<app-header></app-header>
<div class="wrapper">
  <div class="main">
    <div class="page-header2 clear-filter">
      <img src="assets/img/timeless.png" width="100%" />
    </div>
    <div class="container">
        <div class="content-center brand text-center" style="padding: 10px;">
          <div class="row">
            <div class="col-md-12">
              <a href="https://live.daystarng.org" target="_blank" class="btn btn-primary btn-round btn-lg">
                <i class="now-ui-icons media-1_button-play"></i> Watch Live
              </a>
            
              <a
                class="btn btn-primary btn-round btn-lg"
                href="https://www.youtube.com/channel/UCxvgxpys6nNDbVvEra71v8g"
                target="_blank"
              >
                <i class="now-ui-icons media-1_button-play"></i> Watch on YouTube
              </a>

              <a
              class="btn btn-primary btn-round btn-lg"
              href="https://www.facebook.com/daystarchristiancentre/"
              target="_blank"
            >
              <i class="now-ui-icons media-1_button-play"></i> Watch on Facebook
            </a>

            <a
            class="btn btn-primary btn-round btn-lg"
            href="https://www.instagram.com/daystarng"
            target="_blank"
          >
            <i class="now-ui-icons media-1_button-play"></i> Watch on Instagram
          </a>

          <a
            class="btn btn-primary btn-round btn-lg"
            href="https://www.twitter.com/daystarng"
            target="_blank"
          >
            <i class="now-ui-icons media-1_button-play"></i> Watch on Twitter
          </a>

          <a
            class="btn btn-primary btn-round btn-lg"
            href="https://ng.linkedin.com/in/daystar-christian-centre-3bba81172"
            target="_blank"
          >
            <i class="now-ui-icons media-1_button-play"></i> Watch on LinkedIn
          </a>

          <a
            class="btn btn-primary btn-round btn-lg"
            href="https://daystarng.org/daystarfm"
            target="_blank"
          >
            <i class="now-ui-icons media-1_button-play"></i> Listen on Daystar Radio
          </a>
            </div>
          </div>
        </div>
      </div>
  </div>
  <app-footer></app-footer>
</div>
