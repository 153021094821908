<div class="row" style="overflow: hidden;">
  <div class="col-md-4">
    <div><img src="assets/img/management-team/pst_bolu.png" style="width: 100%;" /></div>
  </div>
  <div class="col-md-8">
    <div class="row float-right" style="margin-top: 10px;margin-right: 20px;"><button type="button" class="close"
        (click)="onNoClick()">×</button></div>
    <h2>BOLUTIFE OLUYOMI</h2>
    <p style="font-size: 1.2em;">
      Bolutife Oluyomi is an Associate Pastor and currently serves as Head of Membesrhip Team and the Centre Pastor for
      the Lekki Satellite Church. As Head of Membership Team, he mobilizes the church towards the fulfilment of the
      membership purpose of caring for every member of the church. He supervises all fellowships (Women of Destiny,
      Strictly Masculine, Singles fellowship), some specialized ministries (Family Life Ministry and others) dedicated
      to building homes and families, and some service units which include the Information Centre, Membership Support,
      and some others. He supervises Daystar Membership School.
    </p>
    <p>
      Pastor Bolu holds a degree in Computer Science from the University of Agriculture, Abeokuta and a Post Graduate
      Diploma in Management from the University of Calabar. He has a certificate in Ministerial Training from the Word
      of Victory Bible Training Institute, Ilorin, Nigeria. His over two decades of career experience spans marketing,
      management, IT, and leadership development. He is a member of the Institute of Management Consultants
      Nigeria.<br /><br />

      Pastor Bolu has a passion for building Christ-centered families, developing leaders, and helping individuals find
      their life purpose. In his spare time, he loves to play golf and to cook. <br /><br />

      He is married to Bunmi, and they have three adorable children.
    </p>
  </div>
</div>
