<div class="row" style="overflow: hidden;">
  <div class="col-md-4">
    <div><img src="assets/img/management-team/head_hr.png" style="width: 100%;" /></div>
  </div>
  <div class="col-md-8">
    <div class="row float-right" style="margin-top: 10px;margin-right: 20px;"><button type="button" class="close"
        (click)="onNoClick()">×</button></div>
    <h2>AMARA UGOCHUKWU</h2>
    <p style="font-size: 1.2em;">
      Amara Ugochukwu is a seasoned Strategic Human Resources Professional and currently serves as the Head of Human
      Resources & Administration for Daystar. In this role, she is responsible for overseeing all human capital
      development and administrative functions of the organization, to maximize productivity, effectiveness and
      efficiency. This includes human resources, office management, procurement and contracts.
      <br /><br />
      With over 15 years of experience in management and administration, she has been practicing professional level
      human resources for over 13 years. Amara holds an MBA in Human Resources Management and is a certified Senior
      Professional in Human Resources by the HR Certification Institute, USA. She is an Associate member of the
      Chartered Institute of Personnel Management, Nigeria, and a certified Management Consultant. She is an alumnus of
      the Lagos Business School, having attended the Senior Management Program (SMP).
    </p>
    <p>
      Amara is gifted communicator. She is passionate about leadership, consulting, coaching and counselling. With these
      tools she inspires individuals and groups to maximize their potentials and become treasured assets to their
      organization and the society.
      <br /><br />
      She is married to Uche and they are blessed with two lovely children.
    </p>
  </div>
</div>
