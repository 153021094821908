<div class="row" style="overflow: hidden;">
  <div class="col-md-4">
    <div><img src="assets/img/management-team/pst_nike.png" style="width: 100%;" /></div>
  </div>
  <div class="col-md-8">
    <div class="row float-right" style="margin-top: 10px;margin-right: 20px;"><button type="button" class="close"
        (click)="onNoClick()">×</button></div>
    <h2>NIKE ADEYEMI</h2>
    <p style="font-size: 1.2em;">
      Nike Adeyemi is a global speaker and minister, a voice of love and healing to nations. She co-founded Daystar
      Christian Centre with her husband, Sam Adeyemi.<br /><br />

      Pastor Nike inspires leaders to be authentic, compassionate and courageous. She speaks passionately with the aim
      to liberate, heal and empower individuals, organizations, communities and nations. <br /><br />

      She is driven to share God’s love in practical ways through her TV broadcast, “Real Woman with Nike Adeyemi” which
      is viewed locally and globally, and through her Podcast, "Conversations With Nike Adeyemi".<strong>Conversations
        With Nike Adeyemi</strong>".
    </p>
    <p>
      Pastor Nike is the president of Real Woman International Inc. A 501c non-profit organization. She founded The Real
      Woman Foundation, Nigeria, which shelters and trains trafficked and abused ladies. She also founded the Love Home
      Orphanage. Beneficiaries from these programs are doing great in their new families and communities around the
      world.<br /><br />

      Nike Adeyemi holds the Master of Architecture and Master of Business Administration degrees, she attended the
      Harvard Business School Executive Program. She is a John Maxwell Certified speaker and Coach.
      She is Co-Senior pastor at Daystar also has strategic oversight of the Women’s Fellowship. She is on the faculty
      of the Daystar Leadership Academy.<br /><br />

      She is married to Pastor Sam. Together they have three amazing young adults.<br /><br />

      <i>Follow her @nikeadeyemi on all Social Media platforms
        nikeadeyemi. com for her full bio and global work.</i>

    </p>
  </div>
</div>
