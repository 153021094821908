<app-header></app-header>
<div class="wrapper">
  <div class="page-header page-header-small">
    <div
      class="page-header-image"
      data-parallax="true"
      style="background-image: url('../assets/img/bg6.jpg')"
    ></div>
    <div class="content-center">
      <div class="container">
        <h1 class="title">Simple Ways To Give</h1>
      </div>
    </div>
  </div>

  <!--Start breadcrumb bottom area-->
  <section style="margin-bottom: 0px; padding-bottom: 0px">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="left pull-left footer">
            <a routerLink="/give">
              <span style="font-weight: bold">Give</span>
            </a>
            <a routerLink="#">
              <span style="font-weight: bold">&nbsp;|&nbsp;</span>
            </a>
            <a routerLink="#">
              <span style="font-weight: bold">Simple Ways To Give</span>
            </a>
          </div>
          <div class="right pull-right footer">
            <a href="#">
              <span
                ><i class="fa fa-share-alt" aria-hidden="true"></i>Share</span
              > </a
            >&nbsp;&nbsp;
            <a href="#">
              <span
                ><i class="fab fa-facebook-square" aria-hidden="true"></i
              ></span> </a
            >&nbsp;&nbsp;
            <a href="#">
              <span><i class="fab fa-twitter" aria-hidden="true"></i></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--End breadcrumb bottom area-->
  <div class="section section-team text-center" id="locations" style="padding-bottom: 5px;">
    <div class="container">
      <h2 class="title">USSD CODES</h2>
      <div class="team">
        <div class="row">
          <div class="col-md-6">
            <div class="team-player">
              <h4 class="title text-center">GT BANK ACCOUNT HOLDERS</h4>
              <p class="text-center">FOR OFFERING</p>
              <h2 class="text-center">*737*32*AMOUNT*517#</h2>

              <p class="text-center">FOR TITHE</p>
              <h2 class="text-center">*737*32*AMOUNT*518#</h2>
            </div>
          </div>
          <div class="col-md-6">
            <div class="team-player">
              <h4 class="title text-center">Access Bank Account Holders</h4>
              <p class="text-center">FOR OFFERING</p>
              <h2 class="text-center">*402*00101828*AMOUNT*517#</h2>

              <p class="text-center">FOR TITHE</p>
              <h2 class="text-center">*402*00101827*AMOUNT*517#</h2>
            </div>
          </div>
          <div class="col-md-6">
            <div class="team-player">
              <h4 class="title text-center">ZENITH BANK</h4>
              <h2 class="text-center">*402*96602462*AMOUNT#</h2>
              <p class="text-center">(All Banks customers excluding GTB)</p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="team-player">
              <h4 class="title text-center">FIRST BANK</h4>
              <h2 class="text-center">*894*89404514*AMOUNT#</h2>
              <p class="text-center">(For First Bank Customers Only)</p>
            </div>
          </div>
        </div>
        <div class="row" style="margin-top: 10px;">
          <div class="col-md-12">
            <div class="team-player">
              
              <h4 class="title text-center">UNIVERSAL CODE</h4>
              <h2 class="text-center">*YOUR BANK CODE*000*322+AMOUNT#</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section section-team text-center" id="locations" style="padding-bottom: 5px;">
    <div class="container">
      <h2 class="title">ONLINE TRANSFER</h2>
      <div class="team">
        <div class="row">
          <div class="col-md-6">
            <div class="team-player">
              <h4 class="title text-center">GT BANK ACCOUNT</h4>
              <h2 class="text-center">0011920178</h2>
            </div>
          </div>
          <div class="col-md-6">
            <div class="team-player">
              <h4 class="title text-center">ZENITH BANK</h4>
              <h2 class="text-center">1013407842</h2>
            </div>
          </div>
          <div class="col-md-6">
            <div class="team-player">
              <h4 class="title text-center">GT BANK DOLLAR</h4>
              <h2 class="text-center">0011921065</h2>
            </div>
          </div>
          
          <div class="col-md-6">
            <div class="team-player">
              <h4 class="title text-center">REAL WOMAN FOUNDATION(GTB)</h4>
              <h2 class="text-center">0012056281</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="team-player">
            <h4 class="title text-center">LOVE HOME ORPHANAGE(GTB)</h4>
            <h2 class="text-center">0012056298</h2>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section section-team text-center" id="locations" style="padding-bottom: 5px;">
    <div class="container">
      <h2 class="title">CHEQUE</h2>
      <div class="team">
        <div class="row">
          <div class="col-md-12">
            <div class="team-player">
              <h2 class="text-center">ADDRESS TO <br/>DAYSTAR CHRISTIAN CENTRE</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section section-team text-center" id="locations" style="padding-bottom: 5px;">
    <div class="container">
      <h2 class="title">WEBSITE</h2>
      <div class="team">
        
        <div class="row">
          <div class="col-md-12">
            <div class="team-player">
              <a href="https://offering2.daystarng.org/give" target="_blank"><h2 class="text-center">HTTPS://OFFERING2.DAYSTARNG.ORG</h2></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section section-team text-center" id="locations" style="padding-bottom: 5px;">
    <div class="container">
      <h2 class="title">POS</h2>
      <div class="team">
        <div class="row">
          <div class="col-md-12">
            <div class="team-player">
              <h2 class="text-center">DONATE WITH POS AT THE ENTRANCES</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-footer-link></app-footer-link>
  <app-footer></app-footer>
</div>
