<app-header></app-header>
<div class="wrapper">
  <div class="page-header page-header-small">
    <div
      class="page-header-image"
      data-parallax="true"
      style="background-image: url('../assets/img/bg6.jpg')"
    ></div>
    <div class="content-center">
      <div class="container">
        <h1 class="title">Connect</h1>
      </div>
    </div>
  </div>
  <!--Start breadcrumb bottom area-->
  <section style="margin-bottom: 0px; padding-bottom: 0px">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="left pull-left footer">
            <a routerLink="/connect">
              <span style="font-weight: bold">Connect</span>
            </a>
            <a routerLink="#">
              <span style="font-weight: bold">&nbsp;|&nbsp;</span>
            </a>
            <a routerLink="#">
              <span style="font-weight: bold">Service Times & Locations</span>
            </a>
          </div>
          <div class="right pull-right footer">
            <a href="#">
              <span
                ><i class="fa fa-share-alt" aria-hidden="true"></i
                >&nbsp;&nbsp;Share</span
              > </a
            >&nbsp;&nbsp;
            <a href="#">
              <span
                ><i class="fab fa-facebook-square" aria-hidden="true"></i
              ></span> </a
            >&nbsp;&nbsp;
            <a href="#">
              <span><i class="fab fa-twitter" aria-hidden="true"></i></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--End breadcrumb bottom area-->
  <div class="section section-team text-center" id="locations">
    <div class="container">
      <h2 class="title">Service Times & Locations</h2>
      <div class="team">
        <div class="row">
          <div class="col-md-4">
            <div class="team-player">
              <h4 class="title text-justify">Oregun Centre</h4>
              <p class="text-left">
                Daystar Christian Centre<br />
                Plot A3C, Ikosi Road, Oregun,<br />
                Lagos, Nigeria.
                <br /><br />
                <strong>Wednesday Service </strong>: 6pm<br />
                <!-- <strong>Saturday Service</strong>: 6:00pm<br /> -->
                <!-- <strong>Sunday Service</strong>: 7:00am, 8.45am, 10:30am and 12:15pm<br /> -->
                <!-- <strong>Wednesday - Online Service (Teaching/Communion)</strong
                >: 6pm<br /> -->
                <strong>Sunday Service</strong>: 7:00am, 9:00am & 11:00am<br />
                <strong>House Fellowship</strong>: 5pm/6pm
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="team-player">
              <h4 class="title text-justify">Lekki Centre</h4>
              <p class="text-left">
                Plot 2, Block 2, Okunde Blue Water Scheme,<br />
                Off Remi Olowude Street,<br />
                Opposite Vantage Beach Hotel,<br />
                Lekki 2nd Roundabout, Lagos.<br /><br />
                <!-- <strong>Wednesday Service (Teaching/Communion)</strong>: 6pm<br /> -->
                <!-- <strong>Sunday Service</strong>: 8.45am and 10:30am<br /> -->
                <!-- <strong>Wednesday - Online Service (Teaching/Communion)</strong>: 6pm<br /> -->
                <strong>Sunday Service</strong>: 9:00am and 11:00am<br />
                <strong>House Fellowship</strong>: 5pm/6pm
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="team-player">
              <h4 class="title text-justify">Ikorodu Centre</h4>
              <p class="text-left">
                Epic Event & Conference Center,<br />
                Along Ijede Road,<br />
                by Cele Bus-stop, after Itamaga,<br />
                Ikorodu, Lagos.
                <br /><br />
                <!-- <strong>Wednesday Service (Teaching/Communion)</strong>: 6pm<br /> -->
                <!-- <strong>Wednesday - Online Service (Teaching/Communion)</strong>: 6pm<br /> -->
                <strong>Sunday Service</strong>: 7:30am (Entrepreneurship
                Service), 7:30am, 9:00am & 11:00am<br />
                <!-- <strong>Sunday Service</strong>: 8.45am, 10:30am and 12:15pm<br /> -->
                <strong>House Fellowship</strong>: 5pm/6pm
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="team-player">
              <h4 class="title text-justify">Alimosho Centre</h4>
              <p class="text-left">
                Iceland Civic Centre,<br />
                Egbeda/Idimu Road, <br />
                Banire Bus-stop,<br />
                Egbeda, Lagos.
                <br /><br />
                <!-- <strong>Wednesday Service (Teaching/Communion)</strong>: 6pm<br /> -->
                <!-- <strong>Sunday Service</strong>: 8.45am, 10:30am and 12:15pm<br /> -->
                <!-- <strong>Wednesday - Online Service (Teaching/Communion)</strong>: 6pm<br /> -->
                <strong>Sunday Service</strong>: 8:00am, 9:45am and 11:30am<br />
                <strong>House Fellowship</strong>: 5pm/6pm
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="team-player">
              <h4 class="title text-justify">Badagry Centre</h4>
              <p class="text-left">
                Emma Iffy Plaza,<br />
                By Black Signboard Busstop, <br />
                Lagos-Badagry Expressway,<br />
                Lagos.
                <br /><br />
                <!-- <strong>Wednesday Service (Teaching/Communion)</strong>: 6pm<br /> -->
                <!-- <strong>Sunday Service</strong>: 8.45am, 10:30am and 12:15pm<br /> -->
                <!-- <strong>Wednesday - Online Service (Teaching/Communion)</strong>: 6pm<br /> -->
                <strong>Sunday Service</strong>: 9:00am and 11:00am<br />
                <strong>House Fellowship</strong>: 5pm/6pm
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="team-player">
              <h4 class="title text-justify">Online Church</h4>
              <p class="text-left">
                <strong>Live and On-Demand</strong>:
                <a href="https://live.daystarng.org" target="_blank"
                  >https://live.daystarng.org</a
                ><br />
                <strong>YouTube</strong>:
                <a
                  href="https://www.youtube.com/daystarchristiancentre2"
                  target="_blank"
                  >https://www.youtube.com/daystarchristiancentre2</a
                ><br />
                <strong>Facebook</strong>:
                <a
                  href="https://facebook.com/daystarchristiancentre"
                  target="_blank"
                  >https://facebook.com/daystarchristiancentre</a
                ><br />
                <strong>Twitter</strong>:
                <a href="https://instagram.com/daystarng" target="_blank">
                  https://twitter.com/daystarng</a
                ><br />
                <strong>Instagram</strong>:
                <a href="https://instagram.com/daystarng" target="_blank">
                  https://instagram.com/daystarng</a
                ><br />
                <strong>TikTok</strong>:
                <a href="https://tiktok.com/daystar_ng" target="_blank">
                  https://tiktok.com/daystar_ng</a
                ><br />
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <p>
              <strong>Office lines</strong>: (234) 812 595 3460, (234) 908 257
              1374, (234) 812 331 4163
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="section section-about-us">
    <div class="container">
      <div class="row" id="homefellowship">
        <div class="col-md-12">
          <img
            src="assets/img/home-fellowship.jpg"
            style="width: 50%; margin: 10px"
            class="pull-right"
          />
          <h2 class="title">Home Fellowship</h2>
          <p class="text-justify">
            Our home fellowship system provides a platform for members to have
            church in intimate settings – from the comfort of a home. Leadership
            and mentoring are hallmarks of Daystar Christian Centre, and the
            home fellowship provides an opportunity for volunteers to grow in
            leadership roles by hosting a home fellowship centre.<br /><br />
            <a class="btn btn-lg btn-primary" routerLink="/cell-locator"
              >Find a cell close to you</a
            >
          </p>
        </div>
      </div>
      <div class="separator separator-primary"></div>
      <div class="section" id="carousel" id="events">
        <div class="container">
          <h2 class="title">Events</h2>
          <div class="row justify-content-center">
            <div class="col-lg-10 col-md-12 col-sm-12">
              <div
                id="carouselExampleIndicators"
                class="carousel slide"
                data-ride="carousel"
              >
                <ol class="carousel-indicators">
                  <li
                    *ngFor="let event of events; let i = index"
                    data-target="#carouselExampleIndicators"
                    attr.data-slide-to="{{ i }}"
                    [ngClass]="{ active: i == 0 }"
                  ></li>
                </ol>
                <div class="carousel-inner" role="listbox">
                  <div
                    class="carousel-item"
                    *ngFor="let event of events; let i = index"
                    [ngClass]="{ active: i == 0 }"
                  >
                    <img
                      class="d-block"
                      [src]="event.image"
                      alt="{{ event.comment }}"
                    />
                    <div class="carousel-caption d-none d-md-block">
                      <h5>{{ event.comment }}</h5>
                    </div>
                  </div>
                </div>
                <a
                  class="carousel-control-prev"
                  href="#carouselExampleIndicators"
                  role="button"
                  data-slide="prev"
                >
                  <i
                    class="now-ui-icons arrows-1_minimal-left"
                    style="
                      color: #000000;
                      font-weight: bolder;
                      font-weight: 30px;
                    "
                  ></i>
                </a>
                <a
                  class="carousel-control-next"
                  href="#carouselExampleIndicators"
                  role="button"
                  data-slide="next"
                >
                  <i
                    class="now-ui-icons arrows-1_minimal-right"
                    style="
                      color: #000000;
                      font-weight: bolder;
                      font-weight: 30px;
                    "
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-primary"></div>
      <div class="section-story-overview">
        <div class="row" id="starhub">
          <div class="col-md-7">
            <h2>Starhub</h2>
            <p>
              Star-Hub is a central place where youths, ages 16-23 years, gather
              to develop a deep and meaningful relationship with God and with
              each other.<br /><br />

              Young adults need opportunities to express their love for God by
              serving humanity, Star-Hub offers direction on this.<br /><br />

              Join our services every Sunday 11:00am
              <!-- Join our services every Sunday 10:30am and 12:15pm -->
              <!--              How to thrive as a young adult (A link to articles)-->
              <!--              Be an impact driven youth (A link to articles – testimonies)-->
              <!--              A letter to parents. (Another link on articles about how to manage young adults)-->
            </p>
            <a class="btn btn-primary btn-lg" href="https://thestarhub.org/"
              >Read More...</a
            >
          </div>
          <div class="col-md-5">
            <img src="/assets/img/starhub.png" width="100%;" />
          </div>
        </div>

        <div class="row py-5" id="juniorchurch">
          <div class="col-md-5">
            <img src="assets/img/junior_church.jpg" width="100%" />
          </div>
          <div class="col-md-7">
            <h2>Junior Church</h2>
            <p>
              <em>
                “Children are heritage from the Lord, and the fruit of the womb
                a reward”</em
              >
              <br /><br />
              The early years of a child represent a crucial period of growth
              and development; we recognize that God ordained this, therefore,
              our Junior church is designed to provide a loving environment
              where our little members can be taught the love of Jesus Christ
              early. Depending on their age groups, stories from the bible are
              taught through songs, story-telling and fun activities.<br />
              <!--              Take a look at some of our kids’ activities.-->
              <!--              Here’s how to help your kids eat healthy (link to articles)-->
              <!--              Helping your kids to be emotionally healthy (link to articles)-->
              <!--              How to answer their frequently asked questions (link to articles)-->
              <!--              Awesome resources for your kids (link to articles)-->
              <!--              Kids making impact (testimonies - link)-->
            </p>
            <a
              class="btn btn-primary btn-lg"
              href="https://juniorchurch.daystarng.org/"
              >Read More...</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer-link></app-footer-link>
  <app-footer></app-footer>
</div>
