<div class="row" style="overflow: hidden;">
  <div class="col-md-4">
    <div><img src="assets/img/management-team/khenny.png" style="width: 100%;" /></div>
  </div>
  <div class="col-md-8">
    <div class="row float-right" style="margin-top: 10px;margin-right: 20px;"><button type="button" class="close"
        (click)="onNoClick()">×</button></div>
    <h2>SHEYI KEHNNY</h2>
    <p style="font-size: 1.2em;">
      Sheyi Kehnny is a professional musician and the Music Director at Daystar. In this role, he gives professional
      advice and supervises the Worship Team of Daystar, which comprises the Choir (Healing Streams of God), the
      Instrumentalists (Anointed Fingers), and the Praise Team. </p>
    <p>
      Sheyi Kehnny holds a doctoral degree (Ph.D) in Music from the University of Lagos and has national and
      international publications to his credit. He is a multi-instrumentalist and a world class Bass
      Guitarist.<br /><br />

      He is passionate about performance and creativity, and he teaches and grooms talented musicians. In his spare
      time, he loves to watch sports.
    </p>
  </div>
</div>
