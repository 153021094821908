<app-header></app-header>
<div class="wrapper">
  <div class="page-header page-header-small">
    <div class="page-header-image" data-parallax="true" style="background-image: url('../assets/img/about.jpg');">
    </div>
    <div class="content-center">
      <div class="container">
        <h1 class="title">Find a cell close to you.</h1>
      </div>
    </div>
  </div>
  <!--Start breadcrumb bottom area-->
  <section style="margin-bottom: 0px; padding-bottom: 0px;">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="left pull-left footer">
            <a routerLink="/about">
              <span style="font-weight: bold">Home Cell</span>
            </a>
            <a routerLink="#">
              <span style="font-weight: bold">&nbsp;|&nbsp;</span>
            </a>
            <a routerLink="#">
              <span style="font-weight: bold">Cell Locator</span>
            </a>
          </div>
          <div class="right pull-right footer">
            <a href="#">
              <span><i class="fa fa-share-alt" aria-hidden="true"></i>&nbsp;&nbsp;Share</span>
            </a>&nbsp;&nbsp;
            <a href="#">
              <span><i class="fab fa-facebook-square" aria-hidden="true"></i></span>
            </a>&nbsp;&nbsp;
            <a href="#">
              <span><i class="fab fa-twitter" aria-hidden="true"></i></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--End breadcrumb bottom area-->
  <div class="section sectabout-us">
    <div class="container-fluid">
      <div class='row justify-content-center'
        style="background-color:#fff; text-align:center;  font-weight:500;  padding:20px;">
        <div class='col-md-12' style='margin-bottom:20px' id="vision">
          <h2>Locate a cell</h2>
        </div>
      </div>
    </div>
    <div style="clear: both"></div>
    <div class="container">
      <form [formGroup]="cellForm" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-md-4 col-sm-12">
            <div class="input-group no-border input-lg">
              <select class="form-control" formControlName="cellType" style="height: 50px;"
                [ngClass]="{ 'is-invalid': submitted && f.cellType.errors }">
                <option value="">Select Cell Type</option>
                <option value="Adult">Adult</option>
                <option value="Business">Business</option>
                <option value="Children">Children</option>
                <option value="Couple">Couple</option>
                <option value="Kiddies">Kiddies</option>
                <option value="Mixed">Mixed</option>
                <option value="Preteen">Preteen</option>
                <option value="Specialize">Specialize</option>
                <option value="Teens">Teens</option>
                <option value="Yoruba">Yoruba</option>
              </select>
              <div *ngIf="submitted && f.cellType.errors" class="invalid-feedback">
                <div *ngIf="f.cellType.errors.required">Cell Type is required</div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-12">
            <div class="input-group no-border input-lg">
              <input type="text" class="form-control" placeholder="Area(e.g Oregun)" formControlName="area"
                [ngClass]="{ 'is-invalid': submitted && f.area.errors }">
              <div *ngIf="submitted && f.area.errors" class="invalid-feedback">
                <div *ngIf="f.area.errors.required">Area is required</div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-12">
            <div class="input-group no-border input-lg">
              <input type="text" class="form-control" placeholder="Nearest Bus stop (optional)"
                formControlName="busStop">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <div class="input-group no-border input-lg">
              <input type="text" class="form-control" placeholder="Street name (optional)" formControlName="streetName">
            </div>
          </div>
          <div class="col-md-6 col-sm-12">
            <input type="submit" class="btn btn-primary btn-round btn-lg btn-block" value="Find Cell" />
          </div>
        </div>
      </form>
      <div class="row" style="padding: 20px;">
        <div class="typography-line" *ngFor="let cell of cells">
          <div class="blockquote blockquote-primary">
            <p>Cell Type: {{cell.Cell_Type }}</p>
            <h6>Address: {{ cell.Cell_Address}}</h6>
            <p>Meeting Time: {{cell.Meeting_Day}} {{cell.Meeting_Time}}</p>
            <p>Contact: {{cell.Area_Mobile}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-footer-link></app-footer-link>
  <app-footer></app-footer>
</div>
