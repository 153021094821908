<app-header></app-header>
<div class="wrapper">
  <div class="page-header page-header-small">
    <div
      class="page-header-image"
      data-parallax="true"
      style="background-image: url('../assets/img/bg6.jpg')"
    ></div>
    <div class="content-center">
      <div class="container">
        <h1 class="title">Give</h1>
      </div>
    </div>
  </div>

  <!--Start breadcrumb bottom area-->
  <section style="margin-bottom: 0px; padding-bottom: 0px">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="left pull-left footer">
            <a routerLink="/give">
              <span style="font-weight: bold">Give</span>
            </a>
            <a routerLink="#">
              <span style="font-weight: bold">&nbsp;|&nbsp;</span>
            </a>
            <a routerLink="#">
              <span style="font-weight: bold">Giving</span>
            </a>
          </div>
          <div class="right pull-right footer">
            <a href="#">
              <span
                ><i class="fa fa-share-alt" aria-hidden="true"></i>Share</span
              > </a
            >&nbsp;&nbsp;
            <a href="#">
              <span
                ><i class="fab fa-facebook-square" aria-hidden="true"></i
              ></span> </a
            >&nbsp;&nbsp;
            <a href="#">
              <span><i class="fab fa-twitter" aria-hidden="true"></i></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--End breadcrumb bottom area-->

  <div class="section section-about-us" id="giving">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <img
            src="assets/img/offering.jpg"
            class="float-left"
            style="width: 50%; margin: 20px"
          />
          <h2 class="title">Giving</h2>
          <h3>WELCOME TO DAYSTAR CHRISTIAN CENTRE ONLINE OFFERING PLATFORM</h3>
          <p>
            Daystar Online offering uses a secure platform with additional
            integration to an international payment platform via Paystack
            Payment Gateway. With this platform, offering from members across
            the globe can be received. Gifts can be made in Naira or USD.<br /><br />
            We appreciate all givers very much because your gifts contribute
            substantially to the work of God in reaching the needy. May
            bountiful blessings abound to you, in Jesus’ name, amen.
          </p>

          <div class="row">
            <div class="col-md-12">
              <a
                href="https://paystack.com/pay/daystarnairadonation"
                target="_blank"
                class="btn btn-primary btn-round btn-lg"
              >
                <i class="now-ui-icons shopping_credit-card"></i> Recurring
                Donation in Naira
              </a>
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              <a
                class="btn btn-primary btn-round btn-lg"
                href="https://paystack.com/pay/daystardollardonation"
                target="_blank"
              >
                <i class="now-ui-icons shopping_credit-card"></i> Recurring
                Donation in USD
              </a>
            </div>
          </div>
        </div>
        <div class="separator separator-primary"></div>
        <div class="section-story-overview" id="encouragementstory">
          <div class="row" id="givingimpact">
            <div class="col-md-5">
              <img src="assets/img/giving-impact.jpg" width="100%" />
            </div>
            <div class="col-md-7">
              <h2>Giving Impact</h2>
              <p>
                Giving impacts our city, nation, and the world at large. We
                believe that it is the responsibility of the church to impact
                the society by showing the love of Jesus in practical terms.
                .<br /><br />
                To this effect we are involved in several community projects.<br /><br />
                Find below some of the projects we, together with faithful and
                generous givers, have completed:
              </p>
              <ul
                class="list-group list-group-flush"
                style="font-family: Montserrat"
              >
                <li class="list-group-item">
                  Renovation of the 5 schools damaged by the 2001 bomb blast in
                  the Ikeja Military Cantonment
                </li>
                <li class="list-group-item">
                  Distribution of free exercise books to public schools
                </li>
                <li class="list-group-item">
                  Donation of science equipment, chairs and desks to public
                  schools
                </li>
                <li class="list-group-item">
                  Building of laboratory, toilet facilities and school hall for
                  Oregun Secondary Schools
                </li>
                <li class="list-group-item">
                  Donation of medical equipment and drugs to public hospitals
                </li>
                <li class="list-group-item">
                  Financial support to Local Government Education Foundations
                </li>
                <li class="list-group-item">
                  Daystar Donates Ultra-Modern 10 Cubicle Toilet Facility to
                  Ajao Estate Junior High School, Lagos
                </li>
                <li class="list-group-item">
                  Daystar empowers 560 Young Entrepreneurs
                </li>
                <li class="list-group-item">
                  Daystar Renovates the Brighter Future School for the Deaf
                </li>
              </ul>
              <p>
                <a
                  href="https://offering2.daystarng.org"
                  class="btn btn-primary btn-simple btn-round btn-sm"
                  target="_blank"
                >
                  Join us</a
                >
                to make an impact today!
              </p>
            </div>
          </div>

          <div class="row py-5">
            <div class="col-md-6">
              <h2>Encouragement Stories</h2>
              <h3>I sowed and I reaped bountifully</h3>
              <p>
                A few years ago, I felt convicted to support Daystar Ministry
                with my resources. I do not make so much money, still I promised
                God that every time I got any extra funds, I’d give 70 percent
                of that. I even have it entered on my goal sheet for the year,
                so I don’t forget when the money comes in. <br /><br />

                I have been doing this for some years now without any problems.
                Well, it happened that at a point in time, when I paid my bills,
                the donation was omitted as I didn’t have much money. So, I
                began to debate whether God wants me bankrupt and unhappy even
                when I have given. With the benefit of hindsight I am
                embarrassed to say that this battle raged for quite a while but
                the moment I was about to give up, I prayed to God to do the
                right thing and decided to keep giving.<a
                  style="color: red; cursor: pointer"
                  (click)="toggleAcoutReadMore1()"
                  >{{ testimonyText1 }}</a
                ><span *ngIf="testimonyReadMore1">
                  <br /><br />
                  I remembered that all gold and silver belongs to the Lord and
                  He could bless me beyond my imagination. I figured I had to be
                  bold and act in faith. So, I wrote out a bigger cheque in
                  faith and prayed about it. Little did I know that I was in for
                  a surprise! <br /><br />

                  A Cousin of mine sent me a jewelry order that was six times
                  the amount of the cheque I wrote. Then I got another extra job
                  that netted out to be eight times the cheque I wrote. Gifts
                  and more extra jobs began to come my way. Guess what?! I did
                  the math and came to the realization that I got sixteen times
                  the original amount on the cheque as a blessing from God!<br /><br />

                  What a blessing! Like me, you can make a commitment to God and
                  honor it even if it has to be in faith. He will never let a
                  giver go hungry. <br />

                  <strong>R. B Cecil</strong>
                </span>
              </p>
            </div>
            <div class="col-md-6">
              <img src="assets/img/encouragement-stories.jpg" width="100%" />
              <h3>Fellowship gave me a family, made me bolder</h3>
              <p>
                I was born into a Christian family with both my parents and
                grandparents being devout Christians themselves. I never really
                questioned so many things maybe because I was born into it.
                <br /><br />

                When I left my parents’ house for college, I left all I was
                taught as a kid. I perceived going to church as burdensome and
                never totally agreed that I was a Christian. I graduated and
                began working, still running away from God.
                <a
                  style="color: red; cursor: pointer"
                  (click)="toggleAcoutReadMore()"
                  >{{ testimonyText }}</a
                >
                <span *ngIf="testimonyReadMore"
                  ><br /><br />
                  Then life happened, and I fell into depression. I began to
                  depend on substance to feel alive. I began to contemplate
                  suicide and then a friend who I never took seriously told me
                  there was some get-together for women at a Women of Destiny
                  event at Oregun and it would be cool if I attended. With
                  nothing else to do, I arrived the venue at the said date only
                  to see people praying. I immediately turned back and began to
                  walk away but with each step I took I felt like I was losing
                  something. So, I turned and headed back to the place where
                  they were praying. As they worshipped, I fell on my knees and
                  for some reason began to cry. I sobbed for a while mouthing
                  these words “Please help me!“ A lady put her hands on my
                  shoulder prayed with me, and collected my contact details.<br /><br />

                  The next week I was paid a visit and I revealed all I had been
                  going through. They kept visiting, calling and inviting me for
                  their fellowship event for women and I kept going. Gradually I
                  stopped substance abuse, I learned about God anew, asked
                  questions and got answers, got baptized and I’m now a member
                  of the music team. <br /><br />

                  I enjoy the sense of belonging when I’m with these women. I
                  feel super happy knowing that I'm part of God's family. I have
                  grown bolder in telling other people of the transforming power
                  of God. This is a place where no one judges you but accepts
                  you as you are and make sure you grow. <br />

                  <strong>Christiana. B</strong>
                </span>
              </p>
            </div>
            <p>
              Just like Christiana you can be part of a family today at Daystar
              Christian Centre - Strictly Masculine, Women of Destiny, Daystar
              Singles fellowship, Joyful Mothers. Our arms are open to welcome
              you.
            </p>
          </div>
        </div>
        <div class="row" id="givingoptions">
          <div class="col-md-12 ml-auto mr-auto text-justify">
            <h2 class="title">Giving Options</h2>
            <h5 class="description">
              Due to the CBN Policy on cash deposits and transfers, please make
              your offering/gifts via the following options:
              <ol>
                <li>
                  Online platforms via Our website
                  <a
                    href="https://offering2.daystarng.org"
                    class="btn btn-primary btn-simple btn-round btn-sm"
                    target="_blank"
                    >Click Here</a
                  >
                </li>
                <li>
                  Cheques in favour of Daystar Christian Centre, Success Power,
                  Real Woman Foundation & Love Home Orphanage.
                </li>
                <li>Daystar Mobile App</li>
                <li>
                  Direct debit (from any bank) to these GTBank account numbers
                  accordingly:
                </li>
              </ol>
            </h5>
            <table class="table table-striped">
              <tr>
                <td>Daystar Christian Centre:</td>
                <td>ACCT NO: 0011920178</td>
                <!-- <td>
                SORTCODE: 058152230
              </td> -->
              </tr>
              <tr>
                <td>Real Woman Foundation:</td>
                <td>ACCT NO: 0012056281</td>
                <!-- <td>
                SORTCODE: 058152230
              </td> -->
              </tr>
              <tr>
                <td>Love Home Orphanage:</td>
                <td>ACCT NO: 0012056298</td>
                <!-- <td>
                SORTCODE: 058152230
              </td> -->
              </tr>
            </table>
            <p>
              You can also use any other means that do not go against the
              National Policy for offerings/gifts. <br />
              For enquiries, please contact: account@daystarng.org. <br />
              Thank you.
            </p>
          </div>
        </div>
        <div class="separator separator-primary"></div>
        <div class="row" id="internationalgiving">
          <div class="col-md-12 ml-auto mr-auto text-justify">
            <h2 class="title">Online Offerings (Fund Transfer Instructions)</h2>
            <p>
              <strong
                >FOR TRANSFER OF USD ($) INTO DOMICILIARY ACCOUNT THROUGH
                CITIBANK (N.Y.) USA.</strong
              ><br /><br />

              <strong> CORRESPONDENT BANK:</strong> CITIBANK, NEW YORK<br />
              <strong>SWIFT CODE: </strong> CITIUS33<br />
              <strong>ABA NO:</strong> 021000089<br />
              <strong>FOR CREDIT OF:</strong> GUARANTY TRUST BANK PLC, LAGOS
              NIGERIA.<br />
              <strong>SWIFT CODE:</strong> GTBINGLA<br />
              <strong>ACCOUNT NUMBER:</strong> 36129295<br />
              <strong>FOR FINAL CREDIT OF:</strong> DAYSTAR CHRISTIAN CENTRE<br />
              <strong>BENEFICIARY’S A/C NO:</strong> 0011921065<br /><br />

              <strong
                >FOR TRANSFER OF USD ($) INTO DOMICILIARY ACCOUNT THROUGH
                CITIBANK (LONDON) UNITED KINGDOM.</strong
              ><br /><br />

              <strong>CORRESPONDENT BANK:</strong> CITIBANK, LONDON<br />
              <strong> SWIFT CODE:</strong> CITIGB2L<br />
              <strong>ABA NO:</strong> 185008<br />
              <strong>FOR CREDIT OF:</strong> GUARANTY TRUST BANK PLC, LAGOS
              NIGERIA.<br />
              <strong>SWIFT CODE:</strong> GTBINGLA<br />
              <strong>ACCOUNT NUMBER:</strong> 5511615<br />
              <strong>IBAN NUMBER:</strong> GB67 CITI 1850 0805 5116 15<br />
              <strong>FOR FINAL CREDIT OF:</strong> DAYSTAR CHRISTIAN CENTRE<br />
              <strong>BENEFICIARY’S A/C NO:</strong> 0011921065<br /><br />

              <strong
                >FOR TRANSFER OF GBP (£) INTO DOMICILIARY ACCOUNT THROUGH
                CITIBANK (LONDON) UNITED KINGDOM.</strong
              ><br /><br />

              <strong>CORRESPONDENT BANK:</strong> CITIBANK, LONDON<br />
              <strong>SWIFT CODE:</strong> CITIGB2L<br />
              <strong>ABA NO:</strong> 185008<br />
              <strong>FOR CREDIT OF:</strong> GUARANTY TRUST BANK PLC, LAGOS
              NIGERIA.<br />
              <strong> SWIFT CODE:</strong> GTBINGLA<br />
              <strong> ACCOUNT NUMBER:</strong> 8315795<br />
              <strong>IBAN NUMBER:</strong> GB72 CITI 1850 0808 3157 95<br />
              <strong> FOR FINAL CREDIT OF:</strong> DAYSTAR CHRISTIAN CENTRE<br />
              <strong>BENEFICIARY’S A/C NO:</strong> 0011921089<br /><br />

              <strong
                >FOR TRANSFER OF EUR (€) INTO DOMICILIARY ACCOUNT THROUGH
                CITIBANK (LONDON) UNITED KINGDOM.</strong
              ><br /><br />

              <strong> CORRESPONDENT BANK:</strong> CITIBANK, LONDON<br />
              <strong>SWIFT CODE:</strong> CITIGB2L<br />
              <strong>SORT CODE:</strong> 185008<br />
              <strong>FOR CREDIT OF:</strong> GUARANTY TRUST BANK PLC, LAGOS
              NIGERIA.<br />
              <strong>SWIFT CODE:</strong> GTBINGLA<br />
              <strong>ACCOUNT NUMBER:</strong> 10820571<br />
              <strong>IBAN NUMBER:</strong> GB05 CITI 1850 0810 8205 71<br />
              <strong>FOR FINAL CREDIT OF:</strong> DAYSTAR CHRISTIAN CENTRE<br />
              <strong>BENEFICIARY’S A/C NO: </strong>0011921096 <br /><br />
              OR <br />
              <strong>DAYSTAR CHRISTIAN CENTRE 2</strong><br />
              <strong>DOMICILIARY ACCOUNT NO: 0011921065</strong><br />
              <strong>GUARANTY TRUST BANK PLC, LAGOS NIGERIA</strong><br />
            </p>
          </div>
        </div>
      </div>
    </div>
    <app-footer-link></app-footer-link>
    <app-footer></app-footer>
  </div>
</div>
