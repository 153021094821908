<!DOCTYPE html>
<html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">

<head>
  <title></title>
  <meta charset="utf-8" />
  <meta content="width=device-width,initial-scale=1" name="viewport" />
  <!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
  <!--[if !mso]><!-->
  <link href="https://fonts.googleapis.com/css?family=Roboto" rel="stylesheet" type="text/css" />
  <link href="https://fonts.googleapis.com/css?family=Abril+Fatface" rel="stylesheet" type="text/css" />
  <link href="https://fonts.googleapis.com/css?family=Merriweather" rel="stylesheet" type="text/css" />
  <link href="https://fonts.googleapis.com/css?family=Montserrat" rel="stylesheet" type="text/css" />
  <link href="https://fonts.googleapis.com/css?family=Roboto+Slab" rel="stylesheet" type="text/css" />
  <link href="https://fonts.googleapis.com/css?family=Bitter" rel="stylesheet" type="text/css" />
  <link href="https://fonts.googleapis.com/css?family=Lato" rel="stylesheet" type="text/css" />
  <link href="https://fonts.googleapis.com/css?family=Varela+Round" rel="stylesheet" type="text/css" />
  <link href="https://fonts.googleapis.com/css?family=Cabin" rel="stylesheet" type="text/css" />
  <link href="https://fonts.googleapis.com/css?family=Permanent+Marker" rel="stylesheet" type="text/css" />
  <!--<![endif]-->
  <style>
    * {
      box-sizing: border-box
    }

    th.column {
      padding: 0
    }

    a[x-apple-data-detectors] {
      color: inherit !important;
      text-decoration: inherit !important
    }

    #MessageViewBody a {
      color: inherit;
      text-decoration: none
    }

    p {
      line-height: inherit
    }

    @media (max-width:500px) {
      .icons-inner {
        text-align: center
      }

      .icons-inner td {
        margin: 0 auto
      }

      .row-content {
        width: 100% !important
      }

      .stack .column {
        width: 100%;
        display: block
      }
    }

  </style>
</head>

<body style="margin:0;background-color:#022a38;padding:0;-webkit-text-size-adjust:none;text-size-adjust:none">
  <table border="0" cellpadding="0" cellspacing="0" class="nl-container" role="presentation"
    style="mso-table-lspace:0;mso-table-rspace:0;background-color:#022a38" width="100%">
    <tbody>
      <tr>
        <td>
          <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-1" role="presentation"
            style="mso-table-lspace:0;mso-table-rspace:0" width="100%">
            <tbody>
              <tr>
                <td>
                  <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack"
                    role="presentation"
                    style="mso-table-lspace:0;mso-table-rspace:0;background-color:#022a38;background-image:url(assets/img/headermsg.jpg);background-position:center top;background-repeat:repeat"
                    width="480">
                    <tbody>
                      <tr>
                        <th class="column"
                          style="mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;vertical-align:top"
                          width="100%">
                          <table border="0" cellpadding="10" cellspacing="0" class="heading_block" role="presentation"
                            style="mso-table-lspace:0;mso-table-rspace:0" width="100%">
                            <tr>
                              <td>
                                <h1
                                  style="margin:0;color:#fff;direction:ltr;font-family:Montserrat,Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif;font-size:40px;font-weight:400;letter-spacing:3px;line-height:120%;text-align:center;margin-top:0;margin-bottom:0">
                                  Speak The Word Over Your Health and Healing</h1>
                              </td>
                            </tr>
                          </table>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-2" role="presentation"
            style="mso-table-lspace:0;mso-table-rspace:0" width="100%">
            <tbody>
              <tr>
                <td>
                  <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack"
                    role="presentation"
                    style="mso-table-lspace:0;mso-table-rspace:0;background-color:#fff;background-position:top center"
                    width="480">
                    <tbody>
                      <tr>
                        <th class="column"
                          style="mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;vertical-align:top"
                          width="100%">
                          <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation"
                            style="mso-table-lspace:0;mso-table-rspace:0;word-break:break-word" width="100%">
                            <tr>
                              <td style="padding-bottom:30px;padding-left:30px;padding-right:30px;padding-top:10px">
                                <div style="font-family:sans-serif">
                                  <div
                                    style="font-size:14px;color:#333;line-height:1.8;font-family:Montserrat,Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif">
                                    <p style="margin:0"><em>
                                        Jesus carried my sins in His Body on the
                                        tree; therefore, I am dead to sin and
                                        alive to God and by His wounds I am
                                        healed and made whole. (1 Pet. 2:24;
                                        Rom. 6:11; 2 Cor. 5:21.)</em></p>
                                    <p style="margin:0"><em> </em></p>
                                    <p style="margin:0"><em>Jesus carried my
                                        sickness and my pain. Therefore, I give
                                        no place to sickness or pain in my
                                        body. For God sent His Word and healed
                                        me. (Ps. 107:20.)</em></p>
                                    <p style="margin:0"><em> </em></p>
                                    <p style="margin:0"><em>
                                        Jesus carried the curse for me;
                                        therefore, I am free from the
                                        consequences of the curse. I forbid
                                        growths and tumours from inhabiting my
                                        body. The life of God within me
                                        dissolves growths and tumours. My
                                        strength and health are restored. Your
                                        Word is manifested in my body, causing
                                        growths, pains, and diseases to
                                        disappear. My bones, joints, muscles and
                                        organs function properly In Jesus'
                                        Name</em></p>
                                    <p style="margin:0"><em> </em></p>
                                    <p style="margin:0"><em>
                                        Father, because of your Word I am an
                                        overcomer. I overcome the world, the
                                        flesh and the devil, by the Blood of the
                                        Lamb and the word of my testimony. I
                                        walk in victory in every area of my
                                        life.  (1 John 4:4; Rev. 12:11.)</em>
                                    </p>
                                    <p style="margin:0"><em> </em></p>
                                    <p style="margin:0"><em>You have given me
                                        abundant life. I receive that life
                                        through your Word and it flows to
                                        every organ of my body bringing healing,
                                        revitalisation and health. (John 10:10;
                                        John 6:63.)</em></p>
                                    <p style="margin:0"><em> </em></p>
                                    <p style="margin:0"><em>Heavenly Father, I
                                        concentrate on your word, and your word
                                        fills my heart and imparts true life and
                                        radiant health into the very core of my
                                        being. I keep them in my heart and
                                        because of your word, I am bursting with
                                        health. (Prov. 4:20-22.)</em></p>
                                    <p style="margin:0"><em> </em></p>
                                    <p style="margin:0"><em>My immune system grows
                                        stronger and better day by day. I speak
                                        life to every system and organ in my
                                        body. Come alive!</em></p>
                                    <p style="margin:0"><em> </em></p>
                                    <p style="margin:0"><em>That which God has not
                                        planted is dissolved and removed out of
                                        my body In Jesus' Name. Every stranger
                                        in my body, soul and spirit run out of
                                        their hiding places. (Matt. 15:13)</em>
                                    </p>
                                    <p style="margin:0"><em> </em></p>
                                    <p style="margin:0"><em>
                                        Heavenly Father, I give voice to your
                                        Word, the law of the Spirit of Life in
                                        Christ Jesus makes me free from the law
                                        of sin and death. Your life is
                                        energizing every cell in my body. (Rom.
                                        8:1-2)</em></p>
                                    <p style="margin:0"><em> </em></p>
                                    <p style="margin:0"><em>The same Spirit that
                                        raised Jesus from the dead dwells in me,
                                        permeating His life through my veins,
                                        sending healing throughout my body.</em>
                                    </p>
                                    <p style="margin:0"><em> </em></p>
                                    <p style="margin:0"><em>
                                        Thank you, Lord, for my healing and
                                        health, in Jesus’ name!</em></p>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-3" role="presentation"
            style="mso-table-lspace:0;mso-table-rspace:0" width="100%">
            <tbody>
              <tr>
                <td>
                  <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack"
                    role="presentation" style="mso-table-lspace:0;mso-table-rspace:0" width="480">
                    <tbody>
                      <tr>
                        <th class="column"
                          style="mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;vertical-align:top"
                          width="100%">
                          <table border="0" cellpadding="0" cellspacing="0" class="text_block" role="presentation"
                            style="mso-table-lspace:0;mso-table-rspace:0;word-break:break-word" width="100%">
                            <tr>
                              <td style="padding-bottom:30px;padding-left:30px;padding-right:30px;padding-top:5px">
                                <div style="font-family:sans-serif">
                                  <div
                                    style="font-size:12px;color:#ccc;line-height:1.5;font-family:Montserrat,Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif">
                                    <p style="margin:0;font-size:14px;text-align:center;mso-line-height-alt:18px">
                                      <br /></p>
                                    <p style="margin:0;font-size:14px;text-align:center;mso-line-height-alt:18px">
                                      <span style="font-size:12px">© 2021 Daystar
                                        Christian Centre. All Rights
                                        Reserved.</span></p>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table><!-- End -->
</body>

</html>
