import {
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'ngx-alerts';
import { DaystarServiceService } from 'src/app/shared/daystar-service.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PopupMessageComponent } from '../popup-message/popup-message.component';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit, OnDestroy {
  events: any[];
  daystarForm: FormGroup;
  contactSubmitted = false;
  subscriptionSubmitted = false;
  title = 'Daystar Christian Centre - raising role models. | Home';

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private renderer: Renderer2,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    public dialog: MatDialog,
    public ngxSmartModalService: NgxSmartModalService,
    private daystarService: DaystarServiceService
  ) {
    this.renderer.addClass(document.body, 'index-page');
    this.renderer.addClass(document.body, 'sidebar-collapse');
    this.loadScripts();

    this.daystarForm = this.formBuilder.group({
      contactForm: this.formBuilder.group({
        name: ['', Validators.required],
        email: [
          '',
          [
            Validators.required,
            Validators.pattern(
              '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
            ),
          ],
        ],
        subject: ['', Validators.required],
        message: ['', Validators.required],
      }),
      subscrptionForm: this.formBuilder.group({
        email: [
          '',
          [
            Validators.required,
            Validators.pattern(
              '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
            ),
          ],
        ],
      }),
    });
  }

  get f() {
    return (this.daystarForm.get('contactForm') as FormGroup).controls;
  }

  get f1() {
    return (this.daystarForm.get('subscrptionForm') as FormGroup).controls;
  }

  get contactForm() {
    return this.daystarForm.get('contactForm');
  }

  get subscrptionForm() {
    return this.daystarForm.get('subscrptionForm');
  }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      { name: 'keywords', content: 'daystar, daystarng, rolemodel, rolemodels, leaders, leadership' },
      { name: 'description', content: 'Daystar Christian Centre was established on the firm foundation of God’s word given to His servants, Sam & Nike Adeyemi in November 18, 1995, to empower individuals to discover, develop, release and maximize our potentials in God through the word.' },
      { name: 'robots', content: 'index, follow' }
    ]);

    //this.openPopupMessage();
    //this.ngxSmartModalService.getModal('myModal').open()
    this.playVideo();
    this.spinner.show();
    this.daystarService.getLatestSliders().subscribe(
      (data: any) => {
        this.spinner.hide();
        const error: boolean = data.error;
        if (!error) {
          console.log(data);
          this.events = data;
        } else {
          this.alertService.danger(data.message);
        }
      },
      (error) => {
        this.spinner.hide();
        this.alertService.danger('An error occurred, Please try again');
      }
    );
  }

  playVideo() {
    const myVideo: any = document.getElementById('myVideo');
    myVideo.muted = true;
    myVideo.play();
  }

  onContactSubmit() {
    console.log('Contact');
    this.contactSubmitted = true;

    // stop here if form is invalid
    if (this.contactForm.invalid) {
      return;
    }

    this.spinner.show();
    this.daystarService
      .submitContact(
        this.contactForm.value.name,
        this.contactForm.value.email,
        this.contactForm.value.subject,
        this.contactForm.value.message
      )
      .subscribe(
        (data: any) => {
          this.spinner.hide();
          const error: boolean = data.error;
          if (!error) {
            console.log(data);
            // this.cells = data;
            this.alertService.success(data.message);
            this.contactForm.reset();
            this.contactSubmitted = false;
            // console.log(this.cells);
          } else {
            this.alertService.danger(data.message);
          }
        },
        (error) => {
          this.spinner.hide();
          this.alertService.danger('An error occurred, Please try again');
        }
      );
  }

  onSubscribeSubmit() {
    console.log('Subscribe');
    this.subscriptionSubmitted = true;

    // stop here if form is invalid
    if (this.subscrptionForm.invalid) {
      return;
    }

    this.spinner.show();
    this.daystarService
      .submitSubscribe(this.subscrptionForm.value.email)
      .subscribe(
        (data: any) => {
          this.spinner.hide();
          const error: boolean = data.error;
          if (!error) {
            // console.log(data);
            this.alertService.success(data.message);
            this.subscrptionForm.reset();
            this.subscriptionSubmitted = false;
            // console.log(this.cells);
          } else {
            this.alertService.danger(data.message);
          }
        },
        (error) => {
          this.spinner.hide();
          this.alertService.danger('An error occurred, Please try again');
        }
      );
  }

  openPopupMessage() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width = '80%';
    dialogConfig.height = '80%';
    dialogConfig.data = {};

    this.dialog.open(PopupMessageComponent, dialogConfig)
      .afterClosed()
      .subscribe(res => {

      });
  }

  loadScripts() {
    const dynamicScripts = [
      'assets/js/core/jquery.min.js',
      'assets/js/core/popper.min.js',
      'assets/js/core/bootstrap.min.js',
      'assets/js/plugins/bootstrap-switch.js',
      'assets/js/plugins/nouislider.min.js',
      'assets/js/plugins/bootstrap-datepicker.js',
      'assets/js/now-ui-kit.js?v=1.2.0',
      'assets/js/main.js',
      // 'assets/js/jquery.fancybox.min.js'
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'index-page');
    this.renderer.removeClass(document.body, 'sidebar-collapse');
  }
}
