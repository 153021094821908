<app-header></app-header>
<div class="wrapper">
  <div class="page-header page-header-small">
    <div
      class="page-header-image"
      data-parallax="true"
      style="background-image: url('../assets/img/bg6.jpg')"
    ></div>
    <div class="content-center">
      <div class="container">
        <h1 class="title">Daystar Events</h1>
      </div>
    </div>
  </div>

  <!--Start breadcrumb bottom area-->
  <section style="margin-bottom: 0px; padding-bottom: 0px">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="left pull-left footer">
            <a routerLink="/about">
              <span style="font-weight: bold">Home</span>
            </a>
            <a routerLink="#">
              <span style="font-weight: bold">&nbsp;|&nbsp;</span>
            </a>
            <a routerLink="#">
              <span style="font-weight: bold">Events</span>
            </a>
          </div>
          <div class="right pull-right footer">
            <a href="#">
              <span
                ><i class="fa fa-share-alt" aria-hidden="true"></i
                >&nbsp;&nbsp;Share</span
              > </a
            >&nbsp;&nbsp;
            <a href="#">
              <span
                ><i class="fab fa-facebook-square" aria-hidden="true"></i
              ></span> </a
            >&nbsp;&nbsp;
            <a href="#">
              <span><i class="fab fa-twitter" aria-hidden="true"></i></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--End breadcrumb bottom area-->
  <!--Start breadcrumb bottom area-->
  <section style="margin-bottom: 0px; padding-bottom: 0px">
    <div class="container justify-content-center">
      <h2 style="margin-top: 5px; align-items: center">Daystar Events</h2>
      <div class="row justify-content-center">
        <div class="col-lg-9 col-md-12 col-sm-12">
          <div
            class="row"
            *ngFor="let event of events; let i = index"
            style="
              margin: 5px 5px 0 5px;
              border: 1px solid gray;
              border-radius: 5px;
            "
          >
            <a [href]="event.url" target="_blank">
              <img
                class="d-block"
                [src]="event.image"
                alt="{{ event.comment }}"
                style="border-radius: 5px"
              />
              <div class="text-center" style="margin-top: 5px">
                <h3>{{ event.comment }}</h3>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--End breadcrumb bottom area-->
  <app-footer-link></app-footer-link>
  <app-footer></app-footer>
</div>
