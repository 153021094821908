import { Component, OnInit, Renderer2 } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'ngx-alerts';
import { DaystarServiceService } from 'src/app/shared/daystar-service.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-bible-reading-details',
  templateUrl: './bible-reading-details.component.html',
  styleUrls: ['./bible-reading-details.component.css']
})
export class BibleReadingDetailsComponent implements OnInit {
  date = '';
  passage = '';
  bible_readings: any[];

  constructor(
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private spinner: NgxSpinnerService,
    private alertService: AlertService,
    private daystarService: DaystarServiceService) {
    this.renderer.addClass(document.body, 'landing-page');
    this.renderer.addClass(document.body, 'sidebar-collapse');
    this.loadScripts();
    this.route.queryParams.subscribe(params => {
      this.date = params['date'];
      this.passage = params['passage'];

      console.log(this.date);
      console.log(this.passage);
    });
  }

  ngOnInit() {
    if (this.date !== '' && this.passage !== '') {
      this.spinner.show();
      //
      this.daystarService.getBibleDetail(this.passage).subscribe(
        (data: any) => {
          this.spinner.hide();
          const error = data.status;
          if (error == 'success') {
            console.log(data);
            this.bible_readings = data.verse_content;
            console.log(this.bible_readings);
          } else {
            this.alertService.danger(data.message);
          }
        },
        error => {
          this.spinner.hide();
          this.alertService.danger('An error occurred, Please try again');
        }
      );
    }
  }

  loadScripts() {
    const dynamicScripts = [
      'assets/js/core/jquery.min.js',
      'assets/js/core/popper.min.js',
      'assets/js/core/bootstrap.min.js',
      'assets/js/plugins/bootstrap-switch.js',
      'assets/js/plugins/nouislider.min.js',
      'assets/js/plugins/bootstrap-datepicker.js',
      'assets/js/now-ui-kit.js?v=1.2.0',
      'assets/js/main.js',
      // 'assets/js/jquery.fancybox.min.js'
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'landing-page');
    this.renderer.removeClass(document.body, 'sidebar-collapse');
  }


}
