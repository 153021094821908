<app-header></app-header>
<div class="wrapper">
  <div class="page-header page-header-small">
    <div
      class="page-header-image"
      data-parallax="true"
      style="background-image: url('../assets/img/bg6.jpg')"
    ></div>
    <div class="content-center">
      <div class="container">
        <h1 class="title">Connect With Us</h1>
      </div>
    </div>
  </div>
  <!--Start breadcrumb bottom area-->
  <section style="margin-bottom: 0px; padding-bottom: 0px">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="left pull-left footer">
            <a routerLink="/">
              <span style="font-weight: bold">Home</span>
            </a>
            <a routerLink="#">
              <span style="font-weight: bold">&nbsp;|&nbsp;</span>
            </a>
            <a routerLink="#">
              <span style="font-weight: bold">Newsletter</span>
            </a>
          </div>
          <div class="right pull-right footer">
            <a href="https://www.facebook.com/daystarchristiancentre">
              <span
                ><i class="fab fa-facebook-square" aria-hidden="true"></i
              ></span> </a
            >&nbsp;&nbsp;
            <a href="https://www.instagram.com/daystarng/">
              <span
                ><i class="fab fa-instagram" aria-hidden="true"></i
              ></span> </a
            >&nbsp;&nbsp;
            <a href="https://www.youtube.com/@DaystarChristianCentre2">
              <span><i class="fab fa-youtube" aria-hidden="true"></i></span> </a
            >&nbsp;&nbsp;
            <a href="https://twitter.com/daystarng">
              <span><i class="fab fa-twitter" aria-hidden="true"></i></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--End breadcrumb bottom area-->
  <div class="section section-team text-center" id="locations">
    <div class="container">
      <h1 class="text-center">Connect With Us</h1>
      <p class="text-center">
        We are always cooking up life-transforming resources and events that are
        designed to empower you to discover, develop, release and maximise your
        potential in God. Simply put, we turn you into a megastar!<br /><br />

        Stay connected with us so you don't miss anything from your church.
      </p>
    </div>
    <br /><br />

    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-lg-6 text-left">
          <h3>Newsletter</h3>
          <form [formGroup]="subscrptionForm" (ngSubmit)="onSubscribeSubmit()">
            <div class="container">
              <div
                class="col-md-12 col-lg-12 col-sm-12"
                style="padding-right: 0px"
              >
                <input
                  type="email"
                  placeholder="YOUR EMAIL*"
                  class="form-control"
                  formControlName="email"
                  [ngClass]="{
                    'is-invalid': subscriptionSubmitted && f1.email.errors
                  }"
                  style="
                    margin-bottom: 20px;
                    height: 40px;
                    padding: 15px;
                    width: 100%;
                    background-color: #fff;
                  "
                />
                <div
                  *ngIf="subscriptionSubmitted && f1.email.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f1.email.errors.required">
                    Please enter a valid email
                  </div>
                  <div *ngIf="f1.email.errors.pattern">
                    Please enter a valid email
                  </div>
                </div>
              </div>

              <div class="col-md-12 col-sm-12" style="text-align: center">
                <!--          <span style='font-size:12px; padding:15px; cursor:pointer' class='btn btn-danger'>SEND MESSAGE</span>-->
                <button class="btn btn-primary btn-lg">SUBSCRIBE</button>
              </div>
            </div>
          </form>
        </div>
        <div class="col-sm-12 col-lg-6 text-left">
          <h3>Socials</h3>
          <p style="font-size: 18px">
            <a
              href="https://www.facebook.com/daystarchristiancentre"
              target="_blank"
            >
              <span
                ><i class="fab fa-facebook-square" aria-hidden="true"></i
              ></span>
              Facebook </a
            ><br />
            <a href="https://www.instagram.com/daystarng/" target="_blank">
              <span><i class="fab fa-instagram" aria-hidden="true"></i></span>
              Instagram</a
            ><br />
            <a
              href="https://www.youtube.com/@DaystarChristianCentre2"
              target="_blank"
            >
              <span><i class="fab fa-youtube" aria-hidden="true"></i></span>
              Youtube</a
            ><br />
            <a href="https://twitter.com/daystarng" target="_blank">
              <span><i class="fab fa-twitter" aria-hidden="true"></i></span>
              Twitter
            </a>
          </p>
        </div>
        <div class="col-sm-12 col-lg-6 text-left">
          <h3>On Demand</h3>
          <p style="font-size: 18px">
            <a href="https://vod.daystarng.org" target="_blank">
              <span><i class="fas fa-video" aria-hidden="true"></i></span>
              Video</a
            ><br />
            <a
              href="https://www.youtube.com/@DaystarChristianCentre2"
              target="_blank"
            >
              <span><i class="fab fa-youtube" aria-hidden="true"></i></span>
              Youtube</a
            ><br />
            <a href="https://live.daystarng.org" target="_blank">
              <span><i class="fas fa-link" aria-hidden="true"></i></span>
              Live.daystarng.org</a
            ><br />
          </p>
        </div>
        <div class="col-sm-12 col-lg-6 text-left">
          <h3>IMs</h3>
          <p style="font-size: 18px">
            <a href="https://t.me/Daystarng_bot" target="_blank">
              <span><i class="fab fa-telegram" aria-hidden="true"></i></span>
              Telegram</a
            ><br />
            <a href="https://wa.me/2348123314163" target="_blank">
              <span><i class="fab fa-whatsapp" aria-hidden="true"></i></span>
              WhatsApp</a
            ><br />
          </p>
        </div>
      </div>
    </div>
  </div>
  <app-footer-link></app-footer-link>
  <app-footer></app-footer>
</div>
