<div class="row" style="overflow: hidden;">
  <div class="col-md-4">
    <div><img src="assets/img/management-team/pst_murphy.png" style="width: 100%;" /></div>
  </div>
  <div class="col-md-8">
    <div class="row float-right" style="margin-top: 10px;margin-right: 20px;"><button type="button" class="close"
        (click)="onNoClick()">×</button></div>
    <h2>MURPHY EYENIKE</h2>
    <p style="font-size: 1.2em;">
      Murphy Eyenike is an Associate Pastor, a District Pastor overseeing two Districts, and currently serves as the
      Centre Pastor for the Ikorodu Satellite Church.</p>
    <p>
      Pastor Murphy holds a Bachelor of Science degree in Mathematics and is currently undertaking a Masters in
      Psychology program at The University of South Wales. He has over 10years experience in sports coaching and
      entrepreneurship. He is a certified Faith-based Counsellor with the International Institute of Faith Based
      Counselling, USA. He is an associate of the Institute of Family System Development and a faculty member of the
      prestigious Daystar Leadership Academy. <br /><br />
      Pastor Murphy loves teaching and coaching. He has a strong passion for life coaching and leadership development.
      He has a key interest in the Family. He is also a sport enthusiast and promoter.<br /><br />
      He is married to Tolulope and they are blessed with two lovable children.
    </p>
  </div>
</div>
