<app-header></app-header>
<div class="wrapper">
  <div class="page-header page-header-small">
    <div class="page-header-image" data-parallax="true" style="background-image: url('../assets/img/bg6.jpg');">
    </div>
    <div class="content-center">
      <div class="container">
        <h1 class="title">Data Protection & Privacy Policy</h1>
      </div>
    </div>
  </div>
  <!--Start breadcrumb bottom area-->
  <section style="margin-bottom: 0px; padding-bottom: 0px;">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="left pull-left footer">
            <a routerLink="/care">
              <span style="font-weight: bold">Home</span>
            </a>
            <a routerLink="#">
              <span style="font-weight: bold">&nbsp;|&nbsp;</span>
            </a>
            <a routerLink="#">
              <span style="font-weight: bold">Data Protection & Privacy Policy</span>
            </a>
          </div>
          <div class="right pull-right footer">
            <a href="#">
              <span><i class="fa fa-share-alt" aria-hidden="true"></i>Share</span>
            </a>&nbsp;&nbsp;
            <a href="#">
              <span><i class="fab fa-facebook-square" aria-hidden="true"></i></span>
            </a>&nbsp;&nbsp;
            <a href="#">
              <span><i class="fab fa-twitter" aria-hidden="true"></i></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--End breadcrumb bottom area-->
  <div class="section section-about-us">
    <div class="container">
      <div class="row">
        <div class="col-md-12 ml-auto mr-auto text-center">
          <h2 class="title" id="ministry">Data Protection & Privacy Policy<br />of<br />Daystar Christian Centre</h2>
        </div>
      </div>
      <div class="separator separator-primary"></div>
      <div class="section-story-overview">
        <div class="row">
          <div class="col-md-12">
            <h4>1. General </h4>
            <p>We are DAYSTAR CHRISTIAN CENTRE (“<strong>DAYSTAR</strong>”) and are committed to safeguarding the
              privacy of your
              personal data. This Data Protection and Privacy Policy (“<strong>Policy</strong>”) is issued in compliance
              with the
              provisions of the Nigeria Data Protection Regulation 2019 (the “<strong>Regulation</strong>”) issued by
              the National
              Information Technology Development Agency and it is designed to provide you with information on how your
              personal data will be treated by DAYSTAR.<br /><br />
              This Policy is subject to change at any time without notice.<br /><br />
              For questions, comments and requests regarding this Policy please contact us by e-mail at
              subscription@daystarng.org our Data Protection Officer will be ready and able to attend to you.
            </p>
            <h4>2. Consent to Use of Data</h4>
            <p>By agreeing to the terms of this Policy you consent to: provide us your relevant personal data, its
              processing, use and storage for the purposes stated herein or any purposes communicated to
              you.<br /><br />
              At any time when your consent is requested, you will also have the option of objecting to the processing
              of your personal data by not providing the requested details as the provision of your personal data is
              absolutely voluntary.<br /><br />

              In accordance with the Regulation, DAYSTAR will only collect and/or process your personal data where one
              of the following conditions exists:</p>
            <ul style="font-family: 'Montserrat';">
              <li>You give us your consent to process your data for one or more purposes; or</li>
              <li>It is required for compliance with a legal obligation to which DAYSTAR is subject.</li>
            </ul>
            <p> Please note that you have a right to withdraw your consent at any time. More information on the
              withdrawal
              of your consent is contained in paragraph 7 (‘Your Rights’) below.
            </p>

            <h4>3. Nature of Data Collected and Purpose for Collection</h4>
            <p>We may collect the following personal data from you, including your name, address, email address, age
              range, telephone or mobile number, location data, gender, occupation, marital status, online identifier
              and any other personal information that may be necessarily required for the purpose of providing our
              services.<br /><br />
              In line with the purposes of DAYSTAR, we use the personal data we collect for the following purposes:
            </p>
            <ul style="font-family: 'Montserrat';">
              <li>Updating of membership database;</li>
              <li>Following-up on our members for welfare purposes;</li>
              <li>Providing premarital and marital counseling services;</li>
              <li>Evaluating application for membership of any of our service units;iv. Evaluating application for
                membership of any of our service units;</li>
              <li>Producing demographic feedback for our strategic planning.</li>
            </ul>
            <p>Your data may be used in order to send you relevant notifications, to respond to your enquiries, to
              profile and identify services which may interest you or best meet your needs.<br /><br />
              Any further processing of your data will only be for archiving purposes in the public interest, or
              statistical purposes. Where we intend to further process your personal data for a purpose other than for
              the purposes stated herein or which it was collected, we will provide you, prior to that further
              processing, with information on such other purpose and with any relevant further information.<br /><br />
              You can always update or amend your personal data provided to DAYSTAR through our self-service portal.
              Other means of updating your personal data include written request through e-mail or letter or verbal
              request over the phone.
            </p>
            <h4>4. Collection and Storage of Personal Data </h4>
            <p>We may collect your data by telephone, SMS, web forms, cookies, APIs (Json, Rest). Our policy is to
              ensure that your data is only stored for a period for which it is reasonably needed for the provision of
              our services to you, and as such:
            </p>
            <ul style="font-family: 'Montserrat';">
              <li>We keep records of all our members irrespective of when they joined us;</li>
              <li>We keep records of the alumni of our various trainings; and</li>
            </ul>
            <p>Notwithstanding the above, DAYSTAR will delete your personal data where:
            </p>
            <ul style="font-family: 'Montserrat';">
              <li>It is no longer necessary in relation to the purposes for which it was collected or processed;</li>
              <li>You withdraw your consent to the processing of your data, or on which the processing is based;</li>
              <li>You object to the processing of your personal data and there are no overriding legitimate grounds
                for the processing; and</li>
              <li>Compliance with a legal obligation requires the erasure of your personal data.</li>
            </ul>
            <h4>5. Third Parties </h4>
            <p>We generally do not share your personal data with third parties. </p>
            <h4>6. Data Security and Protection </h4>
            <p>To prevent unauthorised access and maintain security against foreseeable hazards, we have in place
              physical, electronic and managerial procedures to protect, secure and safeguard your data and ensure
              accuracy of our records. </p>
            <ul style="font-family: 'Montserrat';">
              <li>We use secure socket layer (SSL) protocol technology for the collection. However, DAYSTAR cannot
                guarantee the security of any personal data disclosed to us or collected by us to the extent that we
                have no control over the public or third-party network through which personal data may be sent to our
                website.</li>
              <li>We have a management and corporate commitment to information security within the organisation and
                provide clear direction, guidance and responsibilities and procedures in this respect.</li>
              <li>We have a Data Protection Officer who deals with security of information and personal data together
                with relevant compliance with the Regulation, and the knowledge and skills of our Data Protection
                Officer are continuously updated.</li>
              <li>Our staff are trained on the importance of data security and practical aspects of the Regulation,
                confidentiality and our security system. We have procedures in place in relation to our obligations
                under the Regulation. Accordingly, our staff are aware of the information security issues and can go to
                the compliance officer with any issues relating to or arising from the Regulation, privacy or personal
                data. </li>
              <li>We control the physical access to the personal data kept at our facilities and restrict access to our
                sites, buildings, computer rooms, file rooms, offices, technology areas, equipment and other facilities
                where unauthorised access by the public could compromise our security. Personal data and information in
                intangible forms are currently stored on public cloud and we control access to such information and
                personal data through existing procedures for authorising and authenticating users as well as software
                control for restricting access and techniques for protecting data.</li>
              <li>We monitor and log access to assist in the detection and investigation of security breaches and
                any attempted breaches where they occur and have in place relevant controls which alert us to breaches
                in our security. We endeavor to investigate every breach of security and take relevant measures as
                required by law or our Policy.</li>
              <li>We maintain a continuity plan as a contingency which identify our services and assets (including
                personal data) which would need to be maintained in the event of a disaster and set out the procedure
                for protecting and restoring them if necessary.</li>
              <li>We are taking and will endeavour to continue to take all reasonable steps in order to protect our
                information and all personal data.</li>
            </ul>
            <h4>7. Your Rights </h4>
            <p>We generally do not share your personal data with third parties. </p>
            <ul style="font-family: 'Montserrat';">
              <li><strong>Right to Withdraw Consent</strong><br />
                You have a right, at any time, to withdraw your consent to the processing of your personal data. You can
                always do this through our self-service portal if subscribed thereto, by written requests through e-mail
                or letters or by verbal requests over the phone. <br /><br />
                Please note that the withdrawal of your consent cannot be back-dated and thus will have no effect on
                processing already performed during the period of consent.
              <li>
              <li><strong>Right to Access, Change, Deletion, Restriction, Objection, Copies</strong><br />
                You have the following rights regarding your personal data that we store: access to and access to a copy
                thereof; rectification; or erasure; restriction or objection to processing.<br /><br />

                Should you ever wish to exercise any of these rights, please contact us.

              </li>
              <li><strong>iii. The Right to Complain and to Seek Redress</strong><br />
                In the event you feel that your personal data has been misapplied or misused in any way, please contact
                us within ninety (90) days of the issue and we will endeavour to resolve the issue within thirty (30)
                days of acknowledging receipt of the complaint.
              </li>
            </ul>
            <p>This Policy only covers the operations of DAYSTAR on our website and other social media platforms used by
              us. Any third party’s website or channels to which we may provide links may have in place their own
              privacy and data protection policies, which may differ from ours. DAYSTAR accepts no responsibility or
              liability in respect of the information you provided on any such third party’s platforms. </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer-link></app-footer-link>
  <app-footer></app-footer>
</div>
