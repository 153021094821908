<app-header></app-header>
<div class="wrapper">
  <div class="page-header page-header-small">
    <div class="page-header-image" data-parallax="true" style="background-image: url('../assets/img/watch2.jpg');">
    </div>
    <div class="content-center">
      <div class="container">
        <h1 class="title">Watch</h1>
      </div>
    </div>
  </div>
  <!--Start breadcrumb bottom area-->
  <section style="margin-bottom: 0px; padding-bottom: 0px;">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="left pull-left footer">
            <a routerLink="/watch">
              <span style="font-weight: bold">Watch</span>
            </a>
            <a routerLink="#">
              <span style="font-weight: bold">&nbsp;|&nbsp;</span>
            </a>
            <a routerLink="#">
              <span style="font-weight: bold">Online Church</span>
            </a>
          </div>
          <div class="right pull-right footer">
            <a href="#">
              <span><i class="fa fa-share-alt" aria-hidden="true"></i>&nbsp;&nbsp;Share</span>
            </a>&nbsp;&nbsp;
            <a href="#">
              <span><i class="fab fa-facebook-square" aria-hidden="true"></i></span>
            </a>&nbsp;&nbsp;
            <a href="#">
              <span><i class="fab fa-twitter" aria-hidden="true"></i></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--End breadcrumb bottom area-->
  <div class="section section-about-us" id="onlinechurch">
    <div class="container">
      <div class="row">
        <div class="col-md-7">
          <h2 class="title">Online Church</h2>
          <h3>Join us here on every of our service days</h3>
          <p>Are you having a very busy day at work, stuck in traffic or far from any of our centres
            (put a link that re-directs to the various centres)? We’re bringing church to you. Join us in worshipping
            from the comfort of your internet connected smart phone or desktop. Please drop your prayer request,
            testimony and give here.</p>
          <a class="btn btn-lg btn-primary" href="https://live.daystarng.org">Watch Now</a>
        </div>
        <div class="col-md-5">
          <img src="assets/img/online-church.jpg" style="width: 100%;" />
        </div>
      </div>
      <div class="separator separator-primary"></div>
      <div class="section-story-overview">
        <div class="row py-3" id="sermons">
          <div class="col-md-5">
            <img src="assets/img/sermon.jpg" style="width: 100%;">
          </div>
          <div class="col-md-7">
            <h2>Sermons</h2>
            <p>With the leading of Holy Spirit, sermons are treated in series every month. For this month’s sermons.
            </p>
            <a class="btn btn-lg btn-primary" href="http://onlinechurch.daystarng.org/vod">Click here</a>
          </div>
        </div>

        <div class="row py-3" id="reflexions">
          <div class="col-md-7">
            <h2>Reflexions</h2>
            <p>
              The Reflexions is a theatre/film unit of the Daystar Christian Center set up with the aim of using the
              medium of drama and film to communicate the gospel of Christ and also promoting the role model mandate of
              Daystar.<br /><br />

              We believe that drama is a reflection of life; so we mirror the society in order to expose societal ills
              and correct the decadence. For close to 2 decades, we have staged and screened numerous plays and short
              films to educate, entertain, chastise and celebrate good and godly virtues.

            </p>
          </div>
          <div class="col-md-5">
            <img src="assets/img/reflexions2.jpg" style="width: 100%;">
          </div>
        </div>

        <div class="row py-3" id="worship">
          <div class="col-md-5">
            <img src="assets/img/worship.jpg" style="width: 100%;">
          </div>
          <div class="col-md-7">
            <h2>Worship</h2>
            <p>Enjoy quality worship from the ministry of Daystar Christain Centre Praise Team and Healing streams of
              God. Listen to Inspirational soul lifting songs.
            </p>
          </div>
        </div>
      </div>
      <div class="row" id="communityimpact">
        <div class="col-md-12 ml-auto mr-auto text-justify">
          <img src="assets/img/community_impact.jpg" class="pull-right" style="width: 45%; margin: 20px;">
          <h2 class="title">Community Impact</h2>
          <p class="description">As a responsible church, we are involved in many community projects in our society most
            of which are not reported. We simply show the love of Jesus to our society
          </p>
          <a class="btn btn-lg btn-primary" href="https://blog.daystarng.org/community-impact/">Click here to read some
            of
            them</a>
        </div>
      </div>
      <div class="separator separator-primary"></div>
    </div>
  </div>
  <app-footer-link></app-footer-link>
  <app-footer></app-footer>
</div>
