<ngx-alerts></ngx-alerts>
<ngx-spinner
  bdOpacity="0.9"
  bdColor="#333"
  size="large"
  color="#0000ff"
  type="ball-clip-rotate"
  [fullScreen]="true"
>
  <p style="color: white">Loading...</p>
</ngx-spinner>
<!-- <ngx-smart-modal #myModal identifier="myModal">
 <app-popup-message></app-popup-message> 
 <app-confession-message></app-confession-message>
</ngx-smart-modal> -->
<router-outlet></router-outlet>
<!-- export NODE_OPTIONS=--openssl-legacy-provider -->
