<app-header></app-header>
<div class="wrapper">
  <div class="page-header page-header-small">
    <div
      class="page-header-image"
      data-parallax="true"
      style="background-image: url('../assets/img/bg6.jpg')"
    ></div>
    <div class="content-center">
      <div class="container">
        <h1 class="title">Forms Download</h1>
      </div>
    </div>
  </div>

  <!--Start breadcrumb bottom area-->
  <section style="margin-bottom: 0px; padding-bottom: 0px">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="left pull-left footer">
            <a routerLink="/">
              <span style="font-weight: bold">Home</span>
            </a>
            <a>
              <span style="font-weight: bold">&nbsp;|&nbsp;</span>
            </a>
            <a routerLink="/forms-download">
              <span style="font-weight: bold">Forms Download</span>
            </a>
          </div>
          <div class="right pull-right footer">
            <a href="#">
              <span
                ><i class="fa fa-share-alt" aria-hidden="true"></i>Share</span
              > </a
            >&nbsp;&nbsp;
            <a href="#">
              <span
                ><i class="fab fa-facebook-square" aria-hidden="true"></i
              ></span> </a
            >&nbsp;&nbsp;
            <a href="#">
              <span><i class="fab fa-twitter" aria-hidden="true"></i></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--End breadcrumb bottom area-->

  <div class="section section-about-us" id="giving">
    <div class="container">
      <div class="row">
        <!-- <div class="col-lg-3 col-sm-6 text-center">
          <div class="card">
            <p></p>
            <img src="assets/img/forms.png" class="card-img-top" alt="Daystar Forms">
            <div class="card-body">
              <h5 class="card-title">Vehicle USE Approval </h5>
              <a href="https://mobile.daystarng.org/forms/VehicleUseApprovalForm.pdf" target="_blank"
                class="btn btn-large btn-primary">Download Form</a>
            </div>
          </div>
        </div> -->

        <div class="col-lg-3 col-sm-6 text-center">
          <div class="card">
            <p></p>
            <img
              src="assets/img/forms.png"
              class="card-img-top"
              alt="Daystar Forms"
            />
            <div class="card-body">
              <h5 class="card-title">Appointment form</h5>
              <a
                href="https://mobile.daystarng.org/forms/AppointmentForm.pdf"
                target="_blank"
                class="btn btn-large btn-primary"
                >Download Form</a
              >
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-sm-6 text-center">
          <div class="card">
            <p></p>
            <img
              src="assets/img/forms.png"
              class="card-img-top"
              alt="Daystar Forms"
            />
            <div class="card-body">
              <h5 class="card-title">Child Naming Notification</h5>
              <a
                href="https://mobile.daystarng.org/forms/ChildNamingNotificationForm.pdf"
                target="_blank"
                class="btn btn-large btn-primary"
                >Download Form</a
              >
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-sm-6 text-center">
          <div class="card">
            <p></p>
            <img
              src="assets/img/forms.png"
              class="card-img-top"
              alt="Daystar Forms"
            />
            <div class="card-body">
              <h5 class="card-title">Facility Booking</h5>
              <a
                href="https://mobile.daystarng.org/forms/FacilityBookingForm.pdf"
                target="_blank"
                class="btn btn-large btn-primary"
                >Download Form</a
              >
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-sm-6 text-center">
          <div class="card">
            <p></p>
            <img
              src="assets/img/forms.png"
              class="card-img-top"
              alt="Daystar Forms"
            />
            <div class="card-body">
              <h5 class="card-title">Family Life</h5>
              <a
                href="https://mobile.daystarng.org/forms/FamilyLifeForm.pdf"
                target="_blank"
                class="btn btn-large btn-primary"
                >Download Form</a
              >
            </div>
          </div>
        </div>

        <!-- <div class="col-lg-3 col-sm-6 text-center">
          <div class="card">
            <p></p>
            <img src="assets/img/forms.png" class="card-img-top" alt="Daystar Forms">
            <div class="card-body">
              <h5 class="card-title">Marriage Registry </h5>
              <a href="https://mobile.daystarng.org/forms/MarriageRegistryForm.pdf" target="_blank"
                class="btn btn-large btn-primary">Download Form</a>
            </div>
          </div>
        </div> -->

        <div class="col-lg-3 col-sm-6 text-center">
          <div class="card">
            <p></p>
            <img
              src="assets/img/forms.png"
              class="card-img-top"
              alt="Daystar Forms"
            />
            <div class="card-body">
              <h5 class="card-title">Courtship Form</h5>
              <a
                href="https://mobile.daystarng.org/forms/CourtshipForm.pdf"
                target="_blank"
                class="btn btn-large btn-primary"
                >Download Form</a
              >
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-sm-6 text-center">
          <div class="card">
            <p></p>
            <img
              src="assets/img/forms.png"
              class="card-img-top"
              alt="Daystar Forms"
            />
            <div class="card-body">
              <h5 class="card-title">Courtship guideline</h5>
              <a
                href="https://mobile.daystarng.org/forms/CourtshipGuidline.pdf"
                target="_blank"
                class="btn btn-large btn-primary"
                >Download</a
              >
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-sm-6 text-center">
          <div class="card">
            <p></p>
            <img
              src="assets/img/forms.png"
              class="card-img-top"
              alt="Daystar Forms"
            />
            <div class="card-body">
              <h5 class="card-title">Post Marital Counselling</h5>
              <a
                href="https://mobile.daystarng.org/forms/PostMaritalCounsellingForm.pdf"
                target="_blank"
                class="btn btn-large btn-primary"
                >Download Form</a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-primary"></div>
    </div>
    <app-footer-link></app-footer-link>
    <app-footer></app-footer>
  </div>
</div>
