import { Component, OnInit, Renderer2 } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'ngx-alerts';
import { DaystarServiceService } from 'src/app/shared/daystar-service.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-cell-locator',
  templateUrl: './cell-locator.component.html',
  styleUrls: ['./cell-locator.component.css']
})
export class CellLocatorComponent implements OnInit {
  cellForm: FormGroup;
  submitted = false;
  cells: any[];

  constructor(
    private renderer: Renderer2,
    private spinner: NgxSpinnerService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private daystarService: DaystarServiceService
  ) {
    this.renderer.addClass(document.body, 'landing-page');
    this.renderer.addClass(document.body, 'sidebar-collapse');
    this.loadScripts();
    this.cellForm = this.formBuilder.group({
      cellType: ['', Validators.required],
      busStop: [''],
      streetName: [''],
      area: ['', Validators.required]
    });
  }

  get f() { return this.cellForm.controls; }

  ngOnInit() {


  }


  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.cellForm.invalid) {
      return;
    }

    this.spinner.show();
    this.daystarService.locateCell(
      this.cellForm.value.cellType,
      this.cellForm.value.area,
      this.cellForm.value.busStop,
      this.cellForm.value.streetName
    ).subscribe(
      (data: any) => {
        this.spinner.hide();
        const error: boolean = data.error;
        if (!error) {
          // console.log(data);
          this.cells = data;

          // console.log(this.cells);
        } else {
          this.alertService.danger(data.message);
        }
      },
      error => {
        this.spinner.hide();
        this.alertService.danger('An error occurred, Please try again');
      }
    );
  }

  loadScripts() {
    const dynamicScripts = [
      'assets/js/core/jquery.min.js',
      'assets/js/core/popper.min.js',
      'assets/js/core/bootstrap.min.js',
      'assets/js/plugins/bootstrap-switch.js',
      'assets/js/plugins/nouislider.min.js',
      'assets/js/plugins/bootstrap-datepicker.js',
      'assets/js/now-ui-kit.js?v=1.2.0',
      'assets/js/main.js',
      // 'assets/js/jquery.fancybox.min.js'
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'landing-page');
    this.renderer.removeClass(document.body, 'sidebar-collapse');
  }
}
