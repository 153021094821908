<div class="row" style="overflow: hidden;">
  <div class="col-md-4">
    <div><img src="assets/img/management-team/pst_sam.png" style="width: 100%;" /></div>
  </div>
  <div class="col-md-8">
    <div class="row float-right" style="margin-top: 10px;margin-right: 20px;"><button type="button" class="close"
        (click)="onNoClick()">×</button></div>
    <h2>SAM ADEYEMI</h2>
    <p style="font-size: 1.2em;">
      Sam Adeyemi began his career as a Civil Engineer and now holds the Master of Arts degree in Leadership Studies
      from the University of Exeter, UK and the ‎Doctor of Strategic Leadership degree from Regent University, Virginia,
      USA. He is the Senior Pastor and Founder of Daystar Christian Centre.
    </p>
    <p>
      He is married to Pastor Nike. They are blessed with three amazing children.
    </p>
  </div>
</div>
