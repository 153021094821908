<app-header></app-header>
<div class="wrapper">
  <div class="page-header page-header-small">
    <div class="page-header-image" data-parallax="true" style="background-image: url('../assets/img/bg6.jpg');">
    </div>
    <div class="content-center">
      <div class="container">
        <h1 class="title">Our Management Team</h1>
      </div>
    </div>
  </div>

  <!--Start breadcrumb bottom area-->
  <section style="margin-bottom: 0px; padding-bottom: 0px;">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="left pull-left footer">
            <a routerLink="/about">
              <span style="font-weight: bold">Home</span>
            </a>
            <a routerLink="#">
              <span style="font-weight: bold">&nbsp;|&nbsp;</span>
            </a>
            <a routerLink="#">
              <span style="font-weight: bold"> Management Team</span>
            </a>
          </div>
          <div class="right pull-right footer">
            <a href="#">
              <span><i class="fa fa-share-alt" aria-hidden="true"></i>&nbsp;&nbsp;Share</span>
            </a>&nbsp;&nbsp;
            <a href="#">
              <span><i class="fab fa-facebook-square" aria-hidden="true"></i></span>
            </a>&nbsp;&nbsp;
            <a href="#">
              <span><i class="fab fa-twitter" aria-hidden="true"></i></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--End breadcrumb bottom area-->
  <!--Start breadcrumb bottom area-->
  <section style="margin-bottom: 0px; padding-bottom: 0px;">
    <div class="container">
      <div class="row">
        <div class="py-5">
          <div class="container">
            <div class="row">
              <div class='col-md-12 text-center' style='margin-bottom:40px'>
                <h2 style="margin-bottom: 0px; padding-bottom: 0px;color: #000; font-weight: bold;">Meet Our Management
                  Team</h2>
                <!-- <p style="color: #000; font-style: italic;">Inspiring resources to become a better you.</p> -->
              </div>
              <div class="col-md-3">
                <a (click)="openSamAdeyemiProfile()" style="cursor: pointer;">
                  <div class="card mb-4 shadow-sm">
                    <img class="imgresource" src="assets/img/management-team/pst_sam.png">
                    <div class="card-body text-center card-text">
                      <h3>
                        SAM ADEYEMI
                      </h3>
                      <p> Senior Pastor<br /><br /></p>
                    </div>
                  </div>
                </a>
              </div>

              <div class="col-md-3">
                <a (click)="openNikeAdeyemiProfile()" style="cursor: pointer;">
                  <div class="card mb-4 shadow-sm">
                    <img class="imgresource" src="assets/img/management-team/pst_nike.png">
                    <div class="card-body text-center card-text">
                      <h3>
                        NIKE ADEYEMI
                      </h3>
                      <p> Co- founder & Deputy Senior Pastor</p>
                    </div>
                  </div>
                </a>
              </div>

              <div class="col-md-3">
                <a (click)="openBoyeOloyedeProfile()" style="cursor: pointer;">
                  <div class="card mb-4 shadow-sm">
                    <img class="imgresource" src="assets/img/management-team/pst_boye.png">
                    <div class="card-body text-center card-text">
                      <h3>
                        BOYE OLOYEDE
                      </h3>
                      <p> Team Head, Missions & Evangelism</p>
                    </div>
                  </div>
                </a>
              </div>

              <div class="col-md-3">
                <a (click)="openBolutifeOluyimoProfile()" style="cursor: pointer;">
                  <div class="card mb-4 shadow-sm">
                    <img class="imgresource" src="assets/img/management-team/pst_bolu.png">
                    <div class="card-body text-center card-text">
                      <h3>BOLUTIFE OLUYOMI</h3>
                      <p> Team Head, Membership<br /><br /></p>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-md-3">
                <a (click)="openSolomonKpandeiProfile()" style="cursor: pointer;">
                  <div class="card mb-4 shadow-sm">
                    <img class="imgresource" src="assets/img/management-team/pst_solomon.png">
                    <div class="card-body text-center card-text">
                      <h3>SOLOMON KPANDEI</h3>
                      <p> Team Head, Ministry<br /><br /></p>
                    </div>
                  </div>
                </a>
              </div>

              <div class="col-md-3">
                <a (click)="openGraceOfiliProfile()" style="cursor: pointer;">
                  <div class="card mb-4 shadow-sm">
                    <img class="imgresource" src="assets/img/management-team/pst_grace.png">
                    <div class="card-body text-center card-text">
                      <h3>GRACE OFILI</h3>
                      <p> Team Head, Maturity<br /><br /></p>
                    </div>
                  </div>
                </a>
              </div>

              <div class="col-md-3">
                <a (click)="openKayodeOlutayoProfile()" style="cursor: pointer;">
                  <div class="card mb-4 shadow-sm">
                    <img class="imgresource" src="assets/img/management-team/pst_kayode.png">
                    <div class="card-body text-center card-text">
                      <h3>KAYODE OLUTAYO</h3>
                      <p> District Pastor<br /><br /></p>
                    </div>
                  </div>
                </a>
              </div>

              <div class="col-md-3">
                <a (click)="openSegunDadaProfile()" style="cursor: pointer;">
                  <div class="card mb-4 shadow-sm">
                    <img class="imgresource" src="assets/img/management-team/pst_segun.png">
                    <div class="card-body text-center card-text">
                      <h3>RALPH SEGUN DADA</h3>
                      <p> District Pastor<br /><br /></p>
                    </div>
                  </div>
                </a>
              </div>

              <div class="col-md-3">
                <a (click)="openMurphyEyenikeProfile()" style="cursor: pointer;">
                  <div class="card mb-4 shadow-sm">
                    <img class="imgresource" src="assets/img/management-team/pst_murphy.png">
                    <div class="card-body text-center card-text">
                      <h3>MURPHY EYENIKE</h3>
                      <p> District Pastor<br /><br /></p>
                    </div>
                  </div>
                </a>
              </div>

              <div class="col-md-3">
                <a (click)="openTimiMogajiProfile()" style="cursor: pointer;">
                  <div class="card mb-4 shadow-sm">
                    <img class="imgresource" src="assets/img/management-team/pst_timi.png">
                    <div class="card-body text-center card-text">
                      <h3>TIMILEHIN MOGAJI</h3>
                      <p> District Pastor<br /><br /></p>
                    </div>
                  </div>
                </a>
              </div>

              <div class="col-md-3">
                <a (click)="openSeunOgundeluProfile()" style="cursor: pointer;">
                  <div class="card mb-4 shadow-sm">
                    <img class="imgresource" src="assets/img/management-team/pst_seun.png">
                    <div class="card-body text-center card-text">
                      <h3>SEUN OGUNDELU</h3>
                      <p> District Pastor<br /><br /></p>
                    </div>
                  </div>
                </a>
              </div>

              <div class="col-md-3">
                <a (click)="openSeunLadokunProfile()" style="cursor: pointer;">
                  <div class="card mb-4 shadow-sm">
                    <img class="imgresource" src="assets/img/management-team/pst_ladokun.png">
                    <div class="card-body text-center card-text">
                      <h3>SEUN LADOKUN</h3>
                      <p> District Pastor<br /><br /></p>
                    </div>
                  </div>
                </a>
              </div>

              <div class="col-md-3">
                <a (click)="openBenjamineOkpalaProfile()" style="cursor: pointer;">
                  <div class="card mb-4 shadow-sm">
                    <img class="imgresource" src="assets/img/management-team/pst_ben.png">
                    <div class="card-body text-center card-text">
                      <h3>BENJAMINE OKPALA</h3>
                      <p> District Pastor<br /><br /></p>
                    </div>
                  </div>
                </a>
              </div>

              <div class="col-md-3">
                <a (click)="openAyoBankoleProfile()" style="cursor: pointer;">
                  <div class="card mb-4 shadow-sm">
                    <img class="imgresource" src="assets/img/management-team/pst_ayo.png">
                    <div class="card-body text-center card-text">
                      <h3>AYO BANKOLE</h3>
                      <p> District Pastor<br /><br /></p>
                    </div>
                  </div>
                </a>
              </div>

              <div class="col-md-3">
                <a (click)="openAmaraUgochukwuProfile()" style="cursor: pointer;">
                  <div class="card mb-4 shadow-sm">
                    <img class="imgresource" src="assets/img/management-team/head_hr.png">
                    <div class="card-body text-center card-text">
                      <h3>AMARA UGOCHUKWU</h3>
                      <p> Head, Human Resources & Administration</p>
                    </div>
                  </div>
                </a>
              </div>

              <div class="col-md-3">
                <a (click)="openGabrielKolawoleProfile()" style="cursor: pointer;">
                  <div class="card mb-4 shadow-sm">
                    <img class="imgresource" src="assets/img/management-team/head_it_comm.png">
                    <div class="card-body text-center card-text">
                      <h3>GABRIEL KOLAWOLE</h3>
                      <p> Head, IT & Communications<br /><br /></p>
                    </div>
                  </div>
                </a>
              </div>

              <div class="col-md-3">
                <a (click)="openWaleOresanwoProfile()" style="cursor: pointer;">
                  <div class="card mb-4 shadow-sm">
                    <img class="imgresource" src="assets/img/management-team/head_finance.png">
                    <div class="card-body text-center card-text">
                      <h3>
                        WALE ORESANWO
                      </h3>
                      <p> Head, Finance<br /><br /></p>
                    </div>
                  </div>
                </a>
              </div>


              <div class="col-md-3">
                <a (click)="openSheyiKhennyProfile()" style="cursor: pointer;">
                  <div class="card mb-4 shadow-sm">
                    <img class="imgresource" src="assets/img/management-team/khenny.png">
                    <div class="card-body text-center card-text">
                      <h3>
                        SHEYI KEHNNY
                      </h3>
                      <p> Music Director <br /><br /></p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--End breadcrumb bottom area-->
  <app-footer-link></app-footer-link>
  <app-footer></app-footer>
</div>
