<div class="row" style="overflow: hidden;">
  <div class="col-md-4">
    <div><img src="assets/img/management-team/head_finance.png" style="width: 100%;" /></div>
  </div>
  <div class="col-md-8">
    <div class="row float-right" style="margin-top: 10px;margin-right: 20px;"><button type="button" class="close"
        (click)="onNoClick()">×</button></div>
    <h2>WALE ORESANWO</h2>
    <p style="font-size: 1.2em;">
      Olawale Oresanwo is an experienced Chartered Accountant and currently serves as the Head of Finance for Daystar.
      In this role, he is responsible for overseeing all the finance and accounting functions and operations, ensuring
      efficient and effective management of all assets of the organization, as well as compliance with statutory
      provisions.
    </p>
    <p>
      Olawale holds a Bachelor’s degree in Accounting from Ambrose Alli University Ekpoma. He has over 15 years post
      qualification experience in accounting and has attended various courses in accounting, taxation and related
      disciplines. He is a Fellow of the Institute of Chartered Accountants of Nigeria.<br /><br />

      Olawale is a Zonal Coordinator in the home fellowship system of Daystar Christian Centre. He has a passion for
      administration, as he likes orderliness and enjoys setting up systems.<br /><br />
      He is married to Omolola.
    </p>
  </div>
</div>
