<app-header></app-header>
<div class="wrapper">
  <div class="page-header page-header-small">
    <div class="page-header-image" data-parallax="true" style="background-image: url('../assets/img/bg6.jpg');">
    </div>
    <div class="content-center">
      <div class="container">
        <h1 class="title">About Us</h1>
      </div>
    </div>
  </div>
  <!--Start breadcrumb bottom area-->
  <section style="margin-bottom: 0px; padding-bottom: 0px;">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="left pull-left footer">
            <a routerLink="/about">
              <span style="font-weight: bold">About</span>
            </a>
            <a routerLink="#">
              <span style="font-weight: bold">&nbsp;|&nbsp;</span>
            </a>
            <a routerLink="#">
              <span style="font-weight: bold">Vision</span>
            </a>
          </div>
          <div class="right pull-right footer">
            <a href="#">
              <span><i class="fa fa-share-alt" aria-hidden="true"></i>&nbsp;&nbsp;Share</span>
            </a>&nbsp;&nbsp;
            <a href="#">
              <span><i class="fab fa-facebook-square" aria-hidden="true"></i></span>
            </a>&nbsp;&nbsp;
            <a href="#">
              <span><i class="fab fa-twitter" aria-hidden="true"></i></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--End breadcrumb bottom area-->
  <div class="section section-about-us">
    <div class="container-fluid">
      <div class='row justify-content-center'
        style="background-color:#fff; text-align:center;  font-weight:500;  padding:20px;">
        <!-- <div class='col-md-12' style='margin-bottom:20px' id="vision">
          <h2>ABOUT US</h2>
        </div> -->
        <div class='col-md-9'>
          <p style=" text-align:justify;">
            Daystar Christian Centre was established on the firm foundation of God’s word given to His servants, Sam &
            Nike Adeyemi in November 18, 1995, to empower individuals to discover, develop, release and maximize our
            potentials in God through the word. By the grace of God, Daystar Christian Centre remains committed to
            executing this mandate through weekly worship gatherings and community outreaches; and God stays faithful to
            supporting the ministry by a steady increase in our numbers and impact.<br /><br />
            There is a community for everyone who comes, irrespective of whatever stage of life you are in, there are
            opportunities to serve and be served, alongside a dedicated team of God’s servants to guide you in your
            spiritual journey.<br /><br />
            We welcome you to be a part of the Daystar experience! The Word Works Wonders in Daystar!

          </p>
        </div>
      </div>
    </div>
    <div style="clear: both"></div>
    <div class="container">
      <div class="row">
        <div class="col-md-5">
          <div><img src="assets/img/vision.jpg" style="width: 100%;" /></div>
        </div>
        <div class="col-md-7">
          <h2>Vision</h2>
          <p>To raise role models.</p>
          <h2>Our Mission</h2>
          <p>To empower you and I to discover, develop, release and maximize our potentials in God.</p>
        </div>
      </div>
      <div class="separator separator-primary"></div>
      <div class="section-story-overview" id="corevalues">
        <div class="row">
          <div class="col-md-7">
            <h2>Our Core Values</h2>
            <p style="font-size: 1.3em;">
              We run our daily activities based on these core values. IREAL<br /><br />
              <strong>I</strong>: Innovation<br />
              <strong>R</strong>: Righteousness<br />
              <strong>E</strong>: Excellence<br />
              <strong>A</strong>: Accountability<br />
              <strong>L</strong>: Love
            </p>
          </div>
          <div class="col-md-5">
            <div><img src="assets/img/core-values.jpg" style="width: 100%;" /></div>
          </div>
        </div>
      </div>
      <div class="row" id="academy">
        <div class="col-md-5">
          <div><img src="assets/img/about_academy.jpg" style="width: 100%;" /></div>
        </div>
        <div class="col-md-7">
          <h2>Our Academy</h2>
          <p style="font-size: 1.2em;">
            The Daystar Academy is the machinery for equipping and training members to become leaders in various
            capacities in the church and community by helping members discover and develop their God-given talents and
            strengths. The Daystar Academy also communicates the vision and mission of the church.<br /><br />
            Man's greatest fulfilment in life comes when he discovers and walks in purpose.<br />

            <a class="btn btn-primary btn-round btn-lg" href="https://learning.daystarng.org" target="_blank">
              <i class="now-ui-icons media-1_button-play"></i> Join Our Academy Online
            </a>

            <!-- Man's greatest fulfillment in life comes when he discovers and walks in his destiny. The Daystar Academy
            is the resources development school for the church. It affords the church to train and communicate the
            vision of the church and the grace in the house to all members. The school has 4 levels and each level
            addresses different needs and purposes. The levels are graduated from 100 to 400.<br /><br />
            100 Level - Membership School<br />
            200 Level - Maturity School<br />
            300 Level - Ministry School<br />
            400 Level - Missions School -->
          </p>
        </div>
      </div>
      <div class="separator separator-primary"></div>
      <div class="section-story-overview" id="history">
        <div class="row">
          <div class="col-md-12">
            <img src="assets/img/about-1.jpg" class="pull-right" style="width: 50%;" />
            <h2>History</h2>
            <p>Having spent some years as a minister of the gospel, our senior pastor, Dr Sam Adeyemi felt his calling
              and ministry needed a boost. Hence, he spent some days fasting and praying.<br /><br />
              Together with his wife, Pastor Nike Adeyemi in April 1994, they went to Lekki beach Lagos fasting and
              praying. Their questions to God were: Father what is the next level in ministry? Where do we go from here?
              God's response was unique. God asked them to continue with the fasting and prayers until He asked them to
              stop. God came through after eleven days. He asked them to stop the fasting but continued with the
              prayers.<br /><br />
              July 1994, the Lord instructed our senior pastor to teach biblical success principles. Because of the
              magnitude of the assignment God has called him to do, he was to establish a church that would help people
              discover and release their potentials. Hence, he resigned from his former church pastorate.<a
                style="color:red;cursor: pointer;" (click)="toggleAcoutReadMore()">{{aboutText}}</a>
              <span *ngIf="aboutReadMore">
                <br /><br />
                As an obedient servant of God, he started the teaching first on radio. Later, he talked to a few people
                about the church arm of the vision. On 18th November 1995, Daystar Christian Centre was inaugurated. The
                inauguration was an interesting period in itself in that we didn't have a lot of resources and we could
                not afford the type of accommodation we were getting either; hotels, banquets halls or some duplexes
                were for between N200,000 and N250,000 per annum and they wanted us to pay for two (2)
                years.<br /><br />

                We were almost taking one small place on the street where our senior pastors lived then but we stopped
                because we had serious doubts in our hearts. Eventually, the inauguration was held at Eko Chinese
                Restaurant at 76 Allen Avenue on the 4th floor.<br /><br />

                The radio broadcast continued with payment of N7, 000 every week while we paid N13, 000 per week for the
                use of the restaurant for church services. Then the radio station doubled the rate for the broadcast,
                and we couldnâ€™t cope. We took the broadcast off Raypower to OGBC2. We needed some breathing space so
                we could keep on the church. However, we couldnâ€™t afford to pay regularly for the
                restaurant.<br /><br />

                One Sunday morning, the manager of the venue locked the door and insisted he needed to collect his money
                before the service. Eventually, we reached a compromise. Our service was between 9am and 11am but he
                said he would take his money at 10am. Few minutes to 10 o'clock an usher came with a message that the
                man was threatening to shut down the generating set. Rather than take offerings at the end of the
                service, our senior pastor took over the service, collected the offerings, counted and gave the manager
                his money. After three months, we knew we had to move.<br /><br />

                We moved to Coker village, Alausa which was mistaken for one in Iganmu area. So when people wanted to
                come to our church and board taxis, they took them somewhere else in Lagos. It was so remote. People
                didn't know it was there, even okada riders didn't know the place. It was a dirty environment; a
                rundown building. The most interesting one was the aroma that came from the food; particularly the smell
                of beans that would wax through the atmosphere.<br /><br />

                While praying one day, God shew our senior pastor a revelation where Bishop David Oyedepo and his wife
                were dedicating a building for Daystar Christian Centre. They took a stick and planted it in the soil.
                It had fresh leaves on it and while he pronounced blessing; people rushed to that location. There was a
                large crowd within a few minutes and then we were through with the occasion, friends came to
                congratulate our senior pastors for the movement.<br /><br />

                How do we turn this into reality? Our senior pastor asked God. He led him into three weeks of prayer and
                fasting. We prayed through the nights and had a 24-hour prayer chain. Few days before the end of the
                fast, we saw "TO LET" on a warehouse on 71 Oregun Road (now Kudirat Abiola Way). Just before we
                moved in, a book "Purpose-Driven Church" by Rick Warren was read by our senior pastor. Through that
                book, God delivered to us the structure and the system for organizing our church to accommodate the
                growth that was coming. It was exciting. For about eight months; church members went through an hour
                class every Sunday before actual service started. Our senior pastor maintained that when the crowd
                comes, those members will in turn teach the coming crowd; that was what it turned out to be.<br /><br />

                Today, we have moved to a bigger facility at Plot A3C Ikosi Road, Oregun Ikeja with other annexes for
                our four services on Sundays. The vision is growing; lives are being impacted to glory of
                God.<br /><br />
                <!-- Pastor Kenny Folarin<br />
                Chief Operating Officer<br /> -->
                Daystar Christian Centre
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="section section-team text-center" id="leadership">
    <div class="container">
      <h2 class="title">Leadership (Meet Our Senior Pastors)</h2>
      <div class="team">
        <div class="row">
          <div class="col-md-6">
            <div class="team-player">
              <img src="../assets/img/psa.jpg" alt="Thumbnail Image" class="rounded-circle img-fluid img-raised">
              <h4 class="title">Pastor Sam Adeyemi</h4>
              <p class="category text-primary">Senior Pastor</p>
              <p class="description text-justify">Sam Adeyemi has trained thousands of people in leadership for more
                than two decades. He has done this through the Daystar Leadership Academy (DLA), which has graduated
                over 35,000 people since 2002, and through seminars, workshops and conferences. He currently serves as
                mentor to hundreds of CEOs in Nigeria and around the world.<br /><br />
                As a global conference speaker, he has addressed audiences in the United States, Canada, Germany, United
                Kingdom, South Africa, Nigeria, India, and several other countries. In 2015 and 2017, he spoke at the
                Global Leadership Summit, a global conference attended by over 400,000 leaders in 130+
                countries. <a style="color:red;cursor: pointer;" (click)="togglePsa()">{{psaText}}</a>
                <span *ngIf="psaReadMore">
                  <br /><br />
                  He holds a Master of Arts degree in Leadership Studies from the University of Exeter, UK, and a
                  Doctorate in Strategic Leadership from Regent University, Virginia, USA. He is a member of the
                  International Leadership Association and the Association of Talent Developers.<br /><br />
                  Sam Adeyemi is the Principal Consultant of Sam Adeyemi GLC, Inc., a leading global leadership
                  consulting
                  company with the mission to raise high impact leaders to shape the fortunes and destinies of nations.
                  He is married to Nike Adeyemi, a social entrepreneur, and they are blessed with children.
                  .<br />
                  <a href="https://samadeyemi.net/">Read More...</a>
                </span>
              </p>

              <!--<a href="#pablo" class="btn btn-primary btn-icon btn-round"><i class="fab fa-twitter"></i></a>-->
              <!--<a href="#pablo" class="btn btn-primary btn-icon btn-round"><i class="fab fa-instagram"></i></a>-->
              <!--<a href="#pablo" class="btn btn-primary btn-icon btn-round"><i class="fab fa-facebook-square"></i></a>-->
            </div>
          </div>
          <div class="col-md-6">
            <div class="team-player">
              <img src="../assets/img/pna.jpg" alt="Thumbnail Image" class="rounded-circle img-fluid img-raised">
              <h4 class="title">Pastor Nike Adeyemi</h4>
              <p class="category text-primary">Deputy Senior Pastor</p>
              <p class="description text-justify">Nike Adeyemi is an international speaker and a global voice for love
                to nations. She teaches and demonstrates Godâ€™s love to individuals and communities. She is the
                president
                of Real Woman International Inc. and the founder of The Real Woman Foundation through which many young
                women have been sheltered, healed and empowered to live amazing lives. At her Love Home Orphanage, many
                children thrive, assured of a bright future. On her global TV broadcast, Real Woman with Nike Adeyemi,
                she shares wisdom on various life issues. She has authored several books. <a
                  style="color:red;cursor: pointer;" (click)="togglePna()">{{pnaText}}</a>
                <span *ngIf="pnaReadMore">
                  <br /><br />
                  Nike is an avid learner, despite her masterâ€™s degrees in Architecture and Business Administration,
                  she
                  further trained in Strategic Perspectives for Non-Profit Management and other leadership courses as
                  she
                  pursues her dream of building lives, to see many more whole and living their dreams too.<br /><br />
                  She heads a new apostolic mission, Love Assembly in Atlanta. Last month, she was awarded The
                  Inspirational Woman Award by the Committee of Wives of Lagos State Officials (COWLSO) for her
                  dedicated
                  service to empowering women.<br /><br />
                  Married to her sweetheart Sam, and blessed with three amazing children, she has nurtured and trained
                  many more at Daystar Christian Center and around the world.
                  <br /><a href="https://nikeadeyemi.com">Read More...</a>
                </span>
              </p>
              <!--<a href="#pablo" class="btn btn-primary btn-icon btn-round"><i class="fab fa-twitter"></i></a>-->
              <!--<a href="#pablo" class="btn btn-primary btn-icon btn-round"><i class="fab fa-linkedin"></i></a>-->
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <app-footer-link></app-footer-link>
  <app-footer></app-footer>
</div>
