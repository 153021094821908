import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-ralph-segun-dada',
  templateUrl: './ralph-segun-dada.component.html',
  styleUrls: ['./ralph-segun-dada.component.css']
})
export class RalphSegunDadaComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<RalphSegunDadaComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
