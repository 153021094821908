<div class="section" id="#download-section" data-background-color="black">
  <div class="container">
    <div class="row text-justify mt-1">
      <div class="col-md-1"></div>
      <div class="col-md-2">
        <h3 class="footer-heading mb-1">About</h3>
        <ul class="list-unstyled">
          <li><a routerLink="/about" fragment="vision">Vision</a></li>
          <li><a routerLink="/about" fragment="corevalues">Core Values</a></li>
          <li><a routerLink="/about" fragment="academy">Academy</a></li>
          <li><a routerLink="/about" fragment="history">History</a></li>
          <li><a routerLink="/about" fragment="leadership">Leadership</a></li>
        </ul>
      </div>
      <div class="col-md-2">
        <h3 class="footer-heading mb-1">Connect</h3>
        <ul class="list-unstyled">
          <li><a routerLink="/connect" fragment="locations">Locations</a></li>
          <li>
            <a routerLink="/connect" fragment="homefellowship"
              >Home Fellowship</a
            >
          </li>
          <li><a routerLink="/connect" fragment="events">Events</a></li>
          <li><a routerLink="/connect" fragment="starhub">Starhub</a></li>
          <li>
            <a routerLink="/connect" fragment="juniorchurch">Junior Church</a>
          </li>
        </ul>
      </div>
      <div class="col-md-2">
        <h3 class="footer-heading mb-1">Watch</h3>
        <ul class="list-unstyled">
          <li>
            <a routerLink="/watch" fragment="onlinechurch">Online Church</a>
          </li>
          <li><a routerLink="/watch" fragment="sermons">Sermons</a></li>
          <li><a routerLink="/watch" fragment="reflexions">Reflexion</a></li>
          <li><a routerLink="/watch" fragment="worship">Worship</a></li>
          <li>
            <a routerLink="/watch" fragment="communityimpact"
              >Community Impact</a
            >
          </li>
        </ul>
      </div>
      <div class="col-md-2">
        <h3 class="footer-heading mb-1">Care</h3>
        <ul class="list-unstyled">
          <li><a routerLink="/care" fragment="ministry">Ministries</a></li>
          <li><a routerLink="/care" fragment="fellowship">Fellowships</a></li>
          <li>
            <a routerLink="/care" fragment="serviceunit">Service Units</a>
          </li>
        </ul>
      </div>
      <div class="col-md-2">
        <h3 class="footer-heading mb-1">Give</h3>
        <ul class="list-unstyled">
          <li><a routerLink="/give" fragment="giving">Giving</a></li>
          <li>
            <a routerLink="/give" fragment="encouragementstory"
              >Encouragement Story</a
            >
          </li>
          <li>
            <a routerLink="/give" fragment="givingimpact">Giving Impact</a>
          </li>
          <li>
            <a routerLink="/give" fragment="givingoptions">Giving Options</a>
          </li>
        </ul>
      </div>
      <div class="col-md-1"></div>
    </div>
    <div class="row justify-content-md-center sharing-area text-center">
      <div class="text-center col-md-12 col-lg-8">
        <a
          target="_blank"
          href="https://twitter.com/daystarng"
          class="btn btn-neutral btn-icon btn-round btn-lg"
          style="color: #26a7de"
          rel="tooltip"
          title="Follow us"
        >
          <i class="fab fa-twitter"></i>
        </a>
        <a
          target="_blank"
          href="https://www.facebook.com/daystarchristiancentre"
          class="btn btn-neutral btn-icon btn-round btn-lg"
          style="color: #3b5998"
          rel="tooltip"
          title="Like us"
        >
          <i class="fab fa-facebook"></i>
        </a>
        <a
          target="_blank"
          href="https://www.instagram.com/daystarng/"
          class="btn btn-neutral btn-icon btn-lg btn-round"
          style="color: #e1306c"
          rel="tooltip"
          title="Like us"
        >
          <i class="fab fa-instagram"></i>
        </a>
        <a
          target="_blank"
          href="https://www.youtube.com/@DaystarChristianCentre2"
          class="btn btn-neutral btn-icon btn-round btn-lg"
          style="color: #e62117"
          rel="tooltip"
          title="Watch us"
        >
          <i class="fab fa-youtube"></i>
        </a>
      </div>
    </div>
  </div>
</div>
