<app-header></app-header>
<div class="wrapper">
  <div class="page-header page-header-small">
    <div
      class="page-header-image"
      data-parallax="true"
      style="background-image: url('../assets/img/bg6.jpg')"
    ></div>
    <div class="content-center">
      <div class="container">
        <h1 class="title">Daystar #StrongerTogether Commercials</h1>
      </div>
    </div>
  </div>

  <!--Start breadcrumb bottom area-->
  <section style="margin-bottom: 0px; padding-bottom: 0px">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="left pull-left footer">
            <a href="/">
              <span style="font-weight: bold">Home</span>
            </a>
            <a>
              <span style="font-weight: bold">&nbsp;|&nbsp;</span>
            </a>
            <a href="/stronger-together">
              <span style="font-weight: bold"
                >#StrongerTogether Commercials</span
              >
            </a>
          </div>
          <div class="right pull-right footer">
            <a href="#">
              <span
                ><i class="fa fa-share-alt" aria-hidden="true"></i>Share</span
              > </a
            >&nbsp;&nbsp;
            <a href="#">
              <span
                ><i class="fab fa-facebook-square" aria-hidden="true"></i
              ></span> </a
            >&nbsp;&nbsp;
            <a href="#">
              <span><i class="fab fa-twitter" aria-hidden="true"></i></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--End breadcrumb bottom area-->

  <div class="section section-about-us" id="giving">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-sm-6 text-center">
          <div class="card">
            <img
              src="assets/img/vid_imgs/dcvvideo.png"
              class="card-img-top"
              alt="Daystar StrongerTogether"
            />
            <div class="card-body">
              <h5 class="card-title">
                #StrongerTogether Commercials - 90 Seconds
              </h5>
              <a
                type="button"
                fileSaver
                [method]="'GET'"
                [fileName]="'90Seconds.mp4'"
                [url]="'assets/video/dcv.mp4'"
                href="assets/video/dcv.mp4"
                target="_blank"
                class="btn btn-large btn-primary"
                >Download</a
              >
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-sm-6 text-center">
          <div class="card">
            <img
              src="assets/img/vid_imgs/dcvaudio.png"
              class="card-img-top"
              alt="Daystar StrongerTogether"
            />
            <div class="card-body">
              <h5 class="card-title">
                #StrongerTogether Commercials - Audio Only
              </h5>
              <a
                type="button"
                fileSaver
                [method]="'GET'"
                [fileName]="'AudioOnly.mp3'"
                [url]="'assets/video/dtvc90secaudio.mp3'"
                href="assets/video/dtvc90secaudio.mp3"
                target="_blank"
                class="btn btn-large btn-primary"
                >Download</a
              >
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-sm-6 text-center">
          <div class="card">
            <img
              src="assets/img/vid_imgs/dcvversion1.png"
              class="card-img-top"
              alt="Daystar StrongerTogether"
            />
            <div class="card-body">
              <h5 class="card-title">
                #StrongerTogether Commercials - Version 1 - 60 Seconds
              </h5>
              <a
                type="button"
                fileSaver
                [method]="'GET'"
                [fileName]="'60SecVersion1.mp4'"
                [url]="'assets/video/dtvc60secversion1.mp4'"
                href="assets/video/dtvc60secversion1.mp4"
                target="_blank"
                class="btn btn-large btn-primary"
                >Download</a
              >
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-sm-6 text-center">
          <div class="card">
            <img
              src="assets/img/vid_imgs/dcvversion2.png"
              class="card-img-top"
              alt="Daystar StrongerTogether"
            />
            <div class="card-body">
              <h5 class="card-title">
                #StrongerTogether Commercials - Version 2 - 60 Seconds
              </h5>
              <a
                type="button"
                fileSaver
                [method]="'GET'"
                [fileName]="'60SecVersion2.mp4'"
                [url]="'assets/video/dtcv60secversion2.mp4'"
                href="assets/video/dtcv60secversion2.mp4"
                target="_blank"
                class="btn btn-large btn-primary"
                >Download</a
              >
            </div>
          </div>
        </div>

        <!-- <div class="col-lg-4 col-sm-6 text-center">
          <div class="card">
            <img
              src="assets/img/vid_imgs/resource3.png"
              class="card-img-top"
              alt="Daystar StrongerTogether"
            />
            <div class="card-body">
              <h5 class="card-title">
                #StrongerTogether TV Commercials - Lyrics
              </h5>
              <a
                type="button"
                fileSaver
                [method]="'GET'"
                [fileName]="'DaystarStrongerTogetherLyrics.mp4'"
                [url]="'assets/video/dtvclyrics.mp4'"
                href="assets/video/dtvclyrics.mp4"
                target="_blank"
                class="btn btn-large btn-primary"
                >Download</a
              >
            </div>
          </div>
        </div> -->
      </div>
      <div class="separator separator-primary"></div>
    </div>
    <app-footer-link></app-footer-link>
    <app-footer></app-footer>
  </div>
</div>
