<app-header></app-header>
<div class="wrapper">
  <div class="page-header page-header-small">
    <div class="page-header-image" data-parallax="true" style="background-image: url('../assets/img/bg6.jpg');">
    </div>
    <div class="content-center">
      <div class="container">
        <h1 class="title">Care</h1>
      </div>
    </div>
  </div>
  <!--Start breadcrumb bottom area-->
  <section style="margin-bottom: 0px; padding-bottom: 0px;">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="left pull-left footer">
            <a routerLink="/care">
              <span style="font-weight: bold">Care</span>
            </a>
            <a routerLink="#">
              <span style="font-weight: bold">&nbsp;|&nbsp;</span>
            </a>
            <a routerLink="#">
              <span style="font-weight: bold">Ministries</span>
            </a>
          </div>
          <div class="right pull-right footer">
            <a href="#">
              <span><i class="fa fa-share-alt" aria-hidden="true"></i>Share</span>
            </a>&nbsp;&nbsp;
            <a href="#">
              <span><i class="fab fa-facebook-square" aria-hidden="true"></i></span>
            </a>&nbsp;&nbsp;
            <a href="#">
              <span><i class="fab fa-twitter" aria-hidden="true"></i></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--End breadcrumb bottom area-->
  <div class="section section-about-us">
    <div class="container">
      <div class="row">
        <div class="col-md-12 ml-auto mr-auto text-center">
          <h2 class="title" id="ministry">Ministries</h2>
          <p>At Daystar Christian Centre, we believe that being a part of a Ministry Team leads to real transformation.
            When you join a ministry and serve others, you are able to help people encounter God, which in turn draws
            you closer to Him. Join a ministry today.</p>
        </div>
      </div>
      <div class="separator separator-primary"></div>
      <div class="section-story-overview">
        <div class="row">
          <div class="col-md-3">
            <div class="image-container image-left" style="background-image: url('../assets/img/service-unit.jpg')">
              <!-- First image on the left side -->
              <!--<p class="blockquote blockquote-primary">-->
              <!--<small>Beliefs</small><br/>-->
              <!--For a start, it does not automatically follow that a record amount of ice will melt this summer. More important for determining the size of the annual thaw is the state of the weather as the midnight sun approaches and temperatures rise. But over the more than 30 years of satellite records, scientists have observed a clear pattern of decline, decade-by-decade.-->
              <!--</p>-->
            </div>
            <!-- Second image on the left side of the article -->
            <div class="image-container" style="background-image: url('../assets/img/service-unit3.jpg')"></div>
          </div>
          <div class="col-md-8">
            <!-- First image on the right side, above the article -->
            <div class="image-container image-right" style="background-image: url('../assets/img/service-unit2.jpg')">
            </div>
            <h2 id="fellowship">Fellowships</h2>
            <p>Strictly Masculine, Women of Destiny, Daystar Singles fellowship and Joyful Mothers.<br /><br />
              <strong>Strictly Masculine</strong> is the Men’s Ministry of Daystar Christian Centre, set up with a
              vision is to raise men that will be role models in the society. Our mission is to empower men to become
              effective and Godly leaders in whatever area of society God has positioned them.<br /><br />

              All Strictly Masculine programs and activities are geared towards the empowerment of men in 5 areas:
              Spirituality (Christian maturity), Finance (career & business), Emotional stability (marriage & family
              relationships), Intellectual ability & Physical fitness (healthy living & fitness).<br /><br />

              Once every month, our men fellowship with each other through what we call Men’s Network groups, a
              collection of 15 or more men living in the same locality. The objective is to provide an avenue for our
              men to network, fellowship and mentor one another in a relaxed atmosphere of similar interests as a
              support group of friends. Our Network groups are spread all over Lagos along the current zoning system of
              the church.<br /><br /><br />

              <strong>Women of Destiny (WOD)</strong> is the women’s ministry of Daystar Christian Centre. Our vision is
              to raise Daystar women to become role models in society by empowering them to discover, develop, release
              and maximize their potentials in God.<br /><br />

              WOD meets once every month centrally in church. It is an avenue for women of diverse age groups,
              backgrounds and interests to come together and network, share experiences and learn from one another. We
              have five (5) sub-units that meet independently in order to cater for the needs of different women groups.
              They include; Women’s Fellowship, Princesses (Matured single ladies aged 30 and above), Golden Mothers
              (Women aged 50 & above), Joyful Mothers & Joyful Fathers Forum (Waiting and pregnant parents), Wise Women
              (Widows).<br /><br />

              Every woman in Daystar has a place in Women of Destiny irrespective of their age or status and are welcome
              to be part of the vision.
            </p>
            <h2 id="serviceunit">Service Units</h2>
            <p>
              Join us in making the world a better place. With your skills, talents and interests, we can make the body
              of Christ stronger and impact the world for good. To become an influential leader you must first learn to
              serve and the most practical way to serve others at Daystar Christian Centre is to join a team. It takes
              all of us to make church happen so, join us today.
              <br /><br /></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer-link></app-footer-link>
  <app-footer></app-footer>
</div>
