<div class="row" style="overflow: hidden;">
  <div class="col-md-4">
    <div><img src="assets/img/management-team/pst_boye.png" style="width: 100%;" /></div>
  </div>
  <div class="col-md-8">
    <div class="row float-right" style="margin-top: 10px;margin-right: 20px;"><button type="button" class="close"
        (click)="onNoClick()">×</button></div>
    <h2>BOYE OLOYEDE</h2>
    <p style="font-size: 1.2em;">
      Boye Oloyede is an Associate Pastor and currently serves as Head of Missions & Evangelism and the Supervising
      Pastor of the Junior Church. As Head of Missions & Evangelism Team, he mobilizes the church towards the fulfilment
      of the Missions & Evangelism Team, purpose of bringing people to Christ. He supervises all service units under the
      Missions team, which include The Reflexions, Kings & Priests and others. He supervises Daystar Missions School.
    </p>
    <p>
      Pastor Boye Oloyede holds an MBA in Innovation Leadership and Consulting from York St. John University, UK. He is
      a committed lifetime learner with a passion for leadership and business development. With over 20 years of career
      experience spanning the areas of Management, I.T, people development, consulting and Church leadership, he is an
      experienced executive team leader, coach and strategist. He is a faculty member in the renowned Daystar Leadership
      Academy.<br /><br />
      He is married to his adorable wife Tiwalade, and they are blessed with two wonderful children.
    </p>
  </div>
</div>
