import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: "app-upload-testimony",
  templateUrl: "./upload-testimony.component.html",
  styleUrls: ["./upload-testimony.component.css"],
})
export class UploadTestimonyComponent implements  OnInit, OnDestroy {
  constructor(private renderer: Renderer2) {
    this.renderer.addClass(document.body, "landing-page");
    this.renderer.addClass(document.body, "sidebar-collapse");
    this.loadScripts();
  }

  ngOnInit(): void {}

  loadScripts() {
    const dynamicScripts = [
      "assets/js/core/jquery.min.js",
      "assets/js/core/popper.min.js",
      "assets/js/core/bootstrap.min.js",
      "assets/js/plugins/bootstrap-switch.js",
      "assets/js/plugins/nouislider.min.js",
      "assets/js/plugins/bootstrap-datepicker.js",
      "assets/js/now-ui-kit.js?v=1.2.0",
      "assets/js/main.js",
      "https://formfacade.com/include/114116234803713262828/form/1FAIpQLSfM0Dr4WxZOYKqrlYoMOX2cLP1JXsDN26QP8PI97zIO2_p_2g/bootstrap.js?div=ff-compose",
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement("script");
      node.src = dynamicScripts[i];
      node.type = "text/javascript";
      node.async = false;
      node.charset = "utf-8";
      document.getElementsByTagName("head")[0].appendChild(node);
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, "landing-page");
    this.renderer.removeClass(document.body, "sidebar-collapse");
  }
}
