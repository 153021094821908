import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-confession-message',
  templateUrl: './confession-message.component.html',
  styleUrls: ['./confession-message.component.css']
})
export class ConfessionMessageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
