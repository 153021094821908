import { Component, OnDestroy, OnInit, Renderer2 } from "@angular/core";

@Component({
  selector: "app-downloads",
  templateUrl: "./downloads.component.html",
  styleUrls: ["./downloads.component.css"],
})
export class DownloadsComponent implements OnInit, OnDestroy {
  goalSettingTemplate =
    "https://mobile.daystarng.org/downloads/2024-Goal-Setting-Template.pdf";
  bibleReadng =
    "https://mobile.daystarng.org/downloads/daystar_bible_reading_2024.pdf";
  calendar = "https://mobile.daystarng.org/downloads/daystar_calendar_2024.pdf";

  constructor(private renderer: Renderer2) {
    this.renderer.addClass(document.body, "landing-page");
    this.renderer.addClass(document.body, "sidebar-collapse");
  }

  ngOnInit() {
    this.loadScripts();
  }

  loadScripts() {
    const dynamicScripts = [
      "assets/js/core/jquery.min.js",
      "assets/js/core/popper.min.js",
      "assets/js/core/bootstrap.min.js",
      "assets/js/plugins/bootstrap-switch.js",
      "assets/js/plugins/nouislider.min.js",
      "assets/js/plugins/bootstrap-datepicker.js",
      "assets/js/now-ui-kit.js?v=1.2.0",
      "assets/js/main.js",
      // 'assets/js/jquery.fancybox.min.js'
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement("script");
      node.src = dynamicScripts[i];
      node.type = "text/javascript";
      node.async = false;
      node.charset = "utf-8";
      document.getElementsByTagName("head")[0].appendChild(node);
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, "landing-page");
    this.renderer.removeClass(document.body, "sidebar-collapse");
  }
}
