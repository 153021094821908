import { Component, OnInit, Renderer2 } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'ngx-alerts';
import { FormBuilder } from '@angular/forms';
import { DaystarServiceService } from 'src/app/shared/daystar-service.service';

@Component({
  selector: 'app-zonal-coordinators',
  templateUrl: './zonal-coordinators.component.html',
  styleUrls: ['./zonal-coordinators.component.css']
})
export class ZonalCoordinatorsComponent implements OnInit {

  constructor(
    private renderer: Renderer2,
    private spinner: NgxSpinnerService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private daystarService: DaystarServiceService
  ) {
    this.renderer.addClass(document.body, 'landing-page');
    this.renderer.addClass(document.body, 'sidebar-collapse');
    this.loadScripts();
  }

  ngOnInit(): void {
  }

  loadScripts() {
    const dynamicScripts = [
      'assets/js/core/jquery.min.js',
      'assets/js/core/popper.min.js',
      'assets/js/core/bootstrap.min.js',
      'assets/js/plugins/bootstrap-switch.js',
      'assets/js/plugins/nouislider.min.js',
      'assets/js/plugins/bootstrap-datepicker.js',
      'assets/js/now-ui-kit.js?v=1.2.0',
      'assets/js/main.js',
      // 'assets/js/jquery.fancybox.min.js'
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'landing-page');
    this.renderer.removeClass(document.body, 'sidebar-collapse');
  }
}
