<div class="row" style="overflow: hidden;">
  <div class="col-md-4">
    <div><img src="assets/img/management-team/head_it_comm.png" style="width: 100%;" /></div>
  </div>
  <div class="col-md-8">
    <div class="row float-right" style="margin-top: 10px;margin-right: 20px;"><button type="button" class="close"
        (click)="onNoClick()">×</button></div>
    <h2>GABRIEL KOLAWOLE</h2>
    <p style="font-size: 1.2em;">
      Gabriel Kolawole is a seasoned IT professional. He is currently the Head of Information Technology and acting Head
      of Communications in Daystar. In this role, he supervises the technology that drives infrastructure in all Daystar
      locations, satellite centers across the country, and online presence. He also supervises the Daystar audio-visual,
      production & post production, media & publicity, digital graphics, Livestream, Web Development, New media space
      and every other technical unit in Daystar Christian Centre, and affiliates.<br /><br />

      Gabriel holds a B.Sc in Computer Science, a Master's in Business Administration (MBA) and a Postgraduate Diploma
      in Management from Ladoke Akintola University of Technology, Ogbomosho, Oyo state. He is a certified Amazon
      Solution Architect (AWS), and a Pentester and Offensive Security expert. He is a Microsoft Certified Solution
      Associate.
    </p>
    <p>
      A compassionate and gifted teacher, Gabriel loves to teach; he is particularly passionate about Information
      Technology related topics. <br /><br />

      He is married to Tinuola, and they have wonderful children.
    </p>
  </div>
</div>
