import { Component, OnDestroy, OnInit, Renderer2 } from "@angular/core";
import { AlertService } from "ngx-alerts";
import { NgxSpinnerService } from "ngx-spinner";
import { DaystarServiceService } from "src/app/shared/daystar-service.service";

@Component({
  selector: "app-messages",
  templateUrl: "./messages.component.html",
  styleUrls: ["./messages.component.css"],
})
export class MessagesComponent implements OnInit, OnDestroy {
  messages: any[];

  constructor(
    private renderer: Renderer2,
    private spinner: NgxSpinnerService,
    private alertService: AlertService,
    private daystarService: DaystarServiceService
  ) {
    this.renderer.addClass(document.body, "landing-page");
    this.renderer.addClass(document.body, "sidebar-collapse");
    this.loadScripts();
  }

  ngOnInit() {
    window.location.href = "https://www.daystarng.org/messages_";
    this.spinner.show();
    this.daystarService.getMessages().subscribe(
      (data: any) => {
        this.spinner.hide();
        const error: boolean = data.error;
        if (!error) {
          console.log(data);
          this.messages = data.resources;
          //console.log(this.bulletins);
        } else {
          this.alertService.danger(data.message);
        }
      },
      (error) => {
        this.spinner.hide();
        this.alertService.danger("An error occurred, Please try again");
      }
    );
  }

  downloadFile(downloadUrl: string) {
    var save = document.createElement("a");
    save.href = downloadUrl;
    save.target = "_blank";
    save.download = downloadUrl;
    var evt = document.createEvent("MouseEvents");
    evt.initMouseEvent(
      "click",
      true,
      true,
      window,
      1,
      0,
      0,
      0,
      0,
      false,
      false,
      false,
      false,
      0,
      null
    );
    save.dispatchEvent(evt);
    window.URL.revokeObjectURL(save.href);
  }

  loadScripts() {
    const dynamicScripts = [
      "assets/js/core/jquery.min.js",
      "assets/js/core/popper.min.js",
      "assets/js/core/bootstrap.min.js",
      "assets/js/plugins/bootstrap-switch.js",
      "assets/js/plugins/nouislider.min.js",
      "assets/js/plugins/bootstrap-datepicker.js",
      "assets/js/now-ui-kit.js?v=1.2.0",
      "assets/js/main.js",
      // 'assets/js/jquery.fancybox.min.js'
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement("script");
      node.src = dynamicScripts[i];
      node.type = "text/javascript";
      node.async = false;
      node.charset = "utf-8";
      document.getElementsByTagName("head")[0].appendChild(node);
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, "landing-page");
    this.renderer.removeClass(document.body, "sidebar-collapse");
  }
}
