<div class="row" style="overflow: hidden;">
  <div class="col-md-4">
    <div><img src="assets/img/management-team/pst_solomon.png" style="width: 100%;" /></div>
  </div>
  <div class="col-md-8">
    <div class="row float-right" style="margin-top: 10px;margin-right: 20px;"><button type="button" class="close"
        (click)="onNoClick()">×</button></div>
    <h2>SOLOMON KPANDEI</h2>
    <p style="font-size: 1.2em;">
      Solomon Kpandei is an Associate Pastor and currently serves as Head of Ministry Team. In this role, he mobilizes
      the church towards the fulfilment of the ministry purpose of turning every member into a minister. He supervises
      all specialized ministries and some of the service units, which include the Starguard and Protocol units, and so
      many others. He supervises Daystar Ministry School.
    </p>
    <p>
      Pastor Solomon holds an MBA from Business School Netherlands and is currently pursuing a doctoral degree in
      Strategic Leadership at the Regent University, USA. He is an experienced HR professional.<br /><br />

      He is married to Joan and they are blessed with three children.
    </p>
  </div>
</div>
