<app-header></app-header>
<div class="wrapper">
  <div class="page-header page-header-small">
    <div
      class="page-header-image"
      data-parallax="true"
      style="background-image: url('../assets/img/bg6.jpg')"
    ></div>
    <div class="content-center">
      <div class="container">
        <h1 class="title">Download Messages</h1>
      </div>
      <script>
        console.log;
        window.location.href = "http://www.google.com";
      </script>
    </div>
  </div>

  <!--Start breadcrumb bottom area-->
  <section style="margin-bottom: 0px; padding-bottom: 0px">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="left pull-left footer">
            <a routerLink="/about">
              <span style="font-weight: bold">Home</span>
            </a>
            <a routerLink="#">
              <span style="font-weight: bold">&nbsp;|&nbsp;</span>
            </a>
            <a routerLink="#">
              <span style="font-weight: bold">Messages for download</span>
            </a>
          </div>
          <div class="right pull-right footer">
            <a href="#">
              <span
                ><i class="fa fa-share-alt" aria-hidden="true"></i
                >&nbsp;&nbsp;Share</span
              > </a
            >&nbsp;&nbsp;
            <a href="#">
              <span
                ><i class="fab fa-facebook-square" aria-hidden="true"></i
              ></span> </a
            >&nbsp;&nbsp;
            <a href="#">
              <span><i class="fab fa-twitter" aria-hidden="true"></i></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <script>
    $(document).ready(function () {
      alert("Hi there");
    });
  </script>
  <!--End breadcrumb bottom area-->

  <!--Start breadcrumb bottom area-->
  <section style="margin-bottom: 0px; padding-bottom: 0px">
    <div class="container">
      <div class="row">
        <div class="col-md-12" style="padding: 20px">
          <span *ngFor="let message of messages">
            <a
              class="btn btn-primary btn-round btn-lg text-wrap"
              style="width: 100%"
              target="_blank"
              href="{{ message.resouces_file }}"
            >
              <i class="now-ui-icons arrows-1_cloud-download-93"></i> Download
              {{ message.resource_name }} | {{ message.resource_author }}
              <!-- <strong>{{ bulletin.date }}</strong>  --> </a
            ><br />
          </span>
        </div>
      </div>
    </div>
  </section>
  <!--End breadcrumb bottom area-->
  <app-footer-link></app-footer-link>
  <app-footer></app-footer>
</div>
<!-- href="{{ message.resouces_file }}" -->
