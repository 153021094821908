<div class="row" style="overflow: hidden;">
  <div class="col-md-4">
    <div><img src="assets/img/management-team/pst_kayode.png" style="width: 100%;" /></div>
  </div>
  <div class="col-md-8">
    <div class="row float-right" style="margin-top: 10px;margin-right: 20px;"><button type="button" class="close"
        (click)="onNoClick()">×</button></div>
    <h2>KAYODE OLUTAYO</h2>
    <p style="font-size: 1.2em;">
      Kayode Olutayo is an Associate Pastor, a District Pastor and currently serves as the Team Lead of the Daystar
      Online Community: the Global small group system that caters to all Daystar members within and outside the country.
    </p>
    <p>
      He is a graduate of Mechanical Engineering, holds an MBA in Human Resources, and is an alumnus of Lagos Business
      School. He is a strategic system developer and business/data analyst with Digital Marketing skills, and his career
      experience spans over 17 years at Senior Managerial levels. Kayode is an NLP practitioner, a certified
      Professional Counsellor of the International Institute of Faith Based Counselling, IIFBC (USA), and an associate
      member of the Institute of Family System Engineering and Development. He is a Relationship and Family Life Coach,
      and also a faculty member at the Daystar Leadership Academy.<br /><br />
      He is married to his queen of 21 years, Kemi, and they are blessed with three children.
    </p>
  </div>
</div>
