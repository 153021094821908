import { Component, OnDestroy, OnInit, Renderer2 } from "@angular/core";

@Component({
  selector: "app-volunteer-handbook",
  templateUrl: "./volunteer-handbook.component.html",
  styleUrls: ["./volunteer-handbook.component.css"],
})
export class VolunteerHandbookComponent implements OnInit, OnDestroy {

  pdfSrc = './assets/img/docs/volunteer-handbook.pdf';

  constructor(private renderer: Renderer2) {
    this.renderer.addClass(document.body, "landing-page");
    this.renderer.addClass(document.body, "sidebar-collapse");
  }

  ngOnInit(): void {
    this.loadScripts();
  }

  loadScripts() {
    const dynamicScripts = [
      "assets/js/core/jquery.min.js",
      "assets/js/core/popper.min.js",
      "assets/js/core/bootstrap.min.js",
      "assets/js/plugins/bootstrap-switch.js",
      "assets/js/plugins/nouislider.min.js",
      "assets/js/plugins/bootstrap-datepicker.js",
      "assets/js/now-ui-kit.js?v=1.2.0",
      "assets/js/main.js",
      // 'assets/js/jquery.fancybox.min.js'
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement("script");
      node.src = dynamicScripts[i];
      node.type = "text/javascript";
      node.async = false;
      node.charset = "utf-8";
      document.getElementsByTagName("head")[0].appendChild(node);
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, "landing-page");
    this.renderer.removeClass(document.body, "sidebar-collapse");
  }
}
