import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    Accept: "application/json",
  }),
};

@Injectable({
  providedIn: "root",
})
export class DaystarServiceService {
  // readonly mainURL = 'http://localhost:8080/daystarmobileappapi/api/';

  readonly mainURL = "https://mobile.daystarng.org/api/";

  constructor(private http: HttpClient) {}

  registerUser(user: any) {
    const endPoint = "register";

    return this.http
      .post(this.mainURL + endPoint, user, httpOptions)
      .pipe(catchError(this.handleError));
  }

  submitContact(name: string, email: string, subject: string, message: string) {
    let payload: any = {
      feedback_type: "church",
      fullname: name,
      email,
      comment: subject + "<br/>" + message,
    };

    const endPoint = "feedback";

    return this.http
      .post(this.mainURL + endPoint, payload, httpOptions)
      .pipe(catchError(this.handleError));
  }

  submitSubscribe(email: string) {
    let payload: any = {
      email,
    };

    const endPoint = "subscribe";

    return this.http
      .post(this.mainURL + endPoint, payload, httpOptions)
      .pipe(catchError(this.handleError));
  }

  getOutlineFiles() {
    const endPoint = "getOutlineFiles";

    return this.http
      .get(this.mainURL + endPoint, httpOptions)
      .pipe(catchError(this.handleError));
  }

  getBulletinFiles() {
    const endPoint = "getBulletinFiles";

    return this.http
      .get(this.mainURL + endPoint, httpOptions)
      .pipe(catchError(this.handleError));
  }

  getMessages() {
    const endPoint = "getMessages";

    return this.http
      .get(this.mainURL + endPoint, httpOptions)
      .pipe(catchError(this.handleError));
  }

  getLatestSliders() {
    const endPoint = "getAnnouncements";

    return this.http
      .get(this.mainURL + endPoint, httpOptions)
      .pipe(catchError(this.handleError));
  }

  locateCell(type: string, area: string, busstop: string, street: string) {
    const endPoint =
      "locateCell/" + type + "/" + area + "/" + busstop + "/" + street;

    return this.http
      .get(this.mainURL + endPoint, httpOptions)
      .pipe(catchError(this.handleError));
  }

  getBibleReadings() {
    const endPoint = "getBibleReading";

    return this.http
      .get(this.mainURL + endPoint, httpOptions)
      .pipe(catchError(this.handleError));
  }

  getBibleDetail(passage: string) {
    const endPoint = "https://bible.daystarng.org/v1/searchv/";

    return this.http
      .get(endPoint + passage, httpOptions)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }
}
