<div class="row" style="overflow: hidden;">
  <div class="col-md-4">
    <div><img src="assets/img/management-team/pst_grace.png" style="width: 100%;" /></div>
  </div>
  <div class="col-md-8">
    <div class="row float-right" style="margin-top: 10px;margin-right: 20px;"><button type="button" class="close"
        (click)="onNoClick()">×</button></div>
    <h2>GRACE OFILI</h2>
    <p style="font-size: 1.2em;">
      Grace Ofili is an Associate Pastor and currently serves as Head of Maturity Team. In this role, she mobilizes the
      church towards the fulfilment of the maturity purpose of turning every member into a disciple. She supervises all
      of the service units under the Maturity team which include Bible Study, Counselling, Communion and Prayer units.
      She supervises Daystar Maturity School.
    </p>
    <p>
      Pastor Grace holds a Bachelor's Degree in Theology (B.Th.) from the reputable LIFE Theological Seminary, Lagos
      Nigeria. She is a Certified Psychiatric Nurse and Midwife, a Personal Health Coach, a certified Leadership Expert
      and Counsellor, and a faculty member of the renowned Daystar Leadership Academy. She is a beacon of hope to many
      singles, and married couples.<br /><br />

      She is married to Obed and they are blessed with a lovely daughter.
    </p>
  </div>
</div>
