<div class="row" style="overflow: hidden;">
  <div class="col-md-4">
    <div><img src="assets/img/management-team/pst_ayo.png" style="width: 100%;" /></div>
  </div>
  <div class="col-md-8">
    <div class="row float-right" style="margin-top: 10px;margin-right: 20px;"><button type="button" class="close"
        (click)="onNoClick()">×</button></div>
    <h2>AYO BANKOLE</h2>
    <p style="font-size: 1.2em;">
      Ayo Bankole is an Associate Pastor, a District Pastor and currently the Online Church Pastor.<br /><br />

      He holds a Bachelor's Degree in Environmental Management, and is currently running a Master’s Degree in Psychology
      at the Liverpool John Moores University in the UK. He is a Certified Digital Marketer, a professionally-trained
      Christian Counsellor and Faith-Based Therapist, Certified Family Life Practitioner, and Certified Church
      Administrator. He has certificate-level trainings in Google Analytics, Project Management, Business Modelling,
      Process Development, and Leadership. He has an Advanced Diploma in Programming.<br />
    </p>
    <p>
      He loves to work with couples using evidence-based psychometric solutions in identifying latent issues and
      creating an atmosphere where everyone can live to their highest potentials.<br /><br />
      He is happily married to Yemisi and they are blessed with two children.
    </p>
  </div>
</div>
