import { Component, OnInit, Renderer2 } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AmaraUgochukwuComponent } from '../Teams/amara-ugochukwu/amara-ugochukwu.component';
import { AyoBankoleComponent } from '../Teams/ayo-bankole/ayo-bankole.component';
import { BenjamineOkpalaComponent } from '../Teams/benjamine-okpala/benjamine-okpala.component';
import { BolutifeOluyomiComponent } from '../Teams/bolutife-oluyomi/bolutife-oluyomi.component';
import { BoyeOloyedeComponent } from '../Teams/boye-oloyede/boye-oloyede.component';
import { GabrielKolawoleComponent } from '../Teams/gabriel-kolawole/gabriel-kolawole.component';
import { GraceOfiliComponent } from '../Teams/grace-ofili/grace-ofili.component';
import { KayodeOlutayoComponent } from '../Teams/kayode-olutayo/kayode-olutayo.component';
import { MurphyEyenikeComponent } from '../Teams/murphy-eyenike/murphy-eyenike.component';
import { NikeAdeyemiComponent } from '../Teams/nike-adeyemi/nike-adeyemi.component';
import { RalphSegunDadaComponent } from '../Teams/ralph-segun-dada/ralph-segun-dada.component';
import { SamAdeyemiComponent } from '../Teams/sam-adeyemi/sam-adeyemi.component';
import { SeunLadokunComponent } from '../Teams/seun-ladokun/seun-ladokun.component';
import { SeunOgundeluComponent } from '../Teams/seun-ogundelu/seun-ogundelu.component';
import { SheyiKehnnyComponent } from '../Teams/sheyi-kehnny/sheyi-kehnny.component';
import { SolomonKpandeiComponent } from '../Teams/solomon-kpandei/solomon-kpandei.component';
import { TimilehinMogajiComponent } from '../Teams/timilehin-mogaji/timilehin-mogaji.component';
import { WaleOresanwoComponent } from '../Teams/wale-oresanwo/wale-oresanwo.component';

@Component({
  selector: 'app-management-team',
  templateUrl: './management-team.component.html',
  styleUrls: ['./management-team.component.css']
})
export class ManagementTeamComponent implements OnInit {

  constructor(private renderer: Renderer2, public dialog: MatDialog,) {
    this.renderer.addClass(document.body, 'landing-page');
    this.renderer.addClass(document.body, 'sidebar-collapse');
    this.loadScripts();
  }

  ngOnInit() {
  }

  openSamAdeyemiProfile() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width = '80%';
    dialogConfig.height = '80%';
    dialogConfig.data = {};

    this.dialog.open(SamAdeyemiComponent, dialogConfig)
      .afterClosed()
      .subscribe(res => {

      });
  }

  openNikeAdeyemiProfile() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width = '80%';
    dialogConfig.height = '80%';
    dialogConfig.data = {};

    this.dialog.open(NikeAdeyemiComponent, dialogConfig)
      .afterClosed()
      .subscribe(res => {

      });
  }

  openBoyeOloyedeProfile() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width = '80%';
    dialogConfig.height = '80%';
    dialogConfig.data = {};

    this.dialog.open(BoyeOloyedeComponent, dialogConfig)
      .afterClosed()
      .subscribe(res => {

      });
  }

  openBolutifeOluyimoProfile() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width = '80%';
    dialogConfig.height = '80%';
    dialogConfig.data = {};

    this.dialog.open(BolutifeOluyomiComponent, dialogConfig)
      .afterClosed()
      .subscribe(res => {

      });
  }

  openSolomonKpandeiProfile() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width = '80%';
    dialogConfig.height = '80%';
    dialogConfig.data = {};

    this.dialog.open(SolomonKpandeiComponent, dialogConfig)
      .afterClosed()
      .subscribe(res => {

      });
  }

  openGraceOfiliProfile() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width = '80%';
    dialogConfig.height = '80%';
    dialogConfig.data = {};

    this.dialog.open(GraceOfiliComponent, dialogConfig)
      .afterClosed()
      .subscribe(res => {

      });
  }

  openKayodeOlutayoProfile() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width = '80%';
    dialogConfig.height = '80%';
    dialogConfig.data = {};

    this.dialog.open(KayodeOlutayoComponent, dialogConfig)
      .afterClosed()
      .subscribe(res => {

      });
  }

  openMurphyEyenikeProfile() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width = '80%';
    dialogConfig.height = '80%';
    dialogConfig.data = {};

    this.dialog.open(MurphyEyenikeComponent, dialogConfig)
      .afterClosed()
      .subscribe(res => {

      });
  }

  openSegunDadaProfile() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width = '80%';
    dialogConfig.height = '80%';
    dialogConfig.data = {};

    this.dialog.open(RalphSegunDadaComponent, dialogConfig)
      .afterClosed()
      .subscribe(res => {

      });
  }

  openTimiMogajiProfile() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width = '80%';
    dialogConfig.height = '80%';
    dialogConfig.data = {};

    this.dialog.open(TimilehinMogajiComponent, dialogConfig)
      .afterClosed()
      .subscribe(res => {

      });
  }

  openSeunOgundeluProfile() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width = '80%';
    dialogConfig.height = '80%';
    dialogConfig.data = {};

    this.dialog.open(SeunOgundeluComponent, dialogConfig)
      .afterClosed()
      .subscribe(res => {

      });
  }

  openSeunLadokunProfile() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width = '80%';
    dialogConfig.height = '80%';
    dialogConfig.data = {};

    this.dialog.open(SeunLadokunComponent, dialogConfig)
      .afterClosed()
      .subscribe(res => {

      });
  }


  openBenjamineOkpalaProfile() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width = '80%';
    dialogConfig.height = '80%';
    dialogConfig.data = {};

    this.dialog.open(BenjamineOkpalaComponent, dialogConfig)
      .afterClosed()
      .subscribe(res => {

      });
  }


  openAyoBankoleProfile() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width = '80%';
    dialogConfig.height = '80%';
    dialogConfig.data = {};

    this.dialog.open(AyoBankoleComponent, dialogConfig)
      .afterClosed()
      .subscribe(res => {

      });
  }


  openAmaraUgochukwuProfile() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width = '80%';
    dialogConfig.height = '80%';
    dialogConfig.data = {};

    this.dialog.open(AmaraUgochukwuComponent, dialogConfig)
      .afterClosed()
      .subscribe(res => {

      });
  }


  openGabrielKolawoleProfile() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width = '80%';
    dialogConfig.height = '80%';
    dialogConfig.data = {};

    this.dialog.open(GabrielKolawoleComponent, dialogConfig)
      .afterClosed()
      .subscribe(res => {

      });
  }

  openWaleOresanwoProfile() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width = '80%';
    dialogConfig.height = '80%';
    dialogConfig.data = {};

    this.dialog.open(WaleOresanwoComponent, dialogConfig)
      .afterClosed()
      .subscribe(res => {

      });
  }


  openSheyiKhennyProfile() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width = '80%';
    dialogConfig.height = '80%';
    dialogConfig.data = {};

    this.dialog.open(SheyiKehnnyComponent, dialogConfig)
      .afterClosed()
      .subscribe(res => {

      });
  }


  loadScripts() {
    const dynamicScripts = [
      'assets/js/core/jquery.min.js',
      'assets/js/core/popper.min.js',
      'assets/js/core/bootstrap.min.js',
      'assets/js/plugins/bootstrap-switch.js',
      'assets/js/plugins/nouislider.min.js',
      'assets/js/plugins/bootstrap-datepicker.js',
      'assets/js/now-ui-kit.js?v=1.2.0',
      'assets/js/main.js',
      // 'assets/js/jquery.fancybox.min.js'
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'landing-page');
    this.renderer.removeClass(document.body, 'sidebar-collapse');
  }

}
