<app-header></app-header>
<div class="wrapper">
  <div class="page-header page-header-small">
    <div class="page-header-image" data-parallax="true" style="background-image: url('../assets/img/bg6.jpg');">
    </div>
    <div class="content-center">
      <div class="container">
        <h1 class="title">Not Found</h1>
      </div>
    </div>
  </div>
  <!--Start breadcrumb bottom area-->
  <section style="margin-bottom: 0px; padding-bottom: 0px;">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="left pull-left footer">
            <a routerLink="/care">
              <span style="font-weight: bold">Home</span>
            </a>
            <a routerLink="#">
              <span style="font-weight: bold">&nbsp;|&nbsp;</span>
            </a>
            <a routerLink="#">
              <span style="font-weight: bold">Page Not found</span>
            </a>
          </div>
          <div class="right pull-right footer">
            <a href="#">
              <span><i class="fa fa-share-alt" aria-hidden="true"></i>Share</span>
            </a>&nbsp;&nbsp;
            <a href="#">
              <span><i class="fab fa-facebook-square" aria-hidden="true"></i></span>
            </a>&nbsp;&nbsp;
            <a href="#">
              <span><i class="fab fa-twitter" aria-hidden="true"></i></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--End breadcrumb bottom area-->
  <div class="section section-about-us">
    <div class="container text-center">
      <img src="assets/img/404.jpg" />
    </div>
  </div>
  <app-footer-link></app-footer-link>
  <app-footer></app-footer>
</div>
