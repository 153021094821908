<app-header></app-header>
<div class="wrapper">
  <div class="page-header page-header-small">
    <div class="page-header-image" data-parallax="true" style="background-image: url('../assets/img/bg6.jpg');">
    </div>
    <div class="content-center">
      <div class="container">
        <h1 class="title">Bible Reading for {{date}} - {{passage}}</h1>
      </div>
    </div>
  </div>
  <!--Start breadcrumb bottom area-->
  <section style="margin-bottom: 0px; padding-bottom: 0px;">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="left pull-left footer">
            <a routerLink="/about">
              <span style="font-weight: bold">Bible Reading</span>
            </a>
            <a routerLink="#">
              <span style="font-weight: bold">&nbsp;|&nbsp;</span>
            </a>
            <a routerLink="#">
              <span style="font-weight: bold">{{passage}}</span>
            </a>
          </div>
          <div class="right pull-right footer">
            <a href="#">
              <span><i class="fa fa-share-alt" aria-hidden="true"></i>&nbsp;&nbsp;Share</span>
            </a>&nbsp;&nbsp;
            <a href="#">
              <span><i class="fab fa-facebook-square" aria-hidden="true"></i></span>
            </a>&nbsp;&nbsp;
            <a href="#">
              <span><i class="fab fa-twitter" aria-hidden="true"></i></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--End breadcrumb bottom area-->
  <!--Start breadcrumb bottom area-->
  <section style="margin-bottom: 0px; padding-bottom: 0px;">
    <div class="container">
      <div class="row">
        <div class="col-md-12" style="padding: 20px;">
          <h2 style="margin-top: 5px; align-items: center;">Bible Reading for {{date}} - {{passage}}</h2>
          <ng-container *ngFor="let bible_reading of bible_readings; let i = index">
            <div class="blockquote blockquote-primary">
              <p class="minifont">{{bible_reading.chapter_id}}:{{bible_reading.verse_numb}} -
                {{bible_reading.Verse_cont}}
              </p>
            </div>
          </ng-container>

          <!-- <div class="typography-line" *ngFor="let bible_reading of bible_readings; let i = index">
           
          </div> -->
          <!-- <span *ngFor="let bible_reading of bible_readings; let i = index">
            <p class="minifont">{{bible_reading.chapter_id}}:{{bible_reading.verse_numb}} - {{bible_reading.Verse_cont}}
            </p>
            <br />
          </span> -->
        </div>
      </div>
    </div>
  </section>
  <!--End breadcrumb bottom area-->
  <app-footer-link></app-footer-link>
  <app-footer></app-footer>
</div>
