<div class="row" style="overflow: hidden;">
  <div class="col-md-4">
    <div><img src="assets/img/management-team/pst_timi.png" style="width: 100%;" /></div>
  </div>
  <div class="col-md-8">
    <div class="row float-right" style="margin-top: 10px;margin-right: 20px;"><button type="button" class="close"
        (click)="onNoClick()">×</button></div>
    <h2>TIMILEHIN MOGAJI</h2>
    <p style="font-size: 1.2em;">
      Timilehin Mogaji is an Associate Pastor, a District Pastor overseeing two Districts, and currently serves as the
      Supervising Pastor for Starhub (the youth expression of Daystar).</p>
    <p>
      Pastor Timilehin holds a Bachelor’s degree in Microbiology and is currently undertaking an MBA program at The
      University of South Wales. He is a certified Faith-Based Counsellor with the Institute of Faith Based Counselling,
      USA. He has over 10 years of experience in Human Resources Management. He is an Associate Member of the
      Association of Professional Recruitment Consultants, UK, and a faculty member of the prestigious Daystar
      Leadership Academy.<br /><br />

      He is married to Ifeanyi and they are blessed with two children.
    </p>
  </div>
</div>
