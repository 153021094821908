<div class="row" style="overflow: hidden;">
  <div class="col-md-4">
    <div><img src="assets/img/management-team/pst_seun.png" style="width: 100%;" /></div>
  </div>
  <div class="col-md-8">
    <div class="row float-right" style="margin-top: 10px;margin-right: 20px;"><button type="button" class="close"
        (click)="onNoClick()">×</button></div>
    <h2>SEUN OGUNDELU</h2>
    <p style="font-size: 1.2em;">
      Seun Ogundelu is an Associate Pastor, and a District Pastor overseeing two Districts.<br /><br />

      He holds a degree in Chemistry from Obafemi Awolowo University, an MBA with specialty in Human Resources from the
      same institution and has obtained several certifications and trainings in the field of Leadership, Management,
      Business Strategies, Counseling, and Family life. He has successfully completed personal development and
      leadership programs from Rhema Bible Training Institute.</p>
    <p>
      Pastor Seun is an insightful and proficient teacher, trainer, life coach and motivational speaker.<br /><br />

      He is happily married to Mabel, and they blessed with three lovely children.
    </p>
  </div>
</div>
