import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-solomon-kpandei',
  templateUrl: './solomon-kpandei.component.html',
  styleUrls: ['./solomon-kpandei.component.css']
})
export class SolomonKpandeiComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SolomonKpandeiComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
