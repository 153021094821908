<app-header></app-header>
<div class="wrapper">
  <div class="page-header page-header-small">
    <div class="page-header-image" data-parallax="true" style="background-image: url('../assets/img/bg6.jpg');">
    </div>
    <div class="content-center">
      <div class="container">
        <h1 class="title">Today's Bible Reading</h1>
      </div>
    </div>
  </div>

  <!--Start breadcrumb bottom area-->
  <section style="margin-bottom: 0px; padding-bottom: 0px;">
    <div class="container">
      <div class="row">
        <div class="col-md-12" style="padding: 20px;">
          <span *ngFor="let bible_reading of bible_readings; let i = index">
            <h5 style="margin-top: 5px; align-items: center;" *ngIf="i == 0">{{bible_reading.date}}</h5>
            <a class="btn btn-primary btn-round btn-lg  text-wrap" style="width: 100%;"
              href="/bible-reading-detail?date={{bible_reading.date}}&passage={{bible_reading.passage}}"
              target="_blank"> Read
              <strong>{{bible_reading.passage}} </strong>
            </a><br />
          </span>
        </div>
      </div>
    </div>
  </section>
  <!--End breadcrumb bottom area-->
  <app-footer-link></app-footer-link>
  <app-footer></app-footer>
</div>
