<app-header></app-header>
<div class="wrapper">
  <div class="page-header page-header-small">
    <div
      class="page-header-image"
      data-parallax="true"
      style="background-image: url('../assets/img/bg6.jpg')"
    ></div>
    <div class="content-center">
      <div class="container">
        <h1 class="title">Daystar 25th Anniversary</h1>
      </div>
    </div>
  </div>

  <!--Start breadcrumb bottom area-->
  <section style="margin-bottom: 0px; padding-bottom: 0px">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="left pull-left footer">
            <a routerLink="/about">
              <span style="font-weight: bold">Home</span>
            </a>
            <a routerLink="#">
              <span style="font-weight: bold">&nbsp;|&nbsp;</span>
            </a>
            <a routerLink="#">
              <span style="font-weight: bold">Daystar 25th Anniversary</span>
            </a>
          </div>
          <div class="right pull-right footer">
            <a href="#">
              <span
                ><i class="fa fa-share-alt" aria-hidden="true"></i
                >&nbsp;&nbsp;Share</span
              > </a
            >&nbsp;&nbsp;
            <a href="#">
              <span
                ><i class="fab fa-facebook-square" aria-hidden="true"></i
              ></span> </a
            >&nbsp;&nbsp;
            <a href="#">
              <span><i class="fab fa-twitter" aria-hidden="true"></i></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--End breadcrumb bottom area-->
  <!--Start breadcrumb bottom area-->
  <section style="section section-about-us">
    <div class="container">
      <div class="row">

        <div class="col-lg-4 col-sm-6 text-center">
          <div class="card">
            <p></p>
            <img
              src="https://mobile.daystarng.org/img/resources/25th-anniv-logo-silver.png"
              class="card-img-top"
              alt="Daystar 25th Anniversary Logo(Silver)"
            />
            <div class="card-body">
              <h5 class="card-title">Daystar 25th Anniversary Logo(Silver)</h5>
              <button
                class="btn btn-large btn-primary"
                (click)="downloadImage('https://mobile.daystarng.org/img/resources/25th-anniv-logo-silver.png')"
                >Download Form</button>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6 text-center">
          <div class="card">
            <p></p>
            <img
              src="https://mobile.daystarng.org/img/resources/25thAnniversaryLogo.png"
              class="card-img-top"
              alt="Daystar 25th Anniversary Logo"
            />
            <div class="card-body">
              <h5 class="card-title">Daystar 25th Anniversary Logo</h5>
              <button
                class="btn btn-large btn-primary"
                (click)="downloadImage('https://mobile.daystarng.org/img/resources/25thAnniversaryLogo.png')"
                >Download Form</button>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6 text-center">
          <div class="card">
            <p></p>
            <img
              src="https://mobile.daystarng.org/img/resources/DaystarFrameSM2.png"
              class="card-img-top"
              alt="Daystar Forms"
            />
            <div class="card-body">
              <h5 class="card-title">Daystar 25th Anniversary Instagram</h5>
              <button
                class="btn btn-large btn-primary"
                (click)="downloadImage('https://mobile.daystarng.org/img/resources/DaystarFrameSM2.png')"
                >Download Form</button>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6 text-center">
          <div class="card">
            <p></p>
            <img
              src="https://mobile.daystarng.org/img/resources/DaystarFrameSM.png"
              class="card-img-top"
              alt="Daystar Forms"
            />
            <div class="card-body">
              <h5 class="card-title">Daystar 25th Anniversary Twitter</h5>
              <button
                class="btn btn-large btn-primary"
                (click)="downloadImage('https://mobile.daystarng.org/img/resources/DaystarFrameSM.png')"
                >Download Form</button>
            </div>
          </div>
        </div>

        <!-- <div class="col-lg-3 col-sm-6 text-center">
          <div class="card">
            <p></p>
            <img src="assets/img/forms.png" class="card-img-top" alt="Daystar Forms">
            <div class="card-body">
              <h5 class="card-title">Marriage Registry </h5>
              <a href="https://mobile.daystarng.org/forms/MarriageRegistryForm.pdf" target="_blank"
                class="btn btn-large btn-primary">Download Form</a>
            </div>
          </div>
        </div> -->

        <div class="col-lg-8 col-sm-6 text-center">
          <div class="card">
            <p></p>
            <img
              src="https://mobile.daystarng.org/img/resources/Daystar25AnniFrame.png"
              class="card-img-top"
              alt="Daystar Forms"
            />
            <div class="card-body">
              <h5 class="card-title">Daystar 25th Anniversary Picture Frame</h5>
              <button
                class="btn btn-large btn-primary"
                (click)="downloadImage('https://mobile.daystarng.org/img/resources/Daystar25AnniFrame.png')"
                >Download Form</button>
            </div>
          </div>
        </div>

      </div>
      <div class="separator separator-primary"></div>
    </div>
  </section>
  <!--End breadcrumb bottom area-->
  <app-footer-link></app-footer-link>
  <app-footer></app-footer>
</div>