import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AlertModule } from 'ngx-alerts';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxSmartModalModule } from 'ngx-smart-modal';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { AboutComponent } from './components/about/about.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { FooterLinkComponent } from './components/footer-link/footer-link.component';
import { ConnectComponent } from './components/connect/connect.component';
import { CareComponent } from './components/care/care.component';
import { WatchComponent } from './components/watch/watch.component';
import { GiveComponent } from './components/give/give.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { BulletinComponent } from './components/bulletin/bulletin.component';
import { OutlineComponent } from './components/outline/outline.component';
import { CellLocatorComponent } from './components/cell-locator/cell-locator.component';
import { FormDownloadComponent } from './components/form-download/form-download.component';
import { BibleReadingComponent } from './components/bible-reading/bible-reading.component';
import { BibleReadingDetailsComponent } from './components/bible-reading-details/bible-reading-details.component';
import { ZonalCoordinatorsComponent } from './components/zonal-coordinators/zonal-coordinators.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { CareersComponent } from './components/careers/careers.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { PopupMessageComponent } from './components/popup-message/popup-message.component';
import { DownloadsComponent } from './components/downloads/downloads.component';
import { Daystar25thanniversaryComponent } from './components/daystar25thanniversary/daystar25thanniversary.component';
import { PaymentCodesComponent } from './components/payment-codes/payment-codes.component';
import { TestimoniesComponent } from './components/testimonies/testimonies.component';
import { ManagementTeamComponent } from './components/management-team/management-team.component';
import { BoyeOloyedeComponent } from './components/Teams/boye-oloyede/boye-oloyede.component';
import { BolutifeOluyomiComponent } from './components/Teams/bolutife-oluyomi/bolutife-oluyomi.component';
import { SolomonKpandeiComponent } from './components/Teams/solomon-kpandei/solomon-kpandei.component';
import { GraceOfiliComponent } from './components/Teams/grace-ofili/grace-ofili.component';
import { KayodeOlutayoComponent } from './components/Teams/kayode-olutayo/kayode-olutayo.component';
import { RalphSegunDadaComponent } from './components/Teams/ralph-segun-dada/ralph-segun-dada.component';
import { MurphyEyenikeComponent } from './components/Teams/murphy-eyenike/murphy-eyenike.component';
import { TimilehinMogajiComponent } from './components/Teams/timilehin-mogaji/timilehin-mogaji.component';
import { SeunOgundeluComponent } from './components/Teams/seun-ogundelu/seun-ogundelu.component';
import { SeunLadokunComponent } from './components/Teams/seun-ladokun/seun-ladokun.component';
import { BenjamineOkpalaComponent } from './components/Teams/benjamine-okpala/benjamine-okpala.component';
import { AyoBankoleComponent } from './components/Teams/ayo-bankole/ayo-bankole.component';
import { AmaraUgochukwuComponent } from './components/Teams/amara-ugochukwu/amara-ugochukwu.component';
import { GabrielKolawoleComponent } from './components/Teams/gabriel-kolawole/gabriel-kolawole.component';
import { WaleOresanwoComponent } from './components/Teams/wale-oresanwo/wale-oresanwo.component';
import { SheyiKehnnyComponent } from './components/Teams/sheyi-kehnny/sheyi-kehnny.component';
import { SamAdeyemiComponent } from './components/Teams/sam-adeyemi/sam-adeyemi.component';
import { NikeAdeyemiComponent } from './components/Teams/nike-adeyemi/nike-adeyemi.component';
import { ConfessionMessageComponent } from './components/confession-message/confession-message.component';
import { FileSaverModule } from 'ngx-filesaver';
import { StrongerTogetherComponent } from './components/stronger-together/stronger-together.component';
import { ReflexionsComponent } from './components/reflexions/reflexions.component';
import { VolunteerHandbookComponent } from './components/volunteer-handbook/volunteer-handbook.component';
import { TimelessComponent } from './components/timeless/timeless.component';
import { NewsletterComponent } from './components/newsletter/newsletter.component';
import { EventsComponent } from './components/events/events.component';
import { SubmitTestimonyComponent } from './components/submit-testimony/submit-testimony.component';
import { UploadTestimonyComponent } from './components/upload-testimony/upload-testimony.component';
import { MessagesComponent } from './components/messages/messages.component';


const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'daystarng.org'// it is recommended to set your domain, for cookies to work properly
  },
  palette: {
    popup: {
      background: '#0F1E7A'
    },
    button: {
      background: '#ED3237'
    }
  },
  theme: 'edgeless',
  type: 'opt-out',
  layout: 'my-custom-layout',
  layouts: {
    "my-custom-layout": '{{messagelink}}{{compliance}}'
  },
  elements: {
    messagelink: `
    <span id="cookieconsent:desc" class="cc-message">{{message}} 
     <a aria-label="learn more about our privacy policy" tabindex="1" class="cc-link" href="{{privacyPolicyHref}}" target="_blank">{{privacyPolicyLink}}</a> applies to you. 
    </span>
    `,
  },
  content: {
    message: 'By using our site,  you agree to and acknowledge that our  ',

    privacyPolicyLink: 'Data and Privacy Policy',
    privacyPolicyHref: 'https://daystarng.org/privacy-policy',
  }
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    HeaderComponent,
    FooterComponent,
    FooterLinkComponent,
    ConnectComponent,
    CareComponent,
    WatchComponent,
    GiveComponent,
    CalendarComponent,
    BulletinComponent,
    OutlineComponent,
    CellLocatorComponent,
    FormDownloadComponent,
    BibleReadingComponent,
    BibleReadingDetailsComponent,
    ZonalCoordinatorsComponent,
    NotFoundComponent,
    CareersComponent,
    PrivacyPolicyComponent,
    PopupMessageComponent,
    DownloadsComponent,
    Daystar25thanniversaryComponent,
    PaymentCodesComponent,
    TestimoniesComponent,
    ManagementTeamComponent,
    BoyeOloyedeComponent,
    BolutifeOluyomiComponent,
    SolomonKpandeiComponent,
    GraceOfiliComponent,
    KayodeOlutayoComponent,
    RalphSegunDadaComponent,
    MurphyEyenikeComponent,
    TimilehinMogajiComponent,
    SeunOgundeluComponent,
    SeunLadokunComponent,
    BenjamineOkpalaComponent,
    AyoBankoleComponent,
    AmaraUgochukwuComponent,
    GabrielKolawoleComponent,
    WaleOresanwoComponent,
    SheyiKehnnyComponent,
    SamAdeyemiComponent,
    NikeAdeyemiComponent,
    ConfessionMessageComponent,
    StrongerTogetherComponent,
    ReflexionsComponent,
    VolunteerHandbookComponent,
    TimelessComponent,
    NewsletterComponent,
    EventsComponent,
    SubmitTestimonyComponent,
    UploadTestimonyComponent,
    MessagesComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientModule,
    PdfViewerModule,
    NgxSpinnerModule,
    NgxSmartModalModule.forRoot(),
    MatDialogModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    AlertModule.forRoot({ maxMessages: 5, timeout: 5000, position: 'right' }),
    FileSaverModule
  ],
  entryComponents: [
    PopupMessageComponent,
    BoyeOloyedeComponent,
    BolutifeOluyomiComponent,
    SolomonKpandeiComponent,
    GraceOfiliComponent,
    KayodeOlutayoComponent,
    RalphSegunDadaComponent,
    MurphyEyenikeComponent,
    TimilehinMogajiComponent,
    SeunOgundeluComponent,
    SeunLadokunComponent,
    BenjamineOkpalaComponent,
    AyoBankoleComponent,
    AmaraUgochukwuComponent,
    GabrielKolawoleComponent,
    WaleOresanwoComponent,
    SheyiKehnnyComponent,
    SamAdeyemiComponent,
    NikeAdeyemiComponent,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

// https://maximelafarie.com/ngx-smart-modal/#/style
