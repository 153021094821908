<!-- Navbar -->
<nav
  class="navbar navbar-expand-lg bg-primary fixed-top navbar-transparent"
  color-on-scroll="400"
>
  <div class="container">
    <div class="navbar-translate">
      <a
        class="navbar-brand"
        routerLink="/"
        style="cursor: pointer"
        data-placement="bottom"
      >
        <img src="assets/img/logo.png" style="width: 60%" />
      </a>
      <button
        class="navbar-toggler navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navigation"
        aria-controls="navigation-index"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-bar top-bar"></span>
        <span class="navbar-toggler-bar middle-bar"></span>
        <span class="navbar-toggler-bar bottom-bar"></span>
      </button>
    </div>
    <div
      class="collapse navbar-collapse justify-content-end"
      id="navigation"
      data-nav-image="./assets/img/blurred-image-1.jpg"
    >
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" href="/about">
            <i class="now-ui-icons business_bank"></i>
            <p>About</p>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/connect">
            <i class="now-ui-icons users_circle-08"></i>
            <p>Connect</p>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/watch">
            <i class="now-ui-icons media-1_button-play"></i>
            <p>Watch</p>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/care">
            <i class="now-ui-icons ui-2_favourite-28"></i>
            <p>Care</p>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/give">
            <i class="now-ui-icons shopping_credit-card"></i>
            <p>Give</p>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://blog.daystarng.org" target="_blank">
            <i class="now-ui-icons education_paper"></i>
            <p>Blog</p>
          </a>
        </li>
        <li class="nav-item dropdown">
          <a
            href="#"
            class="nav-link dropdown-toggle"
            id="navbarDropdownMenuLink1"
            data-toggle="dropdown"
          >
            <span class="button-bar"></span>
            <span class="button-bar"></span>
            <span class="button-bar"></span>
          </a>
          <div
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="navbarDropdownMenuLink1"
          >
            <a class="dropdown-item" href="/bulletin"> Bulletin </a>
            <a class="dropdown-item" href="/bible-reading"> Bible Reading </a>
            <a class="dropdown-item" href="/calendar"> Daystar Calendar </a>
            <a class="dropdown-item" href="/events"> Daystar Events </a>
            <a class="dropdown-item" href="/forms-download"> Forms Download </a>
            <a class="dropdown-item" href="/outline"> Homecell Outline </a>
            <a class="dropdown-item" href="/messages"> Download Messages </a>
            <a
              class="dropdown-item"
              href="https://daystarng.org/starvesting/"
              target="_blank"
            >
              Starvesting
            </a>
            <!--<a class="dropdown-item" href="/zonal-coordinators">
              Zonal Co-ordinators
            </a>-->
            <a class="dropdown-item" href="/testimonies"> Submit Testimony </a>
            <a class="dropdown-item" href="/newsletter"> Newsletter </a>
            <a class="dropdown-item" href="/stronger-together">
              Stronger Together Commercials
            </a>
            <a class="dropdown-item" href="/giving-codes">
              Simple Ways To Give
            </a>
            <a class="dropdown-item" href="/careers"> Careers </a>
            <a class="dropdown-item" href="/volunteer-handbook">
              Volunteer Handbook
            </a>
          </div>

          <!-- <a
              class="dropdown-item"
              href="https://presenter.jivrus.com/p/1AK-SEUN7zOJuwVQ93aoA2Z4dOGIAw4lc7igzTzrxNXo"
            >
              Internship Test
            </a> -->

          <!-- <mat-menu #menu="matMenu">
<a class="dropdown-item" mat-menu-item routerLink="/bulletin">Bullein</a>
            <a class="dropdown-item" mat-menu-item routerLink="/outline">Outline</a> 
          </mat-menu> -->
        </li>
      </ul>
    </div>
  </div>
</nav>
<!-- End Navbar -->
