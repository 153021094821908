import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { AlertService } from 'ngx-alerts';
import { NgxSpinnerService } from 'ngx-spinner';
import { DaystarServiceService } from 'src/app/shared/daystar-service.service';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.css']
})
export class NewsletterComponent implements OnInit, OnDestroy {
  daystarForm: FormGroup;
  subscriptionSubmitted = false;

  title = 'Daystar Christian Centre - raising role models. | Sign up for our newsletter';
  constructor(
    private titleService: Title,
    private metaService: Meta,
    private renderer: Renderer2,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private alertService: AlertService,
    private daystarService: DaystarServiceService) {
    this.renderer.addClass(document.body, 'landing-page');
    this.renderer.addClass(document.body, 'sidebar-collapse');
    this.loadScripts();

    this.daystarForm = this.formBuilder.group({
      subscrptionForm: this.formBuilder.group({
        email: [
          '',
          [
            Validators.required,
            Validators.pattern(
              '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
            ),
          ],
        ],
      }),
    });
  }

  get f1() {
    return (this.daystarForm.get('subscrptionForm') as FormGroup).controls;
  }

  get subscrptionForm() {
    return this.daystarForm.get('subscrptionForm');
  }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      { name: 'keywords', content: 'daystar, daystarng, rolemodel, rolemodels, leaders, leadership, connect,' },
      { name: 'description', content: 'Signup for our newsletter to keep you updated on happenings in Daystar Christian Centre' },
      { name: 'robots', content: 'index, follow' }
    ]);
    // this.spinner.show();
    // this.daystarService.getLatestSliders().subscribe(
    //   (data: any) => {
    //     this.spinner.hide();
    //     const error: boolean = data.error;
    //     if (!error) {
    //       console.log(data);
    //       this.events = data;
    //     } else {
    //       this.alertService.danger(data.message);
    //     }
    //   },
    //   error => {
    //     this.spinner.hide();
    //     this.alertService.danger('An error occurred, Please try again');
    //   }
    // );
  }

  onSubscribeSubmit() {
    console.log('Subscribe');
    this.subscriptionSubmitted = true;

    // stop here if form is invalid
    if (this.subscrptionForm.invalid) {
      return;
    }

    this.spinner.show();
    this.daystarService
      .submitSubscribe(this.subscrptionForm.value.email)
      .subscribe(
        (data: any) => {
          this.spinner.hide();
          const error: boolean = data.error;
          if (!error) {
            // console.log(data);
            this.alertService.success(data.message);
            this.subscrptionForm.reset();
            this.subscriptionSubmitted = false;
            // console.log(this.cells);
          } else {
            this.alertService.danger(data.message);
          }
        },
        (error) => {
          this.spinner.hide();
          this.alertService.danger('An error occurred, Please try again');
        }
      );
  }


  loadScripts() {
    const dynamicScripts = [
      'assets/js/core/jquery.min.js',
      'assets/js/core/popper.min.js',
      'assets/js/core/bootstrap.min.js',
      'assets/js/plugins/bootstrap-switch.js',
      'assets/js/plugins/nouislider.min.js',
      'assets/js/plugins/bootstrap-datepicker.js',
      'assets/js/now-ui-kit.js?v=1.2.0',
      'assets/js/main.js',
      // 'assets/js/jquery.fancybox.min.js'
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'landing-page');
    this.renderer.removeClass(document.body, 'sidebar-collapse');
  }

}
