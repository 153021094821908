<app-header></app-header>
<div class="wrapper">
  <div class="page-header page-header-small">
    <div
      class="page-header-image"
      data-parallax="true"
      style="background-image: url('../assets/img/bg6.jpg')"
    ></div>
    <div class="content-center">
      <div class="container">
        <h1 class="title">2021 DAYSTAR REFLEXIONS AUDITION FORM</h1>
      </div>
    </div>
  </div>

  <!--Start breadcrumb bottom area-->
  <section style="margin-bottom: 0px; padding-bottom: 0px">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="left pull-left footer">
            <a routerLink="/about">
              <span style="font-weight: bold">Home</span>
            </a>
            <a routerLink="#">
              <span style="font-weight: bold">&nbsp;|&nbsp;</span>
            </a>
            <a routerLink="/reflexions">
              <span style="font-weight: bold"
                >2021 Daystar Reflexions Audition</span
              >
            </a>
          </div>
          <div class="right pull-right footer">
            <a href="#">
              <span
                ><i class="fa fa-share-alt" aria-hidden="true"></i
                >&nbsp;&nbsp;Share</span
              > </a
            >&nbsp;&nbsp;
            <a href="#">
              <span
                ><i class="fab fa-facebook-square" aria-hidden="true"></i
              ></span> </a
            >&nbsp;&nbsp;
            <a href="#">
              <span><i class="fab fa-twitter" aria-hidden="true"></i></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--End breadcrumb bottom area-->
  <!--Start breadcrumb bottom area-->
  <section style="margin-bottom: 0px; padding-bottom: 0px">
    <div class="container">
      <div class="row">
        <div class="col-md-12" style="padding: 20px">
          <iframe
            src="https://formfacade.com/headless/110248188638200178332/home/form/1FAIpQLSfn4ZHUtbXH0FPcaH_oar9jDBix_Itzvpzb1dnKCewM4KPnRA"
            scrolling="no"
            frameBorder="0"
            width="100%"
            style="height: 3000px; overflow: auto"
          ></iframe>
          <!-- <div class="container" id="ff-compose"></div>
          <script
            async
            defer
            src="https://formfacade.com/include/110248188638200178332/form/1FAIpQLSfn4ZHUtbXH0FPcaH_oar9jDBix_Itzvpzb1dnKCewM4KPnRA/bootstrap.js?div=ff-compose"
          ></script> -->
        </div>
      </div>
    </div>
  </section>
  <!--End breadcrumb bottom area-->
  <app-footer-link></app-footer-link>
  <app-footer></app-footer>
</div>
