<!-- <img src="assets/img/25-10-annc25102020.jpg" width="150%" /> -->
<!DOCTYPE html
  PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">

<html xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office"
  xmlns:v="urn:schemas-microsoft-com:vml">

<head>
  <!--[if gte mso 9
      ]><xml
        ><o:OfficeDocumentSettings
          ><o:AllowPNG /><o:PixelsPerInch
            >96</o:PixelsPerInch
          ></o:OfficeDocumentSettings
        ></xml
      ><!
    [endif]-->
  <meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
  <meta content="width=device-width" name="viewport" />
  <!--[if !mso]><!-->
  <meta content="IE=edge" http-equiv="X-UA-Compatible" />
  <!--<![endif]-->
  <title></title>
  <!--[if !mso]><!-->
  <link href="https://fonts.googleapis.com/css?family=Nunito" rel="stylesheet" type="text/css" />
  <!--<![endif]-->
  <style type="text/css">
    body {
      margin: 0;
      padding: 0;
    }

    table,
    td,
    tr {
      vertical-align: top;
      border-collapse: collapse;
    }

    * {
      line-height: inherit;
    }

    a[x-apple-data-detectors="true"] {
      color: inherit !important;
      text-decoration: none !important;
    }

  </style>
  <style id="media-query" type="text/css">
    @media (max-width: 620px) {

      .block-grid,
      .col {
        min-width: 320px !important;
        max-width: 100% !important;
        display: block !important;
      }

      .block-grid {
        width: 100% !important;
      }

      .col {
        width: 100% !important;
      }

      .col>div {
        margin: 0 auto;
      }

      img.fullwidth,
      img.fullwidthOnMobile {
        max-width: 100% !important;
      }

      .no-stack .col {
        min-width: 0 !important;
        display: table-cell !important;
      }

      .no-stack.two-up .col {
        width: 50% !important;
      }

      .no-stack .col.num2 {
        width: 16.6% !important;
      }

      .no-stack .col.num3 {
        width: 25% !important;
      }

      .no-stack .col.num4 {
        width: 33% !important;
      }

      .no-stack .col.num5 {
        width: 41.6% !important;
      }

      .no-stack .col.num6 {
        width: 50% !important;
      }

      .no-stack .col.num7 {
        width: 58.3% !important;
      }

      .no-stack .col.num8 {
        width: 66.6% !important;
      }

      .no-stack .col.num9 {
        width: 75% !important;
      }

      .no-stack .col.num10 {
        width: 83.3% !important;
      }

      .video-block {
        max-width: none !important;
      }

      .mobile_hide {
        min-height: 0px;
        max-height: 0px;
        max-width: 0px;
        display: none;
        overflow: hidden;
        font-size: 0px;
      }

      .desktop_hide {
        display: block !important;
        max-height: none !important;
      }
    }

  </style>
</head>

<body class="clean-body" style="
      margin: 0;
      padding: 0;
      -webkit-text-size-adjust: 100%;
      background-color: #fbfbfb;
    ">
  <!--[if IE]><div class="ie-browser"><![endif]-->
  <table bgcolor="#fbfbfb" cellpadding="0" cellspacing="0" class="nl-container" role="presentation" style="
        table-layout: fixed;
        vertical-align: top;
        min-width: 320px;
        border-spacing: 0;
        border-collapse: collapse;
        mso-table-lspace: 0pt;
        mso-table-rspace: 0pt;
        background-color: #fbfbfb;
        width: 100%;
      " valign="top" width="100%">
    <tbody>
      <tr style="vertical-align: top" valign="top">
        <td style="word-break: break-word; vertical-align: top" valign="top">
          <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td align="center" style="background-color:#fbfbfb"><![endif]-->
          <div style="background-color: transparent; overflow: hidden">
            <div class="block-grid two-up no-stack" style="
                  min-width: 320px;
                  max-width: 600px;
                  overflow-wrap: break-word;
                  word-wrap: break-word;
                  word-break: break-word;
                  margin: 0 auto;
                  width: 100%;
                  background-color: #e2dfd8;
                ">
              <div style="
                    border-collapse: collapse;
                    display: table;
                    width: 100%;
                    background-color: #e2dfd8;
                  ">
                <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:transparent;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px"><tr class="layout-full-width" style="background-color:#e2dfd8"><![endif]-->
                <!--[if (mso)|(IE)]><td align="center" width="300" style="background-color:#e2dfd8;width:300px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:10px; padding-bottom:10px;"><![endif]-->
                <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
                <!--[if (mso)|(IE)]></td><td align="center" width="300" style="background-color:#e2dfd8;width:300px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:0px; padding-bottom:0px;"><![endif]-->
                <div class="col num6" style="
                      display: table-cell;
                      vertical-align: top;
                      max-width: 320px;
                      min-width: 300px;
                      width: 300px;
                    ">
                  <div style="width: 100% !important">
                    <!--[if (!mso)&(!IE)]><!-->
                    <div style="
                          border-top: 0px solid transparent;
                          border-left: 0px solid transparent;
                          border-bottom: 0px solid transparent;
                          border-right: 0px solid transparent;
                          padding-top: 0px;
                          padding-bottom: 0px;
                          padding-right: 0px;
                          padding-left: 0px;
                        ">
                      <!--<![endif]-->
                      <div></div>
                      <!--[if (!mso)&(!IE)]><!-->
                    </div>
                    <!--<![endif]-->
                  </div>
                </div>
                <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
                <!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
              </div>
            </div>
          </div>
          <div style="background-color: transparent; overflow: hidden">
            <div class="block-grid" style="
                  min-width: 320px;
                  max-width: 600px;
                  overflow-wrap: break-word;
                  word-wrap: break-word;
                  word-break: break-word;
                  margin: 0 auto;
                  width: 100%;
                  background-color: #e2dfd8;
                ">
              <div style="
                    border-collapse: collapse;
                    display: table;
                    width: 100%;
                    background-color: #e2dfd8;
                  ">
                <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:transparent;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px"><tr class="layout-full-width" style="background-color:#e2dfd8"><![endif]-->
                <!--[if (mso)|(IE)]><td align="center" width="600" style="background-color:#e2dfd8;width:600px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:20px; padding-bottom:0px;"><![endif]-->
                <div class="col num12" style="
                      min-width: 320px;
                      max-width: 600px;
                      display: table-cell;
                      vertical-align: top;
                      width: 600px;
                    ">
                  <div style="width: 100% !important">
                    <!--[if (!mso)&(!IE)]><!-->
                    <div style="
                          border-top: 0px solid transparent;
                          border-left: 0px solid transparent;
                          border-bottom: 0px solid transparent;
                          border-right: 0px solid transparent;
                          padding-top: 20px;
                          padding-bottom: 0px;
                          padding-right: 0px;
                          padding-left: 0px;
                        ">
                      <!--<![endif]-->
                      <div align="center" class="img-container center fullwidthOnMobile autowidth"
                        style="padding-right: 0px; padding-left: 0px">
                        <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr style="line-height:0px"><td style="padding-right: 0px;padding-left: 0px;" align="center"><!
                          [endif]-->
                        <img align="center" alt="Lets gather to worship this Sunday" border="0"
                          class="center fullwidthOnMobile autowidth" src="assets/img/15-11-banner-900.jpg" style="
                              text-decoration: none;
                              -ms-interpolation-mode: bicubic;
                              height: auto;
                              border: 0;
                              width: 100%;
                              max-width: 600px;
                              display: block;
                            " title="Some Muffins From Our Store" width="600" />
                        <!--[if mso]></td></tr></table><![endif]-->
                      </div>
                      <!--[if (!mso)&(!IE)]><!-->
                    </div>
                    <!--<![endif]-->
                  </div>
                </div>
                <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
                <!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
              </div>
            </div>
          </div>
          <div style="background-color: transparent; overflow: hidden">
            <div class="block-grid" style="
                  min-width: 320px;
                  max-width: 600px;
                  overflow-wrap: break-word;
                  word-wrap: break-word;
                  word-break: break-word;
                  margin: 0 auto;
                  width: 100%;
                  background-color: #f0ebe5;
                ">
              <div style="
                    border-collapse: collapse;
                    display: table;
                    width: 100%;
                    background-color: #f0ebe5;
                  ">
                <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:transparent;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px"><tr class="layout-full-width" style="background-color:#f0ebe5"><![endif]-->
                <!--[if (mso)|(IE)]><td align="center" width="600" style="background-color:#f0ebe5;width:600px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:20px; padding-bottom:20px;"><![endif]-->
                <div class="col num12" style="
                      min-width: 320px;
                      max-width: 600px;
                      display: table-cell;
                      vertical-align: top;
                      width: 600px;
                    ">
                  <div style="width: 100% !important">
                    <!--[if (!mso)&(!IE)]><!-->
                    <div style="
                          border-top: 0px solid transparent;
                          border-left: 0px solid transparent;
                          border-bottom: 0px solid transparent;
                          border-right: 0px solid transparent;
                          padding-top: 20px;
                          padding-bottom: 20px;
                          padding-right: 0px;
                          padding-left: 0px;
                        ">
                      <!--<![endif]-->
                      <div align="center" class="img-container center fixedwidth"
                        style="padding-right: 15px; padding-left: 15px">
                        <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr style="line-height:0px"><td style="padding-right: 15px;padding-left: 15px;" align="center"><![endif]-->
                        <div style="font-size: 1px; line-height: 15px"> </div>
                        <img align="center" alt="5 Points Separator" border="0" class="center fixedwidth"
                          src="assets/img/separator.png" style="
                              text-decoration: none;
                              -ms-interpolation-mode: bicubic;
                              height: auto;
                              border: 0;
                              width: 100%;
                              max-width: 240px;
                              display: block;
                            " title="5 Points Separator" width="240" />
                        <div style="font-size: 1px; line-height: 15px"> </div>
                        <!--[if mso]></td></tr></table><![endif]-->
                      </div>
                      <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 10px; padding-left: 10px; padding-top: 10px; padding-bottom: 10px; font-family: Arial, sans-serif"><![endif]-->
                      <div style="
                            color: #0b474b;
                            font-family: Nunito, Arial, Helvetica Neue,
                              Helvetica, sans-serif;
                            line-height: 1.2;
                            padding-top: 10px;
                            padding-right: 10px;
                            padding-bottom: 10px;
                            padding-left: 10px;
                          ">
                        <div style="
                              line-height: 1.2;
                              font-size: 12px;
                              color: #0b474b;
                              font-family: Nunito, Arial, Helvetica Neue,
                                Helvetica, sans-serif;
                              mso-line-height-alt: 14px;
                            ">
                          <p style="
                                font-size: 24px;
                                line-height: 1.2;
                                word-break: break-word;
                                text-align: center;
                                mso-line-height-alt: 29px;
                                margin: 0;
                              ">
                            <span style="font-size: 24px">Missed physical worship? So did we.</span>
                          </p>
                        </div>
                      </div>
                      <!--[if mso]></td></tr></table><![endif]-->
                      <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 25px; padding-left: 25px; padding-top: 10px; padding-bottom: 10px; font-family: Arial, sans-serif"><![endif]-->
                      <div style="
                            color: #3c4243;
                            font-family: Nunito, Arial, Helvetica Neue,
                              Helvetica, sans-serif;
                            line-height: 1.8;
                            padding-top: 10px;
                            padding-right: 25px;
                            padding-bottom: 10px;
                            padding-left: 25px;
                          ">
                        <div style="
                              line-height: 1.8;
                              font-size: 12px;
                              color: #3c4243;
                              font-family: Nunito, Arial, Helvetica Neue,
                                Helvetica, sans-serif;
                              mso-line-height-alt: 22px;
                            ">
                          <p style="
                                line-height: 1.8;
                                word-break: break-word;
                                mso-line-height-alt: 22px;
                                margin: 0;
                              ">
                            As you return to church, here are some guidelines that we are expected to observe strictly.
                          </p>
                          <p style="
                                line-height: 1.8;
                                word-break: break-word;
                                mso-line-height-alt: 22px;
                                margin: 0;
                              ">
                             
                          </p>
                          <p style="
                                line-height: 1.8;
                                word-break: break-word;
                                mso-line-height-alt: 22px;
                                margin: 0;
                              ">
                            1. No mask, no entry.
                          </p>
                          <p style="
                                line-height: 1.8;
                                word-break: break-word;
                                mso-line-height-alt: 22px;
                                margin: 0;
                              ">
                            2. Maintain social distance.
                          </p>
                          <p style="
                                line-height: 1.8;
                                word-break: break-word;
                                mso-line-height-alt: 22px;
                                margin: 0;
                              ">
                            3. Our team will guide you to your hall.
                          </p>
                          <p style="
                                line-height: 1.8;
                                word-break: break-word;
                                mso-line-height-alt: 22px;
                                margin: 0;
                              ">
                            4. In case you feel sick, please worship online and seek medical help.
                          </p>
                          <p style="
                                line-height: 1.8;
                                word-break: break-word;
                                mso-line-height-alt: 22px;
                                margin: 0;
                              ">
                            5. Children below 4 years are not allowed to attend physica service for now.
                          </p>
                          <p style="
                                line-height: 1.8;
                                word-break: break-word;
                                mso-line-height-alt: 22px;
                                margin: 0;
                              ">
                            6. lastly, remember safety is everyone's business.
                          </p>
                          <p style="
                                line-height: 1.8;
                                word-break: break-word;
                                mso-line-height-alt: 22px;
                                margin: 0;
                                font-size: 16px;
                                font-weight: bolder;
                                margin-top: 10px;
                              ">
                            <strong>
                              To attend physical service, you must pre-register. Sunday service registration opens 12
                              noon every Friday. <br />Register at: daystarng.org/registertoattend
                            </strong>
                          </p>
                        </div>
                      </div>
                      <!--[if mso]></td></tr></table><![endif]-->
                      <div align="center" class="button-container" style="
                            padding-top: 10px;
                            padding-right: 10px;
                            padding-bottom: 10px;
                            padding-left: 10px;
                          ">
                        <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="border-spacing: 0; border-collapse: collapse; mso-table-lspace:0pt; mso-table-rspace:0pt;"><tr><td style="padding-top: 10px; padding-right: 10px; padding-bottom: 10px; padding-left: 10px" align="center"><v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="https://daystarng.org/registertoattend" style="height:33pt; width:225.75pt; v-text-anchor:middle;" arcsize="0%" strokeweight="0.75pt" strokecolor="#C4C4C4" fillcolor="#375ab0"><w:anchorlock/><v:textbox inset="0,0,0,0"><center style="color:#fafcfc; font-family:Arial, sans-serif; font-size:14px"><!
                          [endif]--><a href="https://daystarng.org/registertoattend" style="
                              -webkit-text-size-adjust: none;
                              text-decoration: none;
                              display: inline-block;
                              color: #fafcfc;
                              background-color: #375ab0;
                              border-radius: 0px;
                              -webkit-border-radius: 0px;
                              -moz-border-radius: 0px;
                              width: auto;
                              width: auto;
                              border-top: 1px solid #c4c4c4;
                              border-right: 1px solid #c4c4c4;
                              border-bottom: 1px solid #c4c4c4;
                              border-left: 1px solid #c4c4c4;
                              padding-top: 5px;
                              padding-bottom: 5px;
                              font-family: Nunito, Arial, Helvetica Neue,
                                Helvetica, sans-serif;
                              text-align: center;
                              mso-border-alt: none;
                              word-break: keep-all;
                            " target="_blank"><span style="
                                padding-left: 20px;
                                padding-right: 20px;
                                font-size: 14px;
                                display: inline-block;
                              "><span style="
                                  font-size: 16px;
                                  line-height: 2;
                                  word-break: break-word;
                                  mso-line-height-alt: 32px;
                                "><span data-mce-style="font-size: 14px; line-height: 28px;"
                                style="font-size: 14px; line-height: 28px">Click here to Register for On-site
                                Service</span></span></span></a>
                        <!--[if mso]></center></v:textbox></v:roundrect></td></tr></table><![endif]-->
                      </div>
                      <!--[if (!mso)&(!IE)]><!-->
                    </div>
                    <!--<![endif]-->
                  </div>
                </div>
                <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
                <!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
              </div>
            </div>
          </div>
          <div style="background-color: transparent; overflow: hidden">
            <div class="block-grid" style="
                  min-width: 320px;
                  max-width: 600px;
                  overflow-wrap: break-word;
                  word-wrap: break-word;
                  word-break: break-word;
                  margin: 0 auto;
                  width: 100%;
                  background-color: #f0ebe5;
                ">
              <div style="
                    border-collapse: collapse;
                    display: table;
                    width: 100%;
                    background-color: #f0ebe5;
                  ">
                <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:transparent;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px"><tr class="layout-full-width" style="background-color:#f0ebe5"><![endif]-->
                <!--[if (mso)|(IE)]><td align="center" width="600" style="background-color:#f0ebe5;width:600px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:0px; padding-bottom:0px;"><![endif]-->
                <div class="col num12" style="
                      min-width: 320px;
                      max-width: 600px;
                      display: table-cell;
                      vertical-align: top;
                      width: 600px;
                    ">
                  <div style="width: 100% !important">
                    <!--[if (!mso)&(!IE)]><!-->
                    <div style="
                          border-top: 0px solid transparent;
                          border-left: 0px solid transparent;
                          border-bottom: 0px solid transparent;
                          border-right: 0px solid transparent;
                          padding-top: 0px;
                          padding-bottom: 0px;
                          padding-right: 0px;
                          padding-left: 0px;
                        ">
                      <!--<![endif]-->
                      <div align="center" class="img-container center fixedwidth"
                        style="padding-right: 15px; padding-left: 15px">
                        <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr style="line-height:0px"><td style="padding-right: 15px;padding-left: 15px;" align="center"><![endif]-->
                        <div style="font-size: 1px; line-height: 15px"> </div>
                        <img align="center" alt="5 Points Separator" border="0" class="center fixedwidth"
                          src="assets/img/separator.png" style="
                              text-decoration: none;
                              -ms-interpolation-mode: bicubic;
                              height: auto;
                              border: 0;
                              width: 100%;
                              max-width: 240px;
                              display: block;
                            " title="5 Points Separator" width="240" />
                        <div style="font-size: 1px; line-height: 15px"> </div>
                        <!--[if mso]></td></tr></table><![endif]-->
                      </div>
                      <!--[if (!mso)&(!IE)]><!-->
                    </div>
                    <!--<![endif]-->
                  </div>
                </div>
                <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
                <!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
              </div>
            </div>
          </div>
          <div style="background-color: transparent; overflow: hidden">
            <div class="block-grid" style="
                  min-width: 320px;
                  max-width: 600px;
                  overflow-wrap: break-word;
                  word-wrap: break-word;
                  word-break: break-word;
                  margin: 0 auto;
                  width: 100%;
                  background-color: #0b474b;
                ">
              <div style="
                    border-collapse: collapse;
                    display: table;
                    width: 100%;
                    background-color: #0b474b;
                  ">
                <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:transparent;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px"><tr class="layout-full-width" style="background-color:#0b474b"><![endif]-->
                <!--[if (mso)|(IE)]><td align="center" width="600" style="background-color:#0b474b;width:600px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:20px; padding-bottom:20px;"><![endif]-->
                <div class="col num12" style="
                      min-width: 320px;
                      max-width: 600px;
                      display: table-cell;
                      vertical-align: top;
                      width: 600px;
                    ">
                  <div style="width: 100% !important">
                    <!--[if (!mso)&(!IE)]><!-->
                    <div style="
                          border-top: 0px solid transparent;
                          border-left: 0px solid transparent;
                          border-bottom: 0px solid transparent;
                          border-right: 0px solid transparent;
                          padding-top: 20px;
                          padding-bottom: 20px;
                          padding-right: 0px;
                          padding-left: 0px;
                        ">
                      <!--<![endif]-->
                      <div align="center" class="img-container center fixedwidth"
                        style="padding-right: 0px; padding-left: 0px">
                        <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr style="line-height:0px"><td style="padding-right: 0px;padding-left: 0px;" align="center"><!
                          [endif]--><a href="http://www.example.com" style="outline: none" tabindex="-1"
                          target="_blank">
                          <img align="center" alt="Your Brand Logo" border="0" class="center fixedwidth"
                            src="assets/img/logo.png" style="
                                text-decoration: none;
                                -ms-interpolation-mode: bicubic;
                                height: auto;
                                border: 0;
                                width: 100%;
                                max-width: 120px;
                                display: block;
                              " title="Your Brand Logo" width="120" /></a>
                        <!--[if mso]></td></tr></table><![endif]-->
                      </div>
                      <table cellpadding="0" cellspacing="0" class="social_icons" role="presentation" style="
                            table-layout: fixed;
                            vertical-align: top;
                            border-spacing: 0;
                            border-collapse: collapse;
                            mso-table-lspace: 0pt;
                            mso-table-rspace: 0pt;
                          " valign="top" width="100%">
                        <tbody>
                          <tr style="vertical-align: top" valign="top">
                            <td style="
                                  word-break: break-word;
                                  vertical-align: top;
                                  padding-top: 10px;
                                  padding-right: 10px;
                                  padding-bottom: 10px;
                                  padding-left: 10px;
                                " valign="top">
                              <table align="center" cellpadding="0" cellspacing="0" class="social_table"
                                role="presentation" style="
                                    table-layout: fixed;
                                    vertical-align: top;
                                    border-spacing: 0;
                                    border-collapse: collapse;
                                    mso-table-tspace: 0;
                                    mso-table-rspace: 0;
                                    mso-table-bspace: 0;
                                    mso-table-lspace: 0;
                                  " valign="top">
                                <tbody>
                                  <tr align="center" style="
                                        vertical-align: top;
                                        display: inline-block;
                                        text-align: center;
                                      " valign="top">
                                    <td style="
                                          word-break: break-word;
                                          vertical-align: top;
                                          padding-bottom: 0;
                                          padding-right: 10px;
                                          padding-left: 10px;
                                        " valign="top">
                                      <a href="https://www.facebook.com/daystarchristiancentre" target="_blank"><img
                                          alt="Facebook" height="32" src="assets/img/facebook2x.png" style="
                                              text-decoration: none;
                                              -ms-interpolation-mode: bicubic;
                                              height: auto;
                                              border: 0;
                                              display: block;
                                            " title="facebook" width="32" /></a>
                                    </td>
                                    <td style="
                                          word-break: break-word;
                                          vertical-align: top;
                                          padding-bottom: 0;
                                          padding-right: 10px;
                                          padding-left: 10px;
                                        " valign="top">
                                      <a href="https://www.twitter.com/daystarng" target="_blank"><img alt="Twitter"
                                          height="32" src="assets/img/twitter2x.png" style="
                                              text-decoration: none;
                                              -ms-interpolation-mode: bicubic;
                                              height: auto;
                                              border: 0;
                                              display: block;
                                            " title="twitter" width="32" /></a>
                                    </td>
                                    <td style="
                                          word-break: break-word;
                                          vertical-align: top;
                                          padding-bottom: 0;
                                          padding-right: 10px;
                                          padding-left: 10px;
                                        " valign="top">
                                      <a href="https://www.instagram.com/daystarng" target="_blank"><img alt="Instagram"
                                          height="32" src="assets/img/instagram2x.png" style="
                                              text-decoration: none;
                                              -ms-interpolation-mode: bicubic;
                                              height: auto;
                                              border: 0;
                                              display: block;
                                            " title="instagram" width="32" /></a>
                                    </td>
                                    <td style="
                                          word-break: break-word;
                                          vertical-align: top;
                                          padding-bottom: 0;
                                          padding-right: 10px;
                                          padding-left: 10px;
                                        " valign="top">
                                      <a href="https://www.youtube.com/user/daystarng" target="_blank"><img
                                          alt="YouTube" height="32" src="assets/img/youtube2x.png" style="
                                              text-decoration: none;
                                              -ms-interpolation-mode: bicubic;
                                              height: auto;
                                              border: 0;
                                              display: block;
                                            " title="YouTube" width="32" /></a>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <!--[if (!mso)&(!IE)]><!-->
                    </div>
                    <!--<![endif]-->
                  </div>
                </div>
                <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
                <!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
              </div>
            </div>
          </div>
          <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
        </td>
      </tr>
    </tbody>
  </table>
  <!--[if (IE)]></div><![endif]-->
</body>

</html>
