<app-header></app-header>
<div class="wrapper">
  <div class="page-header page-header-small">
    <div
      class="page-header-image"
      data-parallax="true"
      style="background-image: url('../assets/img/bg6.jpg')"
    ></div>
    <div class="content-center">
      <div class="container">
        <h1 class="title">List of Zonal Co-ordinators</h1>
      </div>
    </div>
  </div>
  <script>
    window.location = "https://www.daystarng.org";
  </script>
  <!--Start breadcrumb bottom area-->
  <section style="margin-bottom: 0px; padding-bottom: 0px">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="left pull-left footer">
            <a routerLink="/about">
              <span style="font-weight: bold">Home</span>
            </a>
            <a routerLink="#">
              <span style="font-weight: bold">&nbsp;|&nbsp;</span>
            </a>
            <a routerLink="#">
              <span style="font-weight: bold">List of Zonal Co-ordinators</span>
            </a>
          </div>
          <div class="right pull-right footer">
            <a href="#">
              <span
                ><i class="fa fa-share-alt" aria-hidden="true"></i
                >&nbsp;&nbsp;Share</span
              > </a
            >&nbsp;&nbsp;
            <a href="#">
              <span
                ><i class="fab fa-facebook-square" aria-hidden="true"></i
              ></span> </a
            >&nbsp;&nbsp;
            <a href="#">
              <span><i class="fab fa-twitter" aria-hidden="true"></i></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--End breadcrumb bottom area-->
  <!--Start breadcrumb bottom area-->
  <section style="margin-bottom: 0px; padding-bottom: 0px">
    <div class="container">
      <div class="row">
        <div class="col-md-12" style="padding: 20px">
          <h2 style="margin-top: 5px; align-items: center">
            List of Zonal Co-ordinators
          </h2>
          <!--
          <table class="table table-striped">
            <thead>
              <tr>
                <th>ZONE</th>
                <th>COORDINATOR</th>
                <th>PHONE NO</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>LAGOS ISLAND/IKOYI</td>
                <td>ADEBIYI OLADUNJOYE OLUBISI</td>
                <td>2348023122458</td>
              </tr>
              <tr>
                <td>VICTORIA ISLAND/LEKKI</td>
                <td>ADEBIYI OLAYINKA OLUKOYA</td>
                <td>2348033370366</td>
              </tr>
              <tr>
                <td>ABRAHAM ADESANYA/IJESHATEDO</td>
                <td>ABAGUN OYENIKE CATHERINE</td>
                <td>2348023211449</td>
              </tr>
              <tr>
                <td>AJA/EPE</td>
                <td>ADEOTI FEMI</td>
                <td>2348039788076</td>
              </tr>
              <tr>
                <td>SURULERE</td>
                <td>OYEKAN OLALEKAN OLUROTIMI</td>
                <td>2348056155576</td>
              </tr>
              <tr>
                <td>YABA</td>
                <td>ADELANWA OLANREWAJUBASAMTA</td>
                <td>2348087439897</td>
              </tr>
              <tr>
                <td>AGUDA</td>
                <td>OYEKAN PECULIAR NWAMAKA</td>
                <td>2348023782152</td>
              </tr>
              <tr>
                <td>EBUTEMETTA</td>
                <td>EDWARD SEYI ANTHONY</td>
                <td>2348023037471</td>
              </tr>
              <tr>
                <td>MUSHIN</td>
                <td>OTUBUSEN OLUFUNSO ADEBOLA</td>
                <td>2348023167804</td>
              </tr>
              <tr>
                <td>BARIGA</td>
                <td>OLAYEMI ISAAC OLAJIRE</td>
                <td>2348034543638</td>
              </tr>
              <tr>
                <td>GBAGADA</td>
                <td>AYINDE RHODA OLUBUSOLA</td>
                <td>2348023213938</td>
              </tr>
              <tr>
                <td>OWORONSOKI</td>
                <td>FEIBO ADETOLA BABASOLA</td>
                <td>2348022245000</td>
              </tr>
              <tr>
                <td>OBANIKORO/SHOMOLU</td>
                <td>ONI AYOBAMI AKOREDE</td>
                <td>2348023080368</td>
              </tr>
              <tr>
                <td>ANTHONY/MENDE</td>
                <td>OGUNDELE OLADIMEJI OLUMUYIWA</td>
                <td>2348023031259</td>
              </tr>
              <tr>
                <td>OGUDU</td>
                <td>OGUNNUBI ADEYINKA OLUBUNMI</td>
                <td>2348057494503</td>
              </tr>
              <tr>
                <td>OJOTA</td>
                <td>AYINDE LAWRENCE OLUMUYIWA ADE</td>
                <td>2348024213402</td>
              </tr>
              <tr>
                <td>ILUPEJU</td>
                <td>ADEGBIJI ADEBAYO PETER</td>
                <td>2348111133903</td>
              </tr>
              <tr>
                <td>MAFOLUKU</td>
                <td>OYETAYO MICHEAL KAYODE</td>
                <td>2348023919191</td>
              </tr>
              <tr>
                <td>PAPA-AJAO/LADIPO</td>
                <td>OLADEINDE ADEMOLA HENRY</td>
                <td>2348023407399</td>
              </tr>
              <tr>
                <td>IKEJA GRA</td>
                <td>EYITUOYO EJUEYITCHIE</td>
                <td>2348055146887</td>
              </tr>
              <tr>
                <td>ALLEN/OPEBI</td>
                <td>OGOCHUKWU IFEANYI</td>
                <td>2348053075428</td>
              </tr>
              <tr>
                <td>AWOLOWO/TOYIN</td>
                <td>SONEYE OLUKUNLE JOSIAH</td>
                <td>2348084456747</td>
              </tr>
              <tr>
                <td>MARYLAND/OPEBI</td>
                <td>ONAFUNWA KUNLE OLUSESAN</td>
                <td>2347066473104</td>
              </tr>
              <tr>
                <td>ADENIYI JONES/ANIFOWOSHE</td>
                <td>OYEKAN OLUGBENGA CLEMENT</td>
                <td>2348023078533</td>
              </tr>
              <tr>
                <td>IKOTUN/EGBE</td>
                <td>DUDUYEMI OLUWAFORESIMI</td>
                <td>2348023081650</td>
              </tr>
              <tr>
                <td>ISOLO</td>
                <td>ONAWALE ADEBAYO BABATUNDE</td>
                <td>2348055027858</td>
              </tr>
              <tr>
                <td>EJIGBO</td>
                <td>PHILIPS OLANREWAJU OBASHOLA</td>
                <td>2348034089122</td>
              </tr>
              <tr>
                <td>BADAGRY/AGBARA</td>
                <td>ABAGUN ADEDEJI SIMEON</td>
                <td>2348023211421</td>
              </tr>
              <tr>
                <td>LASU-IBA</td>
                <td>OSAGHAE ANDREW OSARUMWENSE</td>
                <td>2348070366064</td>
              </tr>
              <tr>
                <td>APAPA/MILE 2</td>
                <td>UMUKURO FLORAL</td>
                <td>2348127787238</td>
              </tr>
              <tr>
                <td>IKOSI-ALAUSA</td>
                <td>OLUWAJOBI OMOLARA OLORUNTOYIN</td>
                <td>2348063904912</td>
              </tr>
              <tr>
                <td>OREGUN</td>
                <td>ORELAJA KEMI OYEWOLE</td>
                <td>2348036698180</td>
              </tr>
              <tr>
                <td>AGIDINGBI</td>
                <td>OLOJO OLADIJI SEINDE</td>
                <td>2348035102714</td>
              </tr>
              <tr>
                <td>AKILO/ACME ROAD</td>
                <td>OKUNLOYE ADEOLU JAMES</td>
                <td>2348056629269</td>
              </tr>
              <tr>
                <td>OMOLE PHASE 1</td>
                <td>ADEGOKE SEGUN</td>
                <td>2348029201000</td>
              </tr>
              <tr>
                <td>OGBA-AGUDA</td>
                <td>ALLI AFOLAKE BASIRAT</td>
                <td>2348023446383</td>
              </tr>
              <tr>
                <td>OKE IRA</td>
                <td>EGUAVON OSAGIE JONES</td>
                <td>2348029790115</td>
              </tr>
              <tr>
                <td>OGBA-IJAIYE</td>
                <td>ELESIN TUNDE</td>
                <td>2348055026912</td>
              </tr>
              <tr>
                <td>YAYA ABATAN</td>
                <td>AJAYI ADEBAYO GABRIEL</td>
                <td>2348023959041</td>
              </tr>
              <tr>
                <td>ALAPERE-ESTATE</td>
                <td>ODEBO OLUWATOYOSI ABIODUN OREBOWALE</td>
                <td>2348037998525</td>
              </tr>
              <tr>
                <td>KETU/ELEBIJU</td>
                <td>ODUNLAMI OLADIMEJI OYEDEJI</td>
                <td>2348095407251</td>
              </tr>
              <tr>
                <td>KETU/OLORUNDA</td>
                <td>SAMUEL SAMSON ADIGUN< /td></td>

                <td>2348033321570</td>
              </tr>
              <tr>
                <td>OWODE/AJEGUNLE</td>
                <td>TAIWO KAYODE OLAJIDE</td>
                <td>2348033326606</td>
              </tr>
              <tr>
                <td>ALAPERE-AGBOYI</td>
                <td>UNU DANIEL</td>
                <td>2348080515797</td>
              </tr>
              <tr>
                <td>KOSOFE-AJELOGO</td>
                <td>BABAFEMI AKINNIYI DANIEL</td>
                <td>2348023365226</td>
              </tr>
              <tr>
                <td>EBUTE/BAYEKU</td>
                <td>OLUGU KALU KING</td>
                <td>2348027449739</td>
              </tr>
              <tr>
                <td>AGRIC/ISAWO</td>
                <td>AKINBALOYE OLUTOSIN CHRISTIANA</td>
                <td>2348084744462</td>
              </tr>
              <tr>
                <td>IKORODU CENTRAL</td>
                <td>OSIKOMINU SEYI KOLAWOLE</td>
                <td>2348033486229</td>
              </tr>
              <tr>
                <td>IKORODU/LAGOS RD</td>
                <td>OLUWAJOBI OLUSEGUN BOLANLE</td>
                <td>2347058055295</td>
              </tr>
              <tr>
                <td>AGILITI/MILE 12</td>
                <td>OGUNSOLA ADEDAYO ABIMBOLA</td>
                <td>2348033245783</td>
              </tr>
              <tr>
                <td>IKOSI LOVE ALL</td>
                <td>OGUNLEYE JEDIDIAH OLUSHOLA</td>
                <td>2348025019486</td>
              </tr>
              <tr>
                <td>IKOSI/ALADELOLA</td>
                <td>SAMUEL VICTORIA OLATOKUNBO</td>
                <td>2347030087176</td>
              </tr>
              <tr>
                <td>OLUWALOGBON</td>
                <td>ADIGUN ABIODUN TOYIN</td>
                <td>2348023126057</td>
              </tr>
              <tr>
                <td>ISHERI/OPIC</td>
                <td>OLUTAYO OLUTOYE</td>
                <td>2348029990515</td>
              </tr>
              <tr>
                <td>BERGER</td>
                <td>OLUSHOLA OLUTAYO OLUFUNMI</td>
                <td>2348023229210</td>
              </tr>
              <tr>
                <td>OLOWORA</td>
                <td>FALOLA TUNDE</td>
                <td>2348033081760</td>
              </tr>
              <tr>
                <td>MAGODO/SHANGISHA</td>
                <td>OLANREWAJU TAYO ADEBOLA</td>
                <td>2348054774363</td>
              </tr>
              <tr>
                <td>AKUTE</td>
                <td>OBIDIYA BOLA</td>
                <td>2348028357041</td>
              </tr>
              <tr>
                <td>OJODU</td>
                <td>AREOLA BOLURINWA OLUWAGBENRO</td>
                <td>2348055953107</td>
              </tr>
              <tr>
                <td>ALAGBOLE/AJUWON</td>
                <td>SANYAOLU ADEBOLA OLAYEMI</td>
                <td>2348033296953</td>
              </tr>
              <tr>
                <td>YAKOYO</td>
                <td>LUCAS OLUWASEYI EMMANUEL</td>
                <td>2348033490636</td>
              </tr>
              <tr>
                <td>OKE AFA/WAWA</td>
                <td>OMOJANI OLUGBENGA SUCCESS</td>
                <td>2348033824929</td>
              </tr>
              <tr>
                <td>MAGBORO</td>
                <td>AJIJOLA TITUS OLUWADAIRO</td>
                <td>2348033335711</td>
              </tr>
              <tr>
                <td>MOWE/IBAFO</td>
                <td>LAWAL OKANDIJI BABAJIDE</td>
                <td>2348034058212</td>
              </tr>
              <tr>
                <td>AREPO</td>
                <td>NWAEZE EDWIN CHIGOR</td>
                <td>2348023236145</td>
              </tr>
              <tr>
                <td>AGEGE</td>
                <td>ADEYEMO MERCY ANAYOCHUCKWU</td>
                <td>2348037269427</td>
              </tr>
              <tr>
                <td>DOPEMU</td>
                <td>EGINONG AUGUSTINE WILLIAMS</td>
                <td>2348033503248</td>
              </tr>
              <tr>
                <td>OKO OBA</td>
                <td>OREKOYA TAYO ABRAHAM</td>
                <td>2348023889689</td>
              </tr>
              <tr>
                <td>OYEMEKUN</td>
                <td>TUGA OMOYEMI OLUSHOLA</td>
                <td>2348022228523</td>
              </tr>
              <tr>
                <td>COLLEGE ROAD</td>
                <td>OJEWUMI OLUMIDE ADEYEMI</td>
                <td>2348022596000</td>
              </tr>
              <tr>
                <td>AYOBO/COMMAND</td>
                <td>ALADE ADELEYE OLUWASEUN</td>
                <td>2348033046961</td>
              </tr>
              <tr>
                <td>SHASHA</td>
                <td>OLUSHOLA OLUWATOYIN OLUWASEUN</td>
                <td>2348085310320</td>
              </tr>
              <tr>
                <td>EGBEDA</td>
                <td>ORESANWO LOOKMAN OLAWALE</td>
                <td>2348023507769</td>
              </tr>
              <tr>
                <td>AKOWONJO/ABORU</td>
                <td>KOLAWOLE MICHAEL OLUDARE</td>
                <td>2348023850341</td>
              </tr>
              <tr>
                <td>GOWON/ABESAN</td>
                <td>AJE AKINYELE AKINGBOLAHAN</td>
                <td>2348181447551</td>
              </tr>
              <tr>
                <td>IJU-FAGBA</td>
                <td>AINA OLUFEMI ADEBAYO</td>
                <td>2348060593911</td>
              </tr>
              <tr>
                <td>ISHAGA-OKE ARO</td>
                <td>JOHNSON JIDE JESHURUN</td>
                <td>2348065911247</td>
              </tr>
              <tr>
                <td>NEW OKO OBA</td>
                <td>FOLARIN TAIWO OMOTAYO</td>
                <td>2348039780483</td>
              </tr>
              <tr>
                <td>SANGO</td>
                <td>EMEHELU SAM OSITA</td>
                <td>2348033741421</td>
              </tr>
              <tr>
                <td>ALAGBADO</td>
                <td>OGUNSOTE ADEGBOYEGA TUNJI</td>
                <td>2348034401329</td>
              </tr>
              <tr>
                <td>ABULE EGBA</td>
                <td>BABATUNDE ABRAHAM OLAYIWOLA</td>
                <td>2348023065270</td>
              </tr>
            </tbody>
          </table>-->
        </div>
      </div>
    </div>
  </section>
  <!--End breadcrumb bottom area-->
  <app-footer-link></app-footer-link>
  <app-footer></app-footer>
</div>
