import { Component, OnDestroy, OnInit, Renderer2 } from "@angular/core";

@Component({
  selector: "app-testimonies",
  templateUrl: "./submit-testimony.component.html",
  styleUrls: ["./submit-testimony.component.css"],
})
export class SubmitTestimonyComponent implements OnInit, OnDestroy {
  margin: number = -300;

  constructor(private renderer: Renderer2) {
    this.renderer.addClass(document.body, "landing-page");
    this.renderer.addClass(document.body, "sidebar-collapse");
    this.loadScripts();
  }

  ngOnInit() {
    var ua = navigator.userAgent;

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        ua
      )
    ) {
      console.log("Mobile");
      this.margin = -150;
    } else {
      console.log("Deskop");
      this.margin = -300;
    }
  }

  loadScripts() {
    const dynamicScripts = [
      "assets/js/core/jquery.min.js",
      "assets/js/core/popper.min.js",
      "assets/js/core/bootstrap.min.js",
      "assets/js/plugins/bootstrap-switch.js",
      "assets/js/plugins/nouislider.min.js",
      "assets/js/plugins/bootstrap-datepicker.js",
      "assets/js/now-ui-kit.js?v=1.2.0",
      "assets/js/main.js",
      "https://formfacade.com/include/110248188638200178332/form/1FAIpQLSd5b49b63oAVx0G3RN0OrgVqVmMdtVkfDSsLt__Dc3jpa8dvQ/classic.js?div=ff-compose",
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement("script");
      node.src = dynamicScripts[i];
      node.type = "text/javascript";
      node.async = false;
      node.charset = "utf-8";
      document.getElementsByTagName("head")[0].appendChild(node);
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, "landing-page");
    this.renderer.removeClass(document.body, "sidebar-collapse");
  }
}
