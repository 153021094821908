<app-header></app-header>
<div class="wrapper">
  <div class="page-header clear-filter">
    <video
      autoplay
      muted
      loop
      id="myVideo"
      preload="auto"
      poster="assets/img/banner2022.jpg"
      autoplay="true"
    >
      <source src="assets/video/video.mp4" type="video/mp4" />
    </video>
    <div class="container" style="padding-top: 20%">
      <div class="content-center brand">
        <h1 class="h1-seo" style="font-size: 50px">A Place For You</h1>
        <h3>Join us this weekend!</h3>
        <div class="row">
          <div class="col-md-12">
            <a routerLink="/connect" class="btn btn-primary btn-round btn-lg">
              <i class="now-ui-icons location_pin"></i> Find a Location
            </a>
            <!-- <a
              class="btn btn-primary btn-round btn-lg"
              type="button"
              fileSaver
              [method]="'GET'"
              [fileName]="'Daystar Campaign Video.mp4'"
              [url]="'assets/video/dcv.mp4'"
              href="assets/video/dcv.mp4"
              target="_blank"
            >
              Download Daystar Campaign Video
            </a> -->
            <a
              class="btn btn-primary btn-round btn-lg"
              href="https://live.daystarng.org"
              target="_blank"
            >
              <i class="now-ui-icons media-1_button-play"></i> Watch Online
            </a>
            <br />
            <!--<a
              class="btn btn-primary btn-round btn-lg"
              routerLink="/messages"
              target="_blank"
            >
              <i class="now-ui-icons arrows-1_cloud-download-93"></i> Download
              Audio Messages
            </a>-->
            <a
              class="btn btn-primary btn-round btn-lg"
              href="https://www,daystarng.org/messages_v4"
              target="_blank"
            >
              <i class="now-ui-icons arrows-1_cloud-download-93"></i> Download
              Audio Messages
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="main">
    <div class="container-fluid">
      <div
        class="row justify-content-center"
        style="
          background-color: #fff;
          text-align: center;
          font-size: 1.3em;
          font-weight: 500;
          padding: 50px;
        "
      >
        <div class="col-md-12" style="margin-bottom: 20px">
          <span style="font-size: 1.5em; font-weight: 600"
            >WELCOME TO THE DAYSTAR EXPERIENCE</span
          ><br />
          <!-- <span style='font-size:1em; font-weight:400'>A message from our Senior Pastor</span> -->
        </div>
        <div class="col-md-10 col-lg-10 col-sm-12">
          <div style="font-size: 1em; font-weight: 500; padding-top: 20px">
            <blockquote>
              God has given us a mandate to raise role models, and He has indeed
              been faithful, backing our dedication and hard work with a great
              harvest of transformed lives. It is top priority for us that you
              partake of wholesome worship, edifying messages to build you up
              and positive interactions. We invite you to tour our website and
              get to know us a bit better - our service times, locations and
              membership. There are also links should you wish to support our
              ministry by giving. We look forward to having the pleasure of
              hosting you and your family soon, either in person at one of our
              campuses or virtually.
              <br />
              <strong>In Christ, Sam & Nike Adeyemi.</strong>
            </blockquote>
          </div>
        </div>
      </div>
    </div>
    <div style="clear: both"></div>
    <div class="py-5" style="background-color: #ed3237">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center" style="margin-bottom: 40px">
            <h2
              style="
                margin-bottom: 0px;
                padding-bottom: 0px;
                color: #fff;
                font-weight: bold;
              "
            >
              HOPE FOR TODAY
            </h2>
            <p style="color: #fff; font-style: italic">
              Inspiring resources to become a better you.
            </p>
          </div>
          <div class="col-md-4">
            <a href="https://daystarng.org/daystarfm">
              <div class="card mb-4 shadow-sm">
                <img
                  class="imgresource"
                  src="assets/img/vid_imgs/resource1.png"
                />
                <div class="card-body text-center">
                  <h3 style="margin-bottom: 0px; padding-bottom: 0px">
                    Get Inspired: Message Series
                  </h3>
                </div>
              </div>
            </a>
          </div>
          <div class="col-md-4">
            <a routerLink="/calendar">
              <div class="card mb-4 shadow-sm">
                <img
                  class="imgresource"
                  src="assets/img/vid_imgs/resource2.png"
                />
                <div class="card-body text-center">
                  <h3 style="margin-bottom: 0px; padding-bottom: 0px">
                    2025 Calendar & Bible Reading
                  </h3>
                </div>
              </div>
            </a>
          </div>

          <div class="col-md-4">
            <a
              href="https://mobile.daystarng.org/downloads/2025-Goal-Setting-Template.pdf"
              target="_blank"
            >
              <div class="card mb-4 shadow-sm">
                <img
                  class="imgresource"
                  src="assets/img/vid_imgs/goal-setting-sm.png"
                />
                <div class="card-body text-center">
                  <h3 style="margin-bottom: 0px; padding-bottom: 0px">
                    2025 Goal Setting Template
                  </h3>
                </div>
              </div>
            </a>
          </div>

          <div class="col-md-4">
            <a href="https://daystarng.org/getinvolved/" target="_blank">
              <div class="card mb-4 shadow-sm">
                <img class="imgresource" src="assets/img/get-involve.png" />
                <div class="card-body text-center">
                  <h3 style="margin-bottom: 0px; padding-bottom: 0px">
                    Volunteer to serve - Get Involved
                  </h3>
                </div>
              </div>
            </a>
          </div>

          <div class="col-md-4">
            <a href="https://daystarng.org/onlinechurch/help/" target="_blank">
              <div class="card mb-4 shadow-sm">
                <img
                  class="imgresource"
                  src="assets/img/prayer_counselling.png"
                />
                <div class="card-body text-center">
                  <h3 style="margin-bottom: 0px; padding-bottom: 0px">
                    Prayer & Counselling<br />
                  </h3>
                </div>
              </div>
            </a>
          </div>

          <div class="col-md-4">
            <a href="https://daystarng.org/smallgroups/" target="_blank">
              <div class="card mb-4 shadow-sm">
                <img
                  class="imgresource"
                  src="assets/img/vid_imgs/small_groups.png"
                />
                <div class="card-body text-center">
                  <h3 style="margin-bottom: 0px; padding-bottom: 0px">
                    Daystar Small Groups<br />
                  </h3>
                </div>
              </div>
            </a>
          </div>

          <!-- <div class="col-md-4">
            <a href="https://daystarng.org/excellencebug/" target="_blank">
              <div class="card mb-4 shadow-sm">
                <img class="imgresource" src="assets/img/excellence-bug.png" />
                <div class="card-body text-center">
                  <h3 style="margin-bottom: 0px; padding-bottom: 0px">
                    Excellence Bug - ELC 2023
                  </h3>
                </div>
              </div>
            </a>
          </div> -->

          <!-- <div class="col-md-4">
            <a
              type="button"
              fileSaver
              [method]="'GET'"
              [fileName]="'Daystar Campaign Video.mp4'"
              [url]="'assets/video/dcv.mp4'"
              href="assets/video/dcv.mp4"
              target="_blank"
            >
              <div class="card mb-4 shadow-sm">
                <img
                  class="imgresource"
                  src="assets/img/vid_imgs/resource3.png"
                />
                <div class="card-body text-center">
                  <h3 style="margin-bottom: 0px; padding-bottom: 0px">
                    Daystar #Stronger Together Video
                  </h3>
                </div>
              </div>
            </a>
          </div> -->

          <!--          <div class="col-md-4">-->
          <!--            <div class="card mb-4 shadow-sm">-->
          <!--              <img class="imgresource" src="assets/img/vid_imgs/resource4.png">-->
          <!--              <div class="card-body text-center">-->
          <!--                <h3 style="margin-bottom: 0px;padding-bottom: 0px;">Sermon: Multiply 2019</h3>-->
          <!--                <p class="card-text">Pastor Sam Adeyemi<br/>January 2019</p>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <div class="col-md-4">-->
          <!--            <div class="card mb-4 shadow-sm">-->
          <!--              <img class="imgresource" src="assets/img/vid_imgs/resource5.png">-->
          <!--              <div class="card-body text-center">-->
          <!--                <h3 style="margin-bottom: 0px;padding-bottom: 0px;">Sermon: Multiply 2019</h3>-->
          <!--                <p class="card-text">Pastor Sam Adeyemi<br/>January 2019</p>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <div class="col-md-4">-->
          <!--            <div class="card mb-4 shadow-sm">-->
          <!--              <img class="imgresource" src="assets/img/vid_imgs/resource6.png">-->
          <!--              <div class="card-body text-center">-->
          <!--                <h3 style="margin-bottom: 0px;padding-bottom: 0px;">Sermon: Multiply 2019</h3>-->
          <!--                <p class="card-text">Pastor Sam Adeyemi<br/>January 2019</p>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>
    </div>
    <!-- <div class="separator separator-primary"></div> -->
    <div class="section" id="carousel" id="events">
      <div class="container">
        <h2 class="title">Events</h2>
        <div class="row justify-content-center">
          <div class="col-lg-9 col-md-12 col-sm-12">
            <div
              id="carouselExampleIndicators"
              class="carousel slide"
              data-ride="carousel"
            >
              <ol class="carousel-indicators">
                <li
                  *ngFor="let event of events; let i = index"
                  data-target="#carouselExampleIndicators"
                  attr.data-slide-to="{{ i }}"
                  [ngClass]="{ active: i == 0 }"
                ></li>
              </ol>
              <div class="carousel-inner" role="listbox">
                <div
                  class="carousel-item"
                  *ngFor="let event of events; let i = index"
                  [ngClass]="{ active: i == 0 }"
                >
                  <a [href]="event.url" target="_blank">
                    <img
                      class="d-block"
                      [src]="event.image"
                      alt="{{ event.comment }}"
                    />
                    <!-- <div class="carousel-caption d-none d-md-block">
                    <h5>{{ event.comment}}</h5>
                  </div> -->
                  </a>
                </div>
              </div>
              <a
                class="carousel-control-prev"
                href="#carouselExampleIndicators"
                role="button"
                data-slide="prev"
              >
                <i
                  class="now-ui-icons arrows-1_minimal-left"
                  style="color: #000000; font-weight: bolder; font-weight: 30px"
                ></i>
              </a>
              <a
                class="carousel-control-next"
                href="#carouselExampleIndicators"
                role="button"
                data-slide="next"
              >
                <i
                  class="now-ui-icons arrows-1_minimal-right"
                  style="color: #000000; font-weight: bolder; font-weight: 30px"
                ></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div
        class="row justify-content-center"
        style="
          background-color: #0f1e7a;
          min-height: 250px;
          padding: 50px;
          text-align: center;
        "
      >
        <div class="col-md-6 col-lg-6">
          <div class="text-right">
            <img src="assets/img/dla_logo.png" style="width: 50%" />
          </div>
        </div>
        <div class="col-md-6 col-lg-6 text-left" style="padding-top: 50px">
          <a
            class="btn btn-light btn-round btn-lg"
            style="background-color: #fff; color: #222"
            href="http://dlaonline.org"
            target="_blank"
          >
            <i class="now-ui-icons media-1_button-play"></i> Click for more
            information
          </a>
        </div>
      </div>
    </div>
    <div class="section section-team text-center">
      <h2 style="font-weight: bold">See What God Can Do Through You</h2>
      <div class="container">
        <div class="team">
          <div class="row">
            <div class="col-md-3">
              <div class="team-player">
                <img
                  src="../assets/img/avatar.jpg"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                  style="width: 70%"
                />
                <p class="category text-dark">Services</p>
                <!--                <p>Student</p>-->
                <p>
                  The best place to start connecting with God at Daystar is in
                  our Services.<br />
                  <a routerLink="/connect">Service Times </a>
                </p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="team-player">
                <img
                  src="../assets/img/avatar2.jpg"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                  style="width: 70%"
                />
                <p class="category text-dark">Grow With Friends</p>
                <!--                <p>Student</p>-->
                <p>
                  We believe in Daystar that Homegroups are the building blocks
                  of the church life.<br />
                  <a routerLink="/connect"> Join a Group </a>
                </p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="team-player">
                <img
                  src="../assets/img/avatar4.jpg"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                  style="width: 70%"
                />
                <p class="category text-dark">Daystar Academy</p>
                <p>
                  The most practical way to serve at Daystar is being part of
                  our Academy.<br />
                  <a routerLink="/care">Join Us</a>
                </p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="team-player">
                <img
                  src="../assets/img/avatar3.jpg"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                  style="width: 70%"
                />
                <p class="category text-dark">Community Impact</p>
                <p>
                  I want the deaf community to know that God is a relational God
                  and cares about them.
                  <a
                    href="https://blog.daystarng.org/community-impact/"
                    target="_blank"
                    >Read More</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <form [formGroup]="contactForm" (ngSubmit)="onContactSubmit()">
        <div
          class="row justify-content-center"
          style="
            background-image: url('assets/img/world_map_black.jpeg');
            background-color: #000;
            min-height: 500px;
            padding: 40px;
            text-align: center;
          "
        >
          <div
            class="col-md-12"
            style="
              color: #fff;
              font-size: 25px;
              font-weight: 600;
              margin-bottom: 50px;
            "
          >
            CONTACT US
          </div>

          <div class="col-md-12 col-lg-5 col-sm-12" style="padding-right: 0px">
            <input
              type="text"
              placeholder="YOUR NAME*"
              class="form-control"
              formControlName="name"
              [ngClass]="{ 'is-invalid': contactSubmitted && f.name.errors }"
              style="
                margin-bottom: 20px;
                height: 40px;
                padding: 15px;
                width: 100%;
                background-color: #fff;
              "
            />
            <div
              *ngIf="contactSubmitted && f.name.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.name.errors.required">Name is required</div>
            </div>
            <input
              type="email"
              placeholder="YOUR EMAIL*"
              class="form-control"
              formControlName="email"
              [ngClass]="{ 'is-invalid': contactSubmitted && f.email.errors }"
              style="
                margin-bottom: 20px;
                height: 40px;
                padding: 15px;
                width: 100%;
                background-color: #fff;
              "
            />
            <div
              *ngIf="contactSubmitted && f.email.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.email.errors.required">
                Please enter a valid email
              </div>
              <div *ngIf="f.email.errors.pattern">
                Please enter a valid email
              </div>
            </div>
            <input
              type="text"
              placeholder="SUBJECT*"
              class="form-control"
              formControlName="subject"
              [ngClass]="{ 'is-invalid': contactSubmitted && f.subject.errors }"
              style="
                margin-bottom: 20px;
                height: 40px;
                padding: 15px;
                width: 100%;
                background-color: #fff;
              "
            />
            <div
              *ngIf="contactSubmitted && f.subject.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.subject.errors.required">Subject is required</div>
            </div>
          </div>
          <div class="col-md-12 col-lg-5 col-sm-12" style="padding-right: 0px">
            <textarea
              rows="6"
              placeholder="YOUR MESSAGE"
              class="form-control"
              formControlName="message"
              [ngClass]="{ 'is-invalid': contactSubmitted && f.message.errors }"
              style="
                margin-bottom: 10px;
                padding: 10px;
                width: 100%;
                background-color: #fff;
                max-height: 160px;
                border-radius: 10px;
              "
            ></textarea>
            <div
              *ngIf="contactSubmitted && f.message.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.message.errors.required">Message is required</div>
            </div>
          </div>
          <div class="col-md-7 col-sm-12" style="text-align: center">
            <!--          <span style='font-size:12px; padding:15px; cursor:pointer' class='btn btn-danger'>SEND MESSAGE</span>-->
            <button class="btn btn-primary btn-lg">SEND MESSAGE</button>
          </div>
        </div>
      </form>
    </div>

    <div class="section section-team text-center">
      <h2 style="font-weight: bold">Subscribe to our newsletter</h2>
      <form [formGroup]="subscrptionForm" (ngSubmit)="onSubscribeSubmit()">
        <div class="container">
          <div class="col-md-12 col-lg-12 col-sm-12" style="padding-right: 0px">
            <input
              type="email"
              placeholder="YOUR EMAIL*"
              class="form-control"
              formControlName="email"
              [ngClass]="{
                'is-invalid': subscriptionSubmitted && f1.email.errors
              }"
              style="
                margin-bottom: 20px;
                height: 40px;
                padding: 15px;
                width: 100%;
                background-color: #fff;
              "
            />
            <div
              *ngIf="subscriptionSubmitted && f1.email.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f1.email.errors.required">
                Please enter a valid email
              </div>
              <div *ngIf="f1.email.errors.pattern">
                Please enter a valid email
              </div>
            </div>
          </div>

          <div class="col-md-12 col-sm-12" style="text-align: center">
            <!--          <span style='font-size:12px; padding:15px; cursor:pointer' class='btn btn-danger'>SEND MESSAGE</span>-->
            <button class="btn btn-primary btn-lg">SUBSCRIBE</button>
          </div>
        </div>
      </form>
    </div>
    <app-footer-link></app-footer-link>
  </div>
  <app-footer></app-footer>
</div>
