import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-wale-oresanwo',
  templateUrl: './wale-oresanwo.component.html',
  styleUrls: ['./wale-oresanwo.component.css']
})
export class WaleOresanwoComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<WaleOresanwoComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
