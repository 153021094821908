<app-header></app-header>
<div class="wrapper">
  <div class="page-header page-header-small">
    <div
      class="page-header-image"
      data-parallax="true"
      style="background-image: url('../assets/img/bg6.jpg')"
    ></div>
    <div class="content-center">
      <div class="container">
        <h1 class="title">Submit Your Testimony</h1>
      </div>
    </div>
  </div>

  <!--Start breadcrumb bottom area-->
  <section style="margin-bottom: 0px; padding-bottom: 0px">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="left pull-left footer">
            <a routerLink="/about">
              <span style="font-weight: bold">Home</span>
            </a>
            <a routerLink="#">
              <span style="font-weight: bold">&nbsp;|&nbsp;</span>
            </a>
            <a routerLink="#">
              <span style="font-weight: bold">Submit Your Testimony</span>
            </a>
          </div>
          <div class="right pull-right footer">
            <a href="#">
              <span
                ><i class="fa fa-share-alt" aria-hidden="true"></i
                >&nbsp;&nbsp;Share</span
              > </a
            >&nbsp;&nbsp;
            <a href="#">
              <span
                ><i class="fab fa-facebook-square" aria-hidden="true"></i
              ></span> </a
            >&nbsp;&nbsp;
            <a href="#">
              <span><i class="fab fa-twitter" aria-hidden="true"></i></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--End breadcrumb bottom area-->
  <!--Start breadcrumb bottom area-->
  <section style="margin-bottom: 0px; padding-bottom: 0px">
    <div class="container">
      <div class="row">
        <div class="col-md-12" style="padding: 20px">
          <img src="assets/img/testimony-guides.png" style="width: 100%" />
        </div>

        <div class="col-md-12 text-center" style="margin-top: {{ margin }}px">
          <a
            routerLink="/submit-testimony"
            class="btn btn-primary btn-round btn-lg"
          >
            <i class="now-ui-icons ui-1_send"></i> Submit Testimony
          </a>

          <a
            href="https://bit.ly/uploadtestimony"
            target="_blank"
            class="btn btn-primary btn-round btn-lg"
          >
            <li class="now-ui-icons arrows-1_cloud-upload-94"></li>
            Upload Testimony
          </a>
        </div>
      </div>
    </div>
  </section>
  <!--End breadcrumb bottom area-->
  <app-footer-link></app-footer-link>
  <app-footer></app-footer>
</div>
