<div class="row" style="overflow: hidden;">
  <div class="col-md-4">
    <div><img src="assets/img/management-team/pst_ben.png" style="width: 100%;" /></div>
  </div>
  <div class="col-md-8">
    <div class="row float-right" style="margin-top: 10px;margin-right: 20px;"><button type="button" class="close"
        (click)="onNoClick()">×</button></div>
    <h2>BENJAMINE OKPALA </h2>
    <p style="font-size: 1.2em;">
      Benjamine Okpala is an Associate Pastor, and a District Pastor overseeing two Districts.<br /><br />
      He is a graduate of Accountancy and Finance from the prestigious Yaba College of Technology (Yabatech), Lagos. He
      is a Certified Counsellor from the International Institute of Faith-Based Counselling, USA, and an Associate
      Member of the Institute of Strategic Management, Nigeria. He is a recognized expert in Personal Development,
      Leadership and Business Mastery. He leverages his wealth of experience in multiple industries to mentor and
      counsel many individuals and Business Startups. With over a decade of hands-on experience in business development
      and management, gleaned from managing different businesses, Pastor Benjamine is passionate about personal growth,
      leadership and business-related issues.
    </p>
    <p>
      He loves creativity, innovation, excellence and adventure.<br /><br />
      He is happily married to Kelechi.
    </p>
  </div>
</div>
