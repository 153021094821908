import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./components/home/home.component";
import { AboutComponent } from "./components/about/about.component";
import { ConnectComponent } from "./components/connect/connect.component";
import { WatchComponent } from "./components/watch/watch.component";
import { GiveComponent } from "./components/give/give.component";
import { CareComponent } from "./components/care/care.component";
import { CalendarComponent } from "./components/calendar/calendar.component";
import { BulletinComponent } from "./components/bulletin/bulletin.component";
import { OutlineComponent } from "./components/outline/outline.component";
import { CellLocatorComponent } from "./components/cell-locator/cell-locator.component";
import { FormDownloadComponent } from "./components/form-download/form-download.component";
import { BibleReadingComponent } from "./components/bible-reading/bible-reading.component";
import { BibleReadingDetailsComponent } from "./components/bible-reading-details/bible-reading-details.component";
import { ZonalCoordinatorsComponent } from "./components/zonal-coordinators/zonal-coordinators.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { CareersComponent } from "./components/careers/careers.component";
import { PrivacyPolicyComponent } from "./components/privacy-policy/privacy-policy.component";
import { DownloadsComponent } from "./components/downloads/downloads.component";
import { Daystar25thanniversaryComponent } from "./components/daystar25thanniversary/daystar25thanniversary.component";
import { PaymentCodesComponent } from "./components/payment-codes/payment-codes.component";
import { TestimoniesComponent } from "./components/testimonies/testimonies.component";
import { ManagementTeamComponent } from "./components/management-team/management-team.component";
import { StrongerTogetherComponent } from "./components/stronger-together/stronger-together.component";
import { ReflexionsComponent } from "./components/reflexions/reflexions.component";
import { VolunteerHandbookComponent } from "./components/volunteer-handbook/volunteer-handbook.component";
import { TimelessComponent } from "./components/timeless/timeless.component";
import { NewsletterComponent } from "./components/newsletter/newsletter.component";
import { EventsComponent } from "./components/events/events.component";
import { SubmitTestimonyComponent } from "./components/submit-testimony/submit-testimony.component";
import { UploadTestimonyComponent } from "./components/upload-testimony/upload-testimony.component";
import { MessagesComponent } from "./components/messages/messages.component";

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "about", component: AboutComponent },
  { path: "connect", component: ConnectComponent },
  { path: "watch", component: WatchComponent },
  { path: "care", component: CareComponent },
  { path: "events", component: EventsComponent },
  { path: "careers", component: CareersComponent },
  { path: "give", component: GiveComponent },
  { path: "calendar", component: CalendarComponent },
  { path: "bible-reading", component: BibleReadingComponent },
  { path: "bible-reading-detail", component: BibleReadingDetailsComponent },
  { path: "bulletin", component: BulletinComponent },
  { path: "outline", component: OutlineComponent },
  { path: "cell-locator", component: CellLocatorComponent },
  { path: "forms-download", component: FormDownloadComponent },
  { path: "zonal-coordinators", component: ZonalCoordinatorsComponent },
  { path: "privacy-policy", component: PrivacyPolicyComponent },
  { path: "downloads", component: DownloadsComponent },
  { path: "25thanniversary", component: Daystar25thanniversaryComponent },
  { path: "giving-codes", component: PaymentCodesComponent },
  { path: "testimonies", component: TestimoniesComponent },
  { path: "submit-testimony", component: SubmitTestimonyComponent },
  { path: "upload-testimony", component: UploadTestimonyComponent },
  { path: "timeless", component: TimelessComponent },
  { path: "stronger-together", component: StrongerTogetherComponent },
  { path: "management-team", component: ManagementTeamComponent },
  { path: "volunteer-handbook", component: VolunteerHandbookComponent },
  { path: "newsletter", component: NewsletterComponent },
  { path: "messages", component: MessagesComponent },
  { path: "**", component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: "enabled",
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
