<div class="row" style="overflow: hidden;">
  <div class="col-md-4">
    <div><img src="assets/img/management-team/pst_segun.png" style="width: 100%;" /></div>
  </div>
  <div class="col-md-8">
    <div class="row float-right" style="margin-top: 10px;margin-right: 20px;"><button type="button" class="close"
        (click)="onNoClick()">×</button></div>
    <h2>RALPH SEGUN DADA</h2>
    <p style="font-size: 1.2em;">
      Ralph Segun Dada (RSD) is an Associate Pastor, a District Pastor overseeing two Districts, and currently serves as
      the Centre Pastor for the Alimosho Satellite Church. </p>
    <p>
      He holds a Bachelor's degree in Accountancy, and has completed several personal development and leadership
      programs at the Lagos Business School and Word of Faith Bible Institute. He is a faculty member at the Daystar
      Leadership Academy.<br /><br />
      Pastor Ralph is an assiduous development enthusiast and a leadership expert. He is a gifted, graced and proficient
      teacher, trainer, mentor and motivator.<br /><br />
      Pastor Ralph is an author, conference speaker and musician.
    </p>
  </div>
</div>
