import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit, OnDestroy {
  aboutReadMore = false;
  aboutText = 'Show more';
  psaReadMore = false;
  psaText = 'Show more';
  pnaReadMore = false;
  pnaText = 'Show more';
  title = 'Daystar Christian Centre - raising role models. | About Us';

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private renderer: Renderer2) {
    this.renderer.addClass(document.body, 'landing-page');
    this.renderer.addClass(document.body, 'sidebar-collapse');
    this.loadScripts();
  }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      { name: 'keywords', content: 'daystar, daystarng, rolemodel, rolemodels, leaders, leadership, about, aboutus' },
      { name: 'description', content: 'Get to know more about the minitry of Daystar Christian Centre, our Vision, Mission and Core Values. We welcome you to be a part of the Daystar experience! The Word Works Wonders in Daystar!' },
      { name: 'robots', content: 'index, follow' }
    ]);
  }

  togglePsa() {
    this.psaReadMore = !this.psaReadMore;
    this.psaText = this.psaReadMore ? 'Show less' : 'Show more';
  }

  togglePna() {
    this.pnaReadMore = !this.pnaReadMore;
    this.pnaText = this.pnaReadMore ? 'Show less' : 'Show more';
  }

  toggleAcoutReadMore() {
    this.aboutReadMore = !this.aboutReadMore;
    this.aboutText = this.aboutReadMore ? 'Show less' : 'Show more';
  }

  loadScripts() {
    const dynamicScripts = [
      'assets/js/core/jquery.min.js',
      'assets/js/core/popper.min.js',
      'assets/js/core/bootstrap.min.js',
      'assets/js/plugins/bootstrap-switch.js',
      'assets/js/plugins/nouislider.min.js',
      'assets/js/plugins/bootstrap-datepicker.js',
      'assets/js/now-ui-kit.js?v=1.2.0',
      'assets/js/main.js',
      // 'assets/js/jquery.fancybox.min.js'
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'landing-page');
    this.renderer.removeClass(document.body, 'sidebar-collapse');
  }
}
