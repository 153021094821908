<div class="row" style="overflow: hidden;">
  <div class="col-md-4">
    <div><img src="assets/img/management-team/pst_ladokun.png" style="width: 100%;" /></div>
  </div>
  <div class="col-md-8">
    <div class="row float-right" style="margin-top: 10px;margin-right: 20px;"><button type="button" class="close"
        (click)="onNoClick()">×</button></div>
    <h2>SEUN LADOKUN</h2>
    <p style="font-size: 1.2em;">
      Seun Ladokun is an Associate Pastor, and a District Pastor overseeing two Districts. He supports the ministry team
      of the church and also assists with the singles’ ministry among many other functions. </p>
    <p>
      He is a graduate of Computer Science and is currently running a post-graduate degree in Criminology and Security
      Studies at the National Open University of Nigeria. He is an associate member of the Chartered Institute of
      Arbitration UK, a Professional Counsellor, Family Systems Professional, and a Conflict Resolution Expert skilled
      in Mediation and Negotiation.<br /><br />

      Pastor Seun loves to care for and go the extra mile for others. He has interests in policing, security, and
      football. He enjoys playing soccer on PS4.<br /><br />

      He is happily married to Ibitayo and they are blessed with two amazing children.

    </p>
  </div>
</div>
