<app-header></app-header>
<div class="wrapper">
  <div class="page-header page-header-small">
    <div
      class="page-header-image"
      data-parallax="true"
      style="background-image: url('../assets/img/bg6.jpg')"
    ></div>
    <div class="content-center">
      <div class="container">
        <h1 class="title">Submit Your Testimony</h1>
      </div>
    </div>
  </div>

  <!--Start breadcrumb bottom area-->
  <section style="margin-bottom: 0px; padding-bottom: 0px">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="left pull-left footer">
            <a routerLink="/about">
              <span style="font-weight: bold">Home</span>
            </a>
            <a routerLink="#">
              <span style="font-weight: bold">&nbsp;|&nbsp;</span>
            </a>
            <a routerLink="#">
              <span style="font-weight: bold">Submit Your Testimony</span>
            </a>
          </div>
          <div class="right pull-right footer">
            <a href="#">
              <span
                ><i class="fa fa-share-alt" aria-hidden="true"></i
                >&nbsp;&nbsp;Share</span
              > </a
            >&nbsp;&nbsp;
            <a href="#">
              <span
                ><i class="fab fa-facebook-square" aria-hidden="true"></i
              ></span> </a
            >&nbsp;&nbsp;
            <a href="#">
              <span><i class="fab fa-twitter" aria-hidden="true"></i></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--End breadcrumb bottom area-->
  <!--Start breadcrumb bottom area-->
  <section style="margin-bottom: 0px; padding-bottom: 0px">
    <div class="container">
      <div class="row">
        <div class="col-md-12" style="padding: 20px">
          <div id="ff-compose"></div>
          <script
            async
            defer
            src="https://formfacade.com/include/114116234803713262828/form/1FAIpQLSd5b49b63oAVx0G3RN0OrgVqVmMdtVkfDSsLt__Dc3jpa8dvQ/bootstrap.js?div=ff-compose"
          ></script>
          <div
            style="text-align: center; margin-top: 40px; margin-bottom: 20px"
          >
            <a style="text-align: center" href="/submit-testimony">
              <span
                style="
                  padding: 20px;
                  color: white;
                  background-color: #5d33fb;
                  cursor: pointer;
                  border-radius: 4px;
                  font-size: 14px;
                "
                >Submit Video Testimony</span
              ></a
            >
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--End breadcrumb bottom area-->
  <app-footer-link></app-footer-link>
  <app-footer></app-footer>
</div>
