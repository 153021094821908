<app-header></app-header>
<div class="wrapper">
  <div class="page-header page-header-small">
    <div class="page-header-image" data-parallax="true" style="background-image: url('../assets/img/bg6.jpg');">
    </div>
    <div class="content-center">
      <div class="container">
        <h1 class="title">Home Cell Outline</h1>
      </div>
    </div>
  </div>

  <!--Start breadcrumb bottom area-->
  <section style="margin-bottom: 0px; padding-bottom: 0px;">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="left pull-left footer">
            <a routerLink="/about">
              <span style="font-weight: bold">Home</span>
            </a>
            <a routerLink="#">
              <span style="font-weight: bold">&nbsp;|&nbsp;</span>
            </a>
            <a routerLink="#">
              <span style="font-weight: bold"> Cell Outline</span>
            </a>
          </div>
          <div class="right pull-right footer">
            <a href="#">
              <span><i class="fa fa-share-alt" aria-hidden="true"></i>&nbsp;&nbsp;Share</span>
            </a>&nbsp;&nbsp;
            <a href="#">
              <span><i class="fab fa-facebook-square" aria-hidden="true"></i></span>
            </a>&nbsp;&nbsp;
            <a href="#">
              <span><i class="fab fa-twitter" aria-hidden="true"></i></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--End breadcrumb bottom area-->
  <!--Start breadcrumb bottom area-->
  <section style="margin-bottom: 0px; padding-bottom: 0px;">
    <div class="container">
      <div class="row">
        <div class="col-md-12" style="padding: 20px;">
          <a class="btn btn-primary btn-round btn-lg" style="width: 100%;" href="{{outline.outline}}" target="_blank"
            *ngFor="let outline of outlines">
            <div style="word-wrap: break-word;"><i class="now-ui-icons arrows-1_cloud-download-93"></i> Download
              {{outline.type}}
              for {{outline.date}}</div>
          </a>
        </div>
      </div>
    </div>
  </section>
  <!--End breadcrumb bottom area-->
  <app-footer-link></app-footer-link>
  <app-footer></app-footer>
</div>
